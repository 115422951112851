import React, {useEffect} from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  withRouter,
  Link,
  Redirect
} from "react-router-dom";
import './styles/main-layout.scss';
import Login from './components/login';
import {Produits} from './components/produits';
import {TypeProduits} from './components/type-produits';
import {Users} from './components/users';
import {Intervention} from './components/intervention';
import {Techniciens} from './components/techniciens';
import {DemandesDevis} from './components/demandes';
import {Entretiens} from './components/entretiens';
import {Dashboard} from './components/dashboard';
import Sidebard from './layouts/sidebar';
import UserService from './services/user-service';
import { Marques } from "./components/marque";
import { Categorie } from "./components/categorie";
import { Puissance } from "./components/puissance";
import { Models } from "./components/model";
import { PrixProduits } from "./components/prix-produits";
import { Achat } from "./components/achat";
import { Devis } from "./components/devis";
import { Reclamation } from "./components/reclamation";
import { EntretiensPrestation } from './components/entretiens-prestation';
import { EntretiensDevis } from './components/demandes-devis';
import { BdCommande } from "./components/bd-commande";
import { GestionTechn } from "./components/gestion-techn";
import { OffresPromotionnel } from "./components/offres-promotionnel";
import { CodePromo } from "./components/code-promo";
import { ClientNotif } from "./components/client-notif";
import Condition from "./components/condition";

const routes = [
    {
      path: "/",
      exact: true,
      sidebar: () => <div>Dashboard!</div>,
      main: () => <Dashboard/>
      // main: () => <h2>Dashboard</h2>
    },
    {
      path: "/auth",
      sidebar: () => <div>auth!</div>,
      exact: true,
      main: () => <h2>Auth</h2>
    },
    {
      path: "/confidentialite-de-vos-donnees-personnelles-sur-la-smart-maintenance",
      sidebar: () => <div>condition!</div>,
      exact: true,
      main: () => <h2>Condition</h2>
    },
    {
      path: "/dashboard",
      sidebar: () => <div>Dashboard!</div>,
      exact: true,
      main: () => <h2>Dashboard</h2>
    },
    {
      path: "/interventions",
      sidebar: () => <div>Intervention!</div>,
      exact: true,
      main: () => <Intervention/>
      // main: () => <h2>Intervention</h2>
    },
    {
      path: "/entretiens",
      sidebar: () => <div>Entretiens!</div>,
      exact: true,
      // main: () => <h2>Entretiens</h2>
      main: () => <Entretiens/>
    },
    {
      path: "/entretiens-prestation/:entretiensId?",
      /* sidebar: () => <div>Entretiens!</div>, */
      exact: true,
      // main: () => <h2>Entretiens</h2>
      main: (props) => <EntretiensPrestation/>
    },
    {
      path: "/demandes-devis/:entretiensId?",
      /* sidebar: () => <div>Entretiens!</div>, */
      exact: true,
      // main: () => <h2>Entretiens</h2>
      main: (props) => <EntretiensDevis/>
    },
    {
      path: "/parametre",
      sidebar: () => <div>Paramètres!</div>,
      exact: true,
      main: () => <h2>Paramètres</h2>
    },
   
    {
      path: "/puissance",
      sidebar: () => <div>Puissance!</div>,
      exact: true,
      main: ()=> <Puissance/>
    },
    {
      path: "/model",
      sidebar: () => <div>Model!</div>,
      exact: true,
      main: ()=> <Models/>
    },
    {
      path: "/achat",
      sidebar: () => <div>achat!</div>,
      exact: true,
      main: ()=> <Achat/>
    },
    {
      path: "/bd-commande",
      sidebar: () => <div>commande!</div>,
      exact: true,
      main: ()=> <BdCommande/>
    },
    {
      path: "/offres-promotionnel",
      sidebar: () => <div>Ofrre Promr!</div>,
      exact: true,
      main: ()=> <OffresPromotionnel/>
    },
    {
      path: "/client-notif",
      sidebar: () => <div>message Notif!</div>,
      exact: true,
      main: ()=> <ClientNotif/>
    },
    {
      path: "/code-promo",
      sidebar: () => <div>Gestion Promo!</div>,
      exact: true,
      main: ()=> <CodePromo/>
    },
    {
      path: "/gestion-techn",
      sidebar: () => <div>gestion!</div>,
      exact: true,
      main: ()=> <GestionTechn/>
    },
    {
      path: "/categorie",
      sidebar: () => <div>categorie!</div>,
      exact: true,
      //main: () => <h2>Marques</h2>
      main: () => <Categorie/>
    },
    {
      path: "/devis",
      sidebar: () => <div>devis!</div>,
      exact: true,
      //main: () => <h2>Marques</h2>
      main: () => <Devis/>
    },
    {
      path: "/reclamation",
      sidebar: () => <div>reclamation!</div>,
      exact: true,
      //main: () => <h2>Marques</h2>
      main: () => <Devis/>
    },
    {
      path: "/techniciens",
      sidebar: () => <div>Techniciens!</div>,
      exact: true,
      // main: () => <h2>Techniciens</h2>
      main: () => <Techniciens/>
    },
    {
      path: "/demande-devis",
      sidebar: () => <div>Demandes et devis!</div>,
      // main: () => <h2>Demandes</h2>
      main: () => <DemandesDevis/>
    },
    {
      path: "/administration",
      sidebar: () => <div>Administration</div>,
      main: () => <h2>Administration</h2>
    },
  ];


  const userService = new UserService();
  const mainAppComponent = ()=>{
    return (
      <div className="row">
                <div className="sidebar"
                  style={{
                    position: 'absolute',
                    height: '70vw',
                    background: "#f0f0f0"
                  }}>
                 <Sidebard/>
                </div>
                {/* <div className="col-md-10 col-lg-10 col-12 col-xl-10"> */}
                <div className="content-view w-100 pl-5">
                  <div className="view-route">
                  <Switch>
                    {/* {routes.map((route, index) => (
                        // Render more <Route>s with the same paths as
                        // above, but different components this time.
                        // <Route
                        //   key={index}
                        //   path={route.path}
                        //   exact={route.exact}
                        //   children={<route.main />}
                        // />
                        
                      ))} */}
                      <Route exact={true} path='/demande-devis' render={()=> <DemandesDevis/>} />
                      <Route exact={true} path='/interventions' render={()=> <Intervention/>} />
                      <Route exact={true} path='/' render={()=><Dashboard/>} />
                      <Route exact={true} path='/techniciens' render={()=> <Techniciens/>} />
                      <Route exact={true} path='/entretiens' render={()=> <Entretiens/>} />
                      <Route exact={true} path='/entretiens-prestation/:entretiensId?' render={(props)=> <EntretiensPrestation  {...props} />} />
                      <Route exact={true} path='/demandes-devis/:entretiensId?' render={(props)=> <EntretiensDevis  {...props} />} />
                      <Route exact={true} path='/devis' render={()=> <Devis/>} />
                      <Route exact={true} path='/reclamation' render={()=> <Reclamation/>} />
                      <Route exact={true} path='/marques' render={()=> <Marques/>} />
                      <Route exact={true} path='/puissance' render={()=> <Puissance/>} />
                      <Route exact={true} path='/model' render={()=> <Models/>} />
                      <Route exact={true} path='/gestion-techn' render={()=> <GestionTechn/>} />
                      <Route exact={true} path='/achat' render={()=> <Achat/>} />
                      <Route exact={true} path='/bd-commande' render={()=> <BdCommande/>} />
                      <Route exact={true} path='/offres-promotionnel' render={()=> <OffresPromotionnel/>} />
                      <Route exact={true} path='/client-notif' render={()=> <ClientNotif/>} />
                      <Route exact={true} path='/code-promo' render={()=> <CodePromo/>} />
                      <Route exact={true} path='/categorie' render={()=> <Categorie/>} />
                      {/* <Route exact={true} path='/produits' render={()=> <Produits/>} /> */}
                      <Route exact={true} path='/prix-produits' render={()=> <PrixProduits/>} />
                      <Route exact={true} path='/users' render={()=> <Users/>} />
                     {/*  <Route exact={true} path='/type-produits' render={()=> <TypeProduits/>} /> */}
                    </Switch>
                  </div>
                  
                </div>
              </div>
    )
  }
  export default function MainLayoutComponent() {
    function AbortUser(){
        if(userService && userService.getUserData() && userService.getUserData().id){
          return <Redirect to="/auth" />
        }
    }
    useEffect(()=>{
      function checkUserData() {
        const item = localStorage.getItem('userData')

      }

      window.addEventListener('storage', checkUserData)
      console.log('check userData', localStorage.getItem('userData'));
      return () => {
        window.removeEventListener('storage', checkUserData)
      }
    }, [])
    return (
      <Router>
        <div className="container-fluid">
          <Switch>
              {/* <div>
                <Header/>
              </div> */}
              <Route exact path="/auth" component={withRouter(Login)} />
              <Route exact path="/confidentialite-de-vos-donnees-personnelles-sur-la-smart-maintenance" component={withRouter(Condition)} />
              { userService && userService.getUserData() ?
                <>
                {/* <div>auth{JSON.stringify(userService)}</div> */}
                <Route path="/" component={withRouter(mainAppComponent)} /> 
                </>
                : 
                <>
                {/* <div>not auth{JSON.stringify(userService)}</div> */}
                <Route path="/" component={withRouter(mainAppComponent)} /> 
                </>
              }
              <Route path="*">
                404 Error
                {/* <NoMatch /> */}
              </Route>
            </Switch>
          </div>
      </Router>
    );
  }
  //sidik.ouattara@smile.ci 