import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
// import Pagination from "react-js-pagination";
import { Formik, Form, Field, ErrorMessage, FastField, FormikProps } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const restClientApi = new ResClientApi();
const userService = new UserService();

const DevisModal = (props) => {
    if (props.show) {
        // getTypeEquipement();
    }
    const [listeEquipement, setListeEquipement] = useState([]);
    const [listeDevi, setListeDevi] = useState([]);
    useEffect(() => {
        getTypeEquipement();
        getTypeDevi();
    }, []);

    function createDevisIntervention(param?) {
        const methode = 'devis/create';
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isInterview: true,
            datas: [{ ...param, interventionId: props.data.id }]
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success('Opération de réussie avec succès');
                    props.onHide(false)
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    function getTypeDevi() {
        const methode = 'typeDevis/getByCriteria'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: ''
            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setListeDevi(res.data.items ? res.data.items : []);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    function getTypeEquipement() {
        const methode = 'equipementClient/getByCriteria'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isSimpleLoading: false,
            data: {

            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setListeEquipement(res.data.items);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    return (
        <Modal
            {...props}
            backdrop="static"
            size="md"
            keyboard={false}>
            <Modal.Header closeButton className="border-0 pb-0">
                <Modal.Title className="text-green">Dévis N°{props?.data?.id}</Modal.Title>
            </Modal.Header>

            <Modal.Body >
                <div className="title-modal">
                    <i className="fa fa-paperclip" aria-hidden="true"></i> {props?.data?.designation}
                </div>
                <Formik
                    initialValues={{
                        nombreEquipement: props.data?.datasEquipement?.length,
                        // nombrePiece: '',
                        typeDevisCode: 'INTERVENTION',
                        // typeEquipement: '',
                        montant: props.data?.montant
                    }}
                    onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        createDevisIntervention(values);
                    }}
                // validationSchema={Yup.object().shape({
                //     nombreEquipement: Yup.number()
                //       .min(1, 'valeur minimum est 1')
                //       .required('Champs requis'),
                //       nombrePiece: Yup.number()
                //       .min(1, 'valeur minimum est 1')
                //       .required('Champs requis'),
                //     montant: Yup.number()
                //       .min(1, 'valeur minimum est 1')
                //       .required('Champs requis'),
                //     typeEquipement: Yup.string()
                //       .required('Champs requis'),
                //   })}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => {
                        return (
                            <Form>
                                <div className="form-group mt-3">
                                    <label htmlFor="nbreEquipement">Nbre d'équipement</label>
                                    <Field type="number" disabled={true} name="nombreEquipement" className="form-control rounded-0" id="nbreEquipement" placeholder="Nombre d'équipement" />
                                    {errors.nbreEquipement && touched.nbreEquipement &&
                                        <div className="text-danger">{errors.nbreEquipement}</div>}
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="nombrePiece">Nbre de pièce</label>
                                    <Field type="number" disabled={true} name="nombrePiece" className="form-control rounded-0" id="nombrePiece" placeholder="Nombre de pièce" />
                                    {errors.nombrePiece && touched.nombrePiece &&
                                        <div className="text-danger">{errors.nombrePiece}</div>}
                                </div>
                                {/* <div className="form-group">
                                    <label className="text-left w-100" htmlFor="typeDevisCode">Type de devis</label>
                                    <Field as="select" name="typeDevisCode" className="form-control rounded-0">
                                        <option>Selectionnez un type de devis</option>
                                        {listeDevi && listeDevi.map((item, index) =>
                                            <option key={index} value={item.code}>{item.libelle}</option>
                                        )}
                                    </Field>
                                </div> */}
                                <div className="form-group mt-3">
                                    <label htmlFor="nbreEquipement">Type d'équipement</label>
                                    <Field as="select" disabled={true} name="typeEquipement" className="form-control rounded-0">
                                        <option>Selectionnez un type d'équipement</option>
                                        {listeEquipement && listeEquipement.map((item, index) =>
                                            <option key={index} value={item.id}>{item.libelle}</option>
                                        )}
                                    </Field>
                                    {errors.typeEquipement && touched.typeEquipement &&
                                        <div className="text-danger">{errors.typeEquipement}</div>}
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="montant">Montant</label>
                                    <Field type="number" name="montant" className="form-control rounded-0" id="montant" placeholder="Montant" />
                                    {errors.montant && touched.montant &&
                                        <div className="text-danger">{errors.montant}</div>}
                                </div>
                                <div className="d-flex border-0 pt-0 justify-content-between mt-3">
                                    <Button type="reset" className="btn btn-outline-default btn-sm btn-text rounded-0 px-4"
                                        onClick={() => props.onHide(false)}>Fermer</Button>
                                    <button type="submit" className="btn btn-outline-success btn-sm btn-text rounded-0 px-4">
                                        Envoyer
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default DevisModal;