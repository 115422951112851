import React
// , {useState, useEffect, useRef} 
from 'react';
import { Formik
    // , Form, Field, ErrorMessage, FastField
 } from 'formik';
import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import {toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
// const restClientApi = new ResClientApi();

export class BdCommande extends React.Component{
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props){
        super(props);
        
        this.state = {
            position: {
                lat: 7.539989,
                lng: -5.547080,
                zoom: 13,
            },
            listeCommandeEntretien: [],
            listeCommandeDepanage: [],
            listeCommandeAchat: [],
            listeClientNotActif: [],
            setEntretien: 'entretiens',
            listeUserType: [],
            modalShow: false,
            affi: false,
            assignationModalShow: false,
            selectedLigne: -1,
            detailObjet: null,
            marker: [7.539989, -5.547080],
            page: {
                index: 1,
                size: 10,
            },
            pageSup: {
                index: 1,
                size: 10,
            },
            pageTech: {
                index: 1,
                size: 10,
            },
            pageClientNo: {
                index: 1,
                size: 10,
            },
         /*    formState: {
                id: null,
                lastName: '',
                email: '', 
                login: '', 
                firstName: '',
                telephone: '',
                userTypeCode: 'TECH',
                profilTechnicienCode: '',
                // userModeCode: 'OFFRE_ENTRETIEN',
            }, */
            total: 0,
            totalCommandeDepanage: 0,
            totalCommandeAchat: 0,
            totalClientNo: 0,
            search: ''
        }
    }

     /*     initForm(){
            this.setState({formState: {
                id: '',
                lastName: '',
                email: '', 
                login: '', 
                firstName: '',
                telephone: '',
                userTypeCode: 'TECH',
                profilTechnicienCode: '',
               
            }});
        } */

       selectedRow(val, index){
      }
/* 
      goForModif(param){
         const form = {
            id: param.id,
            lastName: param.lastName,
            email: param.email, 
            login: param.login, 
            firstName: param.firstName,
            telephone: param.telephone,
            userTypeCode: 'TECH',
            userTypeCode: param.profilTechnicienCode,
        }; 
        this.setState({formState: form});
      } */

      searchEvent(param){
            this.setState({search: param});
            this.recherche(param);
        }

        async recherche(val){
            await new Promise((r) => setTimeout(r, 500));
            this.getCommandeEntretien({userTypeLibelle: val})
        }

        getCommandeEntretien(param?, pageIndex?){
        const methode =  'offre/getNombreCommandeClient'
        const data = {
            index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: {
                typeOperation: "ENTRETIEN",
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res)=>{
                if(res && res.data && !res.data.hasError){
                    this.setState({listeCommandeEntretien: res.data.items[0].datasEntretiens ? res.data.items[0].datasEntretiens : []});
                    this.setState({total: res.data.count ? res.data.count : 0});
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err)=>{
                toast.error(err);
            });
        }
        getCommandeDepanage(param?, pageIndex?){
            const methode =  'offre/getNombreCommandeClient'
            const data = {
                index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
                size: this.state.pageSup.size,
                user: this.userService.getUserData() && this.userService.getUserData().id,
                data: {
                    typeOperation: "DEPANAGE",
                }
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        this.setState({listeCommandeDepanage: res.data.items[0] ? res.data.items[0] : []});
                        this.setState({totalCommandeDepanage: res.data.count ? res.data.count : 0});
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error(err);
                });
        } 
        getCommandeAchat(param?, pageIndex?){
            const methode =  'offre/getNombreCommandeClient'
            const data = {
                index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
                size: this.state.pageTech.size,
                user: this.userService.getUserData() && this.userService.getUserData().id,
                data: {
                    typeOperation: "ACHAT",
                }
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        this.setState({listeCommandeAchat: res.data.items[0].datasAchats ? res.data.items[0].datasAchats : []});
                        this.setState({totalCommandeAchat: res.data.count ? res.data.count : 0});
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error(err);
                });
        } 
        getClientNonActif(param?, pageIndex?){
            const methode =  'client/getClientNoAction'
            const data = {
                index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
                size: this.state.pageClientNo.size,
                user: this.userService.getUserData() && this.userService.getUserData().id,
                data: {}
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        this.setState({listeClientNotActif: res.data.mapItems ? res.data.mapItems : []});
                        this.setState({totalClientNo: res.data.count ? res.data.count : 0});
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error(err);
                });
        } 

      /*  getUserType(param?, pageIndex?){
            const methode = 'userType/getByCriteria'
            const data = {
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isCostomer: false,
                data: {
                }
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        this.setState({listeUserType: res.data.items});
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error(err);
                });
        } */

     /*    getUserProfil(param?, pageIndex?){
            const methode = 'profilTechnicien/getByCriteria'
            const data = {
                user: "25",
                isSimpleLoading: false,
                data: {
                    id: ''
                }
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        this.setState({listeUserType: res.data.items});
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error(err);
                });
        } */

   /*     createTechnicien(param, resetForm){
        this.setState({affi:true})
        const methode = param && param.id ? 'user/update' : 'user/create'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [param]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res)=>{
                if(res && res.data && !res.data.hasError){
                    toast.success("Operation effectuer avec succes");
                    this.setState({affi:false});
                    this.getCommande();
                    this.getAdmin();
                    this.getTech();
                    this.initForm();
                    resetForm(this.state.formState);
                } else {
                    toast.error("Operation echoué");
                    this.setState({affi:false});
                }
            })
            .catch((err)=>{
                toast.error('Erreur '+ err);
            });
        } */

         handlePageChange(ev){
            const index = ev;
            if (this.state.setEntretien === 'entretiens') {
                this.setState({page: {
                    index: index,
                    size: this.state.page.size
                }});
                this.getCommandeEntretien(null, ev);
            } else if (this.state.setEntretien === 'depannages') {
                this.setState({pageSup: {
                    index: index,
                    size: this.state.page.size
                }});
                this.getCommandeDepanage(null, ev);
            } else if (this.state.setEntretien === 'achatsdeclimatiseurs'){
                this.setState({pageTech: {
                    index: index,
                    size: this.state.page.size
                }});
                this.getCommandeAchat(null, ev);
            } else {
                this.setState({pageClientNo: {
                    index: index,
                    size: this.state.page.size
                }});
                this.getClientNonActif(null, ev);
            }
        }

        componentDidMount(){
            this.getCommandeEntretien();
            this.getCommandeDepanage();
            this.getCommandeAchat();
            this.getClientNonActif();
            /* this.getUserProfil(); */
        }

       /*  delete(val){
            Swal.fire({
                title: 'Attention',
                text: 'Êtes-vous sûre de vouloir la supprimer?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33', 
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
             }).then((result) => {
                if(result.value){
                 this.deleteTechnicien(val);
               }
             })
        } */

      /*   deleteTechnicien(param){
            const methode = 'user/delete'
            const data = {
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isCostomer: false,
                datas: [{
                    id: param.id
                }]
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        toast.success(res.data.status.message);
                        this.getCommande();
                        this.getAdmin();
                        this.getTech();
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error('Erreur '+ err);
                });
            } */

            showTable() {
                if (this.state.setEntretien === 'entretiens') {
                    return <><div className="col-md-12 px-0 bg-light">
                    <div className="tbl">
                        <div className="row-t-head mt-3">
                        <div className="cell">ID </div>
                            <div className="cell">Nom </div>
                            <div className="cell">Prenoms</div>
                            <div className="cell">Email</div>
                            <div className="cell">Commune</div>
                            <div className="cell">Quartier</div>
                            <div className="cell">Télephone</div>
                            <div className="cell">Ville</div>
                            <div className="cell"> Nombre</div>
                        </div>
                        {this.state.listeCommandeEntretien && this.state.listeCommandeEntretien.length>0 ? this.state.listeCommandeEntretien.map((item, index)=>
                            <div className="row-t mt-3" key={index} 
                                onClick={()=>this.selectedRow(item, index)} 
                                style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                                <div className="cell">{index+1}</div>
                                <div className="cell">{item.clientNom}</div>
                                <div className="cell">{item.clientPrenom}</div>
                                <div className="cell">{item.clientEmail}</div>
                                <div className="cell">{item.clientCommune}</div>
                                <div className="cell">{item.clientQuartier}</div>
                                <div className="cell">{item.clientTelephone}</div>
                                <div className="cell">{item.clientVille}</div>
                                <div className="cell">{item.nombre}</div>
                               {/*  <div className="cell">
                                    <button onClick={()=>this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                    <button onClick={()=>this.delete(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                </div> */}
                            </div>
                        ) : 'Aucune donnée'}
                    </div>
                    <div className="w-100 mt-2">
                    {this.state.listeCommandeEntretien && this.state.listeCommandeEntretien.length>0 ? 
                        <Pagination innerClass="pagination pull-right"
                        activePage={this.state.page.index}
                        itemsCountPerPage={this.state.page.size}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={5}
                        onChange={(event)=>this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                        /> : ''}
                    </div>
               </div>
                    </>
                } else if (this.state.setEntretien === 'depannages') {
                    return <> <div className="col-md-12 px-0 bg-light">
                    <div className="tbl">
                        <div className="row-t-head mt-3">
                            <div className="cell">ID </div>
                            <div className="cell">Nom </div>
                            <div className="cell">Prenoms</div>
                            <div className="cell">Email</div>
                            <div className="cell">Commune</div>
                            <div className="cell">Quartier</div>
                            <div className="cell">Télephone</div>
                            <div className="cell">Ville</div>
                            <div className="cell"> Nombre</div>
                        </div>
                        {this.state.listeCommandeDepanage && this.state.listeCommandeDepanage.length>0 ? this.state.listeCommandeDepanage.map((item, index)=>
                            <div className="row-t mt-3" key={index} 
                                onClick={()=>this.selectedRow(item, index)} 
                                style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                                <div className="cell">{index+1}</div>
                                <div className="cell">{item.clientNom}</div>
                                <div className="cell">{item.clientPrenom}</div>
                                <div className="cell">{item.clientEmail}</div>
                                <div className="cell">{item.clientCommune}</div>
                                <div className="cell">{item.clientQuartier}</div>
                                <div className="cell">{item.clientTelephone}</div>
                                <div className="cell">{item.clientVille}</div>
                                <div className="cell">{item.nombre}</div>
                               {/*  <div className="cell">
                                    <button onClick={()=>this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                    <button onClick={()=>this.delete(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                </div> */}
                            </div>
                        ) : 'Aucune donnée'}
                    </div>
                    <div className="w-100 mt-2">
                    {this.state.listeCommandeDepanage && this.state.listeCommandeDepanage.length>0 ? 
                        <Pagination innerClass="pagination pull-right"
                        activePage={this.state.pageSup.index}
                        itemsCountPerPage={this.state.pageSup.size}
                        totalItemsCount={this.state.totalCommandeDepanage}
                        pageRangeDisplayed={5}
                        onChange={(event)=>this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                        /> : ''}
                    </div>
               </div>
                    </>
                } else if (this.state.setEntretien === 'achatsdeclimatiseurs') {
                    return <> <div className="col-md-12 px-0 bg-light">
                    <div className="tbl">
                        <div className="row-t-head mt-3">
                        <div className="cell">ID </div>
                            <div className="cell">Nom </div>
                            <div className="cell">Prenoms</div>
                            <div className="cell">Email</div>
                            <div className="cell">Commune</div>
                            <div className="cell">Quartier</div>
                            <div className="cell">Télephone</div>
                            <div className="cell">Ville</div>
                            <div className="cell"> Nombre</div>
                        </div>
                        {this.state.listeCommandeAchat && this.state.listeCommandeAchat.length>0 ? this.state.listeCommandeAchat.map((item, index)=>
                            <div className="row-t mt-3" key={index} 
                                onClick={()=>this.selectedRow(item, index)} 
                                style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                               <div className="cell">{index+1}</div>
                                <div className="cell">{item.clientNom}</div>
                                <div className="cell">{item.clientPrenom}</div>
                                <div className="cell">{item.clientEmail}</div>
                                <div className="cell">{item.clientCommune}</div>
                                <div className="cell">{item.clientQuartier}</div>
                                <div className="cell">{item.clientTelephone}</div>
                                <div className="cell">{item.clientVille}</div>
                                <div className="cell">{item.nombre}</div>
                               {/*  <div className="cell">
                                    <button onClick={()=>this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                    <button onClick={()=>this.delete(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                </div> */}
                            </div>
                        ) : 'Aucune donnée'}
                    </div>
                    <div className="w-100 mt-2">
                    {this.state.listeCommandeAchat && this.state.listeCommandeAchat.length>0 ? 
                        <Pagination innerClass="pagination pull-right"
                        activePage={this.state.pageTech.index}
                        itemsCountPerPage={this.state.pageTech.size}
                        totalItemsCount={this.state.totalCommandeAchat}
                        pageRangeDisplayed={5}
                        onChange={(event)=>this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                        /> : ''}
                    </div>
               </div>
                    </>
                } else {
                    return <> <div className="col-md-12 px-0 bg-light">
                    <div className="tbl">
                        <div className="row-t-head mt-3">
                        <div className="cell">ID </div>
                            <div className="cell">Nom </div>
                            <div className="cell">Prenoms</div>
                            <div className="cell">Email</div>
                            <div className="cell">Télephone</div>
                        </div>
                        {this.state.listeClientNotActif && this.state.listeClientNotActif.length>0 ? this.state.listeClientNotActif.map((item, index)=>
                            <div className="row-t mt-3" key={index} 
                                onClick={()=>this.selectedRow(item, index)} 
                                style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                               <div className="cell">{index+1}</div>
                                <div className="cell">{item.nom}</div>
                                <div className="cell">{item.prenom}</div>
                                <div className="cell">{item.email}</div>
                                <div className="cell">{item.telephone}</div>
                               {/*  <div className="cell">
                                    <button onClick={()=>this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                    <button onClick={()=>this.delete(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                </div> */}
                            </div>
                        ) : 'Aucune donnée'}
                    </div>
                    <div className="w-100 mt-2">
                    {this.state.listeClientNotActif && this.state.listeClientNotActif.length>0 ? 
                        <Pagination innerClass="pagination pull-right"
                        activePage={this.state.pageClientNo.index}
                        itemsCountPerPage={this.state.pageClientNo.size}
                        totalItemsCount={this.state.totalClientNo}
                        pageRangeDisplayed={5}
                        onChange={(event)=>this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                        /> : ''}
                    </div>
               </div>
                    </> 
                }
            }

 
    render (){
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    return(
        <div className="content intervention mb-5">
            <div className="container px-0">
                <h2 className="mt-3 text-left">Base de données</h2>
            </div>
            <div className="container mt-4 pt-5 pb-2">
                <div className="row justify-content-between">
                    <h4 className="mt-3">Clients ayant passés des commandes</h4>
                   {/*  <input type="text" placeholder="Rechercher sur type user"
                     value={this.state.search} onChange={(val)=>this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0"/> */}
                </div>
            </div>

            <div className="container mt-4 pt-5 pb-2">
                <div className="row">
                    {/*  <button onClick={TouPage} type="button" className="btn btn-sm btn-outline-secondary rounded-pill mr-4">Tout</button> */}
                    <button onClick={() => this.setState({setEntretien:'entretiens'})} type="button" style={{ backgroundColor: this.state.setEntretien === 'entretiens' ? '#e3fef3' : 'transparent' }}
                        className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Entretiens</button>
                    <button onClick={() => this.setState({setEntretien:'depannages'})}  type="button" style={{ backgroundColor: this.state.setEntretien === 'depannages' ? '#e3fef3' : 'transparent' }}
                        className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Depannages</button>
                     <button  onClick={() =>this.setState({setEntretien:'achatsdeclimatiseurs'})} type="button" style={{backgroundColor: this.state.setEntretien === 'achatsdeclimatiseurs' ? '#e3fef3' : 'transparent'}}
                     className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Achats de climatiseur</button> 
                     <button  onClick={() =>this.setState({setEntretien:'clientnonactif'})} type="button" style={{backgroundColor: this.state.setEntretien === 'clientnonactif' ? '#e3fef3' : 'transparent'}}
                     className="btn btn-sm btn-outline-secondary text-dark rounded-pill">Clients non actif</button> 
                </div>
            </div>

            <div className="container bg-light">
               <div className="row bg-light">
                   {this.showTable()}
                   {/* <div className="col-md-4">
                        <div className="card card-shadow p-3 mt-5">
                            <div className="top-card text-left text-green">
                                <h5 className="font-weight-bold">achatsdeclimatiseurs</h5>
                            </div>
                            <hr/>
                            <div className="form">
                            <Formik
                                enableReinitialize={true} 
                                initialValues={this.state.formState}
                                validationSchema={Yup.object().shape({
                                    lastName: Yup.string()
                                      .required('Champs requis'),
                                    firstName: Yup.string()
                                      .required('Champs requis'),
                                    email: Yup.string()
                                      .email('Veuillez saisir un mail correcte')
                                      .required('Champs requis'),
                                    login: Yup.string()
                                      .required('Champs requis'),
                                    telephone: Yup.string()
                                      .matches(phoneRegExp, 'le numero doit comporter au moins 8 chiffres')
                                      .required('Champs requis'),
                                  })}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {
                                    this.createTechnicien(values, resetForm);
                                }}
                                >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    resetForm
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mt-3">
                                            <label htmlFor="nom">Nom</label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="nom"
                                                placeholder="Nom"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                            />
                                            {errors.firstName && touched.firstName && errors.firstName}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="last-name">Prenoms</label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                id="last-name"
                                                placeholder="Prenoms"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lastName}
                                            />
                                            {errors.lastName && touched.lastName && errors.lastName}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="login">Login</label>
                                            <input
                                                type="text"
                                                name="login"
                                                id="login"
                                                placeholder="Login"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.login}
                                            />
                                            {errors.login && touched.login && errors.login}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="profilTechnicienCode">type de profile</label>
                                            <select name="profilTechnicienCode" id="profilTechnicienCode" 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.profilTechnicienCode}
                                                    className="form-control rounded-0">
                                                <option>Selectionnez le type de profile</option>
                                                {this.state.listeUserType && this.state.listeUserType.map((item, index) =>
                                                    <option key={index} value={item.code}>{item.libelle}</option>
                                                )}
                                            </select>
                                            {errors.typeProduitId && touched.typeProduitId && errors.typeProduitId}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="e-mail">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="e-mail"
                                                placeholder="Email: xxxxx@xxx.com"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            {errors.email && touched.email && errors.email}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="telephone">Téléphone</label>
                                            <input
                                                type="text"
                                                name="telephone"
                                                id="telephone"
                                                placeholder="Tel: +225 XX XX XX XX"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.telephone}
                                            />
                                            {errors.telephone && touched.telephone && errors.telephone}
                                        </div>
                                    <div className="d-flex justify-content-between">
                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                        >Annuler</button>
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}                                        
                                            visible={this.state.affi}
                                        />
                                        <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                        >Valider</button>
                                    </div>                                   
                                    </form>
                                )}
                                </Formik>
                            </div>
                        </div>
                   </div>*/}
               </div>
            </div> 
        </div>
    )}
}