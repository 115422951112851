import React from 'react';

export default class UserService{
    constructor(){

    }

    saveUserData(data){
        if(window.localStorage){
            window.localStorage.setItem('userData', JSON.stringify(data));
        }
    }

    getUserData(){
        if(window.localStorage){
          return JSON.parse(window.localStorage.getItem('userData'));
        }
    }

    removeUserData(name?){
        localStorage.removeItem(name ? name : 'userData');
    }
}