import React from 'react';
// import logo from './logo.svg';
import './App.scss';
// import Header from './layouts/header';
import  MainLayoutComponent from './main-layout'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  //console.log('env', process.env.NODE_ENV)
  return (
    <div className="App">
        <ToastContainer />
        <div>
          <MainLayoutComponent/>
        </div>
    </div>
  );
}

export default App;
