import React
    // , {useState, useEffect, useRef} 
    from 'react';
// import { Formik, Form, Field, ErrorMessage, FastField } from 'formik';
// import * as Yup from 'yup';
import { Map, Circle, CircleMarker, Polyline, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import L, { divIcon } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import DevisModal from './devis-modal';
import DevisModa from './devis-moda';
import AssignationModal from './assignation-modal';
import MapingModal from './maping-modal';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { renderToStaticMarkup } from "react-dom/server";
// const restClientApi = new ResClientApi();
import withReactContent from 'sweetalert2-react-content';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

const MySwal = withReactContent(Swal);

L.Marker.prototype.options.icon = DefaultIcon;

export class Intervention extends React.Component {
    restClientApi = new ResClientApi();
    userService = new UserService();
    swalval = null;
    constructor(props) {
        super(props);
        this.state = {
            position: {
                lat: 7.539989,
                lng: -5.547080,
                zoom: 13,
            },
            listeIntervention: [],
            listeInterventionRow: true,
            modalShow: false,
            assignationModalShow: false,
            selectedLigne: -1,
            detailObjet: null,
            dataForModal: null,
            marker: [7.539989, -5.547080],
            limeOptions: { color: 'blue' },
            listeTechnicien: [],
            listeTechnicienRow: [],
            idInterv: {},
            val: {},
            page: {
                index: 1,
                size: 10,
            },
            total: 0,
            show: false,
            sho: false,
            devi: false,
            activinterval: false,
            search: '',
            link: '',
            // mapRef: null
        }
        this.mapRef = React.createRef();
    }
    tabl = [];

    searchEvent(param) {

        this.setState({ search: param });
        this.recherche(param);
    }

    async recherche(val) {
        await new Promise((r) => setTimeout(r, 500));
        this.getIntervention({ clientNom: val })
    }
    handleShow() {
        this.setState({ show: true })
    }
    handleDevis(param) {
        this.setState({ devi: true })
        this.setState({ idInterv: param })
    }
    handleSho(param) {
       /*  const { current = {} } = this.mapRef;
        const { leafletElement: map } = current;
        map.flyTo([param.latitudeClient, param.longitudeClient], 12); */
        if (param) {
            this.setState({ marker: [param.latitudeClient ? param.latitudeClient : '', param.longitudeClient ? param.longitudeClient : ''] });
            //   L.Map().flyTo([50.5, 30.5], 2)
        }
        this.setState({ sho: true })
        this.setState({ activinterval: true })
        this.getTechnicienRow(param);
        this.setState({
            val: setInterval(() => {
                this.getTechnicienRow(param);
            }, 15000)
        })
        //this.setState({assignationModalShow: true})}

    }
    handleClose() {
        this.setState({ show: false })
    }
    handleCloseDevis() {
        this.setState({ devi: false })
    }
    handleClos() {
        this.setState({ sho: false })
        this.setState({ activinterval: false })
        clearInterval(this.state.val)
    }


    getTechnicien(pageIndex?) {
        const methode = 'user/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                userTypeCode: 'TECH',
                profilTechnicienCode: 'DEPANAGE'
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeTechnicien: res.data.items ? res.data.items : [] });
                } else {
                    // toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                // toast.error('Erreur '+ err);
            });
    }

    getTechnicienRow(param?) {
        const methode = 'user/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            isCostomer: false,
            data: {
                id: param.userId,
                userTypeCode: 'TECH',
                profilTechnicienCode: 'DEPANAGE'
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    //this.setState({marker: [param.latitudeClient ? param.latitudeClient : '', param.longitudeClient ? param.longitudeClient : '']});

                    this.setState({ listeTechnicienRow: res.data.items[0].datasPositions ? res.data.items[0].datasPositions : [] });
                    this.setState(prevState => ({
                        listeTechnicienRow: [...prevState.listeTechnicienRow, this.state.marker]
                    }))
                } else {
                    // toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                // toast.error('Erreur '+ err);
            });
    }

    openModal() {
        return <DevisModal show={true} data={this.state.detailObjet}
            onHide={() => this.setState({ modalShow: false })} />
    }
    updatePayement(param?) {
        const methode = 'demandeIntervention/validerStatusPaiement'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            datas: [{
                id: param,
            }]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success("Operation effectuer avec succes");
                    this.setState({ show: false })
                    this.getIntervention();
                    this.setState({ selectedLigne: -1 });
                    this.setState({ detailObjet: null });
                } else {
                    toast.error("Operation echouée: " + res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    getInterventionById(param?, pageIndex?) {
        const methode = 'demandeIntervention/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: {
                id: param && param.id ? param.id : '',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ detailObjet: res.data.items ? res.data.items[0] : null });
                    this.setState({ modalShow: true });
                    // return (<DevisModal show={true} data={this.state.detailObjet}
                    // onHide={() => this.setState({modalShow: false})}/>)
                    // this.openModal();
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                //toast.error('Erreur '+ err);
            });
    }
    getRaport(param) {
        const methode = 'rapport/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                demandeInterventionId: param.id,
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.getRapportIntervention(res.data.items[0].id);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                // toast.error('Erreur '+ err);
            });
    }
    getRapportIntervention(param?) {
        const methode = 'rapport/getRapportIntervention'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: param,
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ link: res.data.pathFile })
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                // toast.error('Erreur '+ err);
            });
    }
    getIntervention(param?, pageIndex?) {
        const methode = 'demandeIntervention/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            size: this.state.page.size,
            data: {
                id: param && param.id ? param.id : '',
                libelle: '',
                clientNom: param ? param.clientNom : '',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeIntervention: res.data.items ? res.data.items : [] });
                    this.setState({ total: res.data && res.data.count ? res.data.count : 0 });
                    // this.selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                // toast.error('Erreur '+ err);
            });
    }

    getInterventionRow(param?) {
        const methode = 'demandeIntervention/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: {
                id: param.id,

            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeInterventionRow: res.data.items[0].datasEquipement[0].equipementId ? true : false });

                    // this.selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                // toast.error('Erreur '+ err);
            });
    }

    askBeforeCreate(val, reset?) {
        Swal.fire({
            title: 'Attention',
            text: 'Vous êtes sur le point de faire un devis',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                //  this.createMarque(val, reset);
            }
        })
    }

    handlePageChange(ev) {
        const index = ev;
        this.getIntervention(null, ev);
        this.setState({
            page: {
                index: index,
                size: this.state.page.size
            }
        });
    }

    selectedRow(val, index) {
        this.getRaport(val);
        const { current = {} } = this.mapRef;
        const { leafletElement: map } = current;
        if (val.latitudeClient && val.longitudeClient ) {
            map.flyTo([val.latitudeClient, val.longitudeClient], 12);  
        }
         //this.indexSelected = index
        this.setState({ selectedLigne: index });
        this.setState({ detailObjet: val });
        if (val) {
            this.setState({ marker: [val.latitudeClient ? val.latitudeClient : '', val.longitudeClient ? val.longitudeClient : ''] });

            //   L.Map().flyTo([50.5, 30.5], 2)
        }
    }

    componentDidMount() {
        this.getIntervention();
        this.getTechnicien();
    }

    showModalAffectation() {
        this.setState({ assignationModalShow: false })
    }

    formatDate(param) {
        let dateParam;
        if (param) {
            dateParam = param.split('-')[2] + '/' + param.split('-')[1] + '/' + param.split('-')[0]
        }
        return dateParam;
    }

    async assigner(val) {
        const myRef = React.createRef();
        this.swalval = await Swal.fire({
            title: 'Date d\'affectation',
            // html: `<div id="datepicker" ref={${myRef}}></div>`,
            html: `<input className="swal2-input" id="date-affectation" type="date" placeholder="Enter your current password..." />`,
            confirmButtonText: 'Valider',
            showCancelButton: true,
            cancelButtonText: 'Non',
            onOpen: () => {
            },
            preConfirm: () => ({
                dateAffectation: document.getElementById('date-affectation').value,
            })
        })
        let swalState = this.swalval && this.swalval.value || this.swalval.dismiss;
        if (swalState && swalState.dateAffectation || swalState === 'cancel') {
            if (!swalState.dateAffectation) {
                await MySwal.fire({ type: 'error', title: 'Veuillez saisir la date d\'affectation' });
                //   resetPw();
            } else if (swalState !== 'cancel') {
                this.formatDate(swalState.dateAffectation);
                this.assginationTechnicien({ ...val, dateIntervention: this.formatDate(swalState.dateAffectation) });
            }
        } else {
            await MySwal.fire({ type: 'error', title: 'Veuillez saisir la date d\'affectation' });
        }
    }

    assginationTechnicien(val?) {
        const methode = 'demandeIntervention/affectationTechnicien';
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isInterview: true,
            data: {
                dateIntervention: val.dateIntervention,
                interventionId: this.state.detailObjet.id,
                datasUserId: [{
                    userId: val.id
                }]
            }

        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.getIntervention();
                    // this.swalval.didDestroy(()=>{
                    // })
                    // props.onHide(false);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                // toast.error('Erreur '+ err);
            });
    }

    closeDevis() {
        this.setState({ modalShow: false });
    }

    closeAssignation() {

        this.setState({ assignationModalShow: false });
        this.getIntervention();
    }

    render() {
        const iconMarkup = renderToStaticMarkup(<i className=" fa fa-map-marker fa-3x text-danger" />);
        const customMarkerIcon = divIcon({
            html: iconMarkup,
        });
        let paymentText;
        if (this.state.detailObjet?.statutPaiement === 'NON') {
            paymentText = 'Payement pas encore éffectué'
        } else if (this.state.detailObjet?.statutPaiement === 'OUI') {
            paymentText = 'Payement éffectué'
        } else if  (this.state.detailObjet?.statutPaiement === 'SUCCESS') {
            paymentText = 'Payement éffectué'
        } else  {
            paymentText = 'En attente de payement'
        }
        return (
          <div className="content intervention mb-5">
            <div className="container px-0">
              <h2 className="mt-3 text-left">
                Suivi et pilotage des interventions
              </h2>
            </div>
            <div className="container mt-4 pt-5 pb-2">
              <div className="row justify-content-between">
                <h4 className="mt-3">Nouvelles interventions</h4>
                <input
                  placeholder="Rechercher par nom"
                  type="text"
                  value={this.state.search}
                  onChange={(val) => this.searchEvent(val.target.value)}
                  className="form-control search-input w-25 rounded-0"
                />
              </div>
            </div>
            <div className="container bg-light">
              <div className="row bg-light">
                <div className="col-md-8 px-0 bg-light">
                  <div className="tbl">
                    <div className="row-t-head mt-3">
                      <div className="cell">ID </div>
                      <div className="cell">Client </div>
                      <div className="cell">Lieu</div>
                      <div className="cell">Statut Payement</div>
                      <div className="cell">Mode Payement</div>
                      <div className="cell"> Date/Heure</div>
                      <div className="cell"> État Position</div>
                      <div className="cell"> État</div>
                      <div className="cell"> Action</div>
                    </div>
                    {this.state.listeIntervention &&
                    this.state.listeIntervention.length > 0
                      ? this.state.listeIntervention.map((item, index) => (
                          <div
                            className="row-t mt-3"
                            key={index}
                            onClick={() => this.selectedRow(item, index)}
                            style={{
                              background:
                                this.state.selectedLigne === index
                                  ? "#e3fef3"
                                  : "#fff",
                            }}
                          >
                            <div className="cell">{index + 1}</div>
                            <div className="cell">
                              {item.clientNom} {item.clientPrenom}
                            </div>
                            <div className="cell">{item.lieu}</div>
                            <div className="cell">{item.statutPaiement}</div>
                            <div className="cell">{item.modePayement}</div>
                            <div className="cell">{item.createdAt}</div>
                            <div className="cell">
                              <span className="btn btn-etat">
                                {item.statutPositionTech}
                              </span>{" "}
                            </div>
                            <div className="cell">
                              <button
                                onClick={() =>
                                  this.getInterventionRow(item, index)
                                }
                                className="btn btn-etat"
                              >
                                {item.libelleStatut}
                              </button>
                            </div>
                            <div className="cell">
                              {item.libelleStatut === "Affectation" && (
                                <button
                                  onClick={() => this.handleSho(item)}
                                  className="btn btn-etat  rounded-0 px-1 py-0"
                                >
                                  <i
                                    className="fa fa-eye icon"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                              {item.libelleStatut === "Terminé" && (
                                <button
                                  onClick={() => this.getRaport(item)}
                                  className="btn btn-etat mr-3 rounded-0 px-1 py-0"
                                >
                                  {" "}
                                  <a
                                    href={
                                      this.state.link
                                        ? this.state.link
                                        : "https://smart-maintenance.mct.ci/#/interventions"
                                    }
                                  >
                                    {" "}
                                    <i
                                      className="fa fa-file-pdf-o icon"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </button>
                              )}
                              {item.libelleStatut === "Terminé" && (
                                <button
                                  onClick={() => this.handleDevis(item)}
                                  className="btn btn-etat  rounded-0 px-1 py-0"
                                >
                                  {" "}
                                  <i
                                    className="fa fa-plus icon"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          </div>
                        ))
                      : "Aucune donnée"}
                  </div>
                  <MapingModal
                    show={this.state.sho}
                    onHide={() => this.handleClos()}
                    activinterval={this.state.activinterval}
                    positions={this.state.listeTechnicienRow}
                    line={this.state.limeOptions}
                    listeTechniciens={this.state.listeTechnicien}
                    customMarkerIcon={customMarkerIcon}
                    marker={this.state.marker}
                  />
                  <DevisModa
                    show={this.state.devi}
                    data={this.state.idInterv}
                    onHide={() => this.handleCloseDevis()}
                  />
                  <div className="w-100 mt-2">
                    {this.state.listeIntervention &&
                    this.state.listeIntervention.length > 0 ? (
                      <Pagination
                        innerClass="pagination pull-right"
                        activePage={this.state.page.index}
                        itemsCountPerPage={this.state.page.size}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={5}
                        onChange={(event) => this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-shadow p-3 mt-5">
                    <div className="top-card text-left text-green">
                      <h5 className="font-weight-bold">Affectation</h5>
                      <div className="font-weight-bold my-2">
                        {this.state.detailObjet?.libelleStatut}
                      </div>
                      {/* <div className="text-right">Id: {this.state.detailObjet?.id}</div> */}
                      <div className="option-detail">
                        <div className="my-2">
                          <i
                            className="fa fa-user fa-14"
                            aria-hidden="true"
                          ></i>
                          <span className="ml-3">
                            {this.state.detailObjet?.userNom}{" "}
                            {this.state.detailObjet?.userPrenom}
                          </span>
                        </div>
                        <div className="my-2">
                          <i
                            className="fa fa-clock-o fa-14"
                            aria-hidden="true"
                          ></i>
                          <span className="ml-3">
                            {this.state.detailObjet?.dateFinIntervention}
                          </span>
                        </div>
                        <div className="my-2">
                          <i
                            className="fa fa-map-marker fa-14"
                            aria-hidden="true"
                          ></i>
                          <span className="ml-3">
                            {this.state.detailObjet?.commune},{" "}
                            {this.state.detailObjet?.quartier}
                          </span>
                        </div>

                        <div className="right-side">
                          <button
                            type="button"
                            disabled={
                              this.state.detailObjet?.statutPaiement === "OUI"
                                ? true
                                : this.state.detailObjet?.statutPaiement ===
                                  "SUCCESS"
                                ? true
                                : false
                            }
                            className="btn btn-sm btn-warning rounded-0 px-1 py-0"
                            onClick={() => this.handleShow()}
                          >
                            {paymentText}
                          </button>
                          <Modal
                            show={this.state.show}
                            onHide={() => this.handleClose()}
                            animation={false}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Payement</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Voulez vous effectuer le payement ?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={() => this.handleClose(false)}
                              >
                                Fermer
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() =>
                                  this.updatePayement(
                                    this.state.detailObjet?.id,
                                  )
                                }
                              >
                                Oui
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="form">
                      <Map
                        ref={this.mapRef}
                        center={[
                          this.state.position.lat,
                          this.state.position.lng,
                        ]}
                        bounceAtZoomLimits={true}
                        zoom={this.state.position.zoom}
                        animate={true}
                      >
                        <TileLayer
                          noWrap={true}
                          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {this.state.listeTechnicien &&
                          this.state.listeTechnicien.map((item, index) => (
                            <Marker
                              position={[
                                item?.latitude || 0,
                                item?.longitude || 0,
                              ]}
                              key={index}
                              icon={customMarkerIcon}
                            >
                              <Popup className="rounded-0">
                                <div className="">
                                  <div className="border technicien-info p-1">
                                    <div className="text-normal">
                                      Client:{" "}
                                      <span className="font-weight-bold">
                                        {item.firstName}
                                        {item?.lastName}
                                      </span>
                                      {/* <img src={item?.fichierPanne} className="w-100"/> */}
                                    </div>
                                    <div className="text-normal">
                                      Téléphone:{" "}
                                      <span className="font-weight-bold">
                                        {item.telephone}
                                      </span>
                                    </div>
                                    <div className="text-normal">
                                      Temps:{" "}
                                      <span className="font-weight-bold">
                                        {item.temps}
                                      </span>
                                    </div>
                                    <div className="text-normal">
                                      equipements en charge:{" "}
                                      <span className="font-weight-bold">
                                        {item.nbreEquipement}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {this.state.detailObjet?.statutId === 1 ? (
                                  <div className="mt-2">
                                    <button
                                      className="btn btn-sm btn-success py-0"
                                      onClick={() => this.assigner(item)}
                                    >
                                      Assigner
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Popup>
                            </Marker>
                          ))}
                        {this.state.marker && this.state.marker.length > 0 ? (
                          <Marker position={this.state.marker}></Marker>
                        ) : (
                          ""
                        )}
                      </Map>
                      <div className="my-4">
                        <p className="text-justify text-size">
                          {this.state.detailObjet?.description}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          disabled={this.state.detailObjet?.statutId !== 1}
                          className="btn btn-outline-success btn-text rounded-0"
                          onClick={() =>
                            this.setState({ assignationModalShow: true })
                          }
                        >
                          Assigner à un technicien
                        </button>
                        <button
                          disabled={this.state.listeInterventionRow}
                          type="button"
                          className="btn btn-outline-success btn-text rounded-0"
                          onClick={() =>
                            this.getInterventionById(this.state.detailObjet)
                          }
                        >
                          Faire un dévis
                        </button>
                        {/* onClick={()=> this.setState({modalShow: true})}>Faire un dévis</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DevisModal
              show={this.state.modalShow}
              data={this.state.detailObjet}
              onHide={() => this.closeDevis()}
            />
            <AssignationModal
              show={this.state.assignationModalShow}
              data={this.state.detailObjet}
              onHide={() => this.closeAssignation()}
              techniciens={this.state.listeTechnicien}
            />
          </div>
        );
    }
}