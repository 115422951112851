import React from 'react';
import { Link, withRouter } from "react-router-dom"
import {
    Formik, Form, Field,
    // ErrorMessage, FastField 
} from 'formik';
import L, { divIcon } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import DevisModal from './devis-modal';
import 'leaflet/dist/leaflet.css';
import withReactContent from 'sweetalert2-react-content';
import { renderToStaticMarkup } from "react-dom/server";
// import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge'
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import Loader from 'react-loader-spinner';
import Select from 'react-select'
import MapingModal from './maping-modal';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

const MySwal = withReactContent(Swal);

L.Marker.prototype.options.icon = DefaultIcon;

export class Entretiens extends React.Component {
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props) {
        super(props);
        this.state = {
            detailObjet: null,
            affi: false,
            selectedLigne: -1,
            listeEntretien: [],
            listeEntretienRow: [],
            listeTechnicienRow: [],
            limeOptions: { color: 'blue' },
            ligne: '',
            listeUser: [],
            perioContrat: [],
            periodicitesContrat: [],
            marker: [6.194727, -5.757227],
            page: {
                index: 1,
                size: 10,
            },
            total: 0,
            search: '',
            link: '',
            linkRapport: '',
            listeInterv: null,
            show: false,
            rapport: false,
            sho: false,
            activinterval: false,
            startDate: moment().format('DD/MM/YYYY'),
            drop: false,
        }
    }

    componentDidMount() {
        this.getEntretien();
        //this.getUser();
        //this.getPeriode();
    }
    selectedRow(val, index) {
        console.log('index', index)
        this.setState({ selectedLigne: index });
        this.setState({ detailObjet: val });
        this.getContrat(val);
        /*  this.getPeriode(val.id); */
    }
    selectedRo(val, index) {
        console.log('index', index)
        this.setState({ selectedLigne: index });
    }
    /*   handleShow() {
          this.setState({ show: true })
      }
      handleSho(param) {
          //const {current= {}} = this.mapRef;
          //const { leafletElement: map} = current;
          //map.flyTo([param.latitudeClient, param.longitudeClient], 12);
          if (param) {
              this.setState({ marker: [param.clientLatitude ? param.clientLatitude : '', param.clientLongitude ? param.clientLongitude : ''] });
              //   L.Map().flyTo([50.5, 30.5], 2)
          }
  
          this.setState({ sho: true })
          this.setState({ activinterval: true })
          this.getTechnicienRow(param);
          this.setState({
              val: setInterval(() => {
                  this.getTechnicienRow(param);
              }, 15000)
          })
          //this.setState({assignationModalShow: true})}
  
      } */
    getContrat(param?) {
        const methode = 'contrat/getClientContrat'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            datas: [
                {
                    clientId: param.clientId
                }
            ]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ link: res.data.pathFile })
                    //this.getRapportIntervention(res.data.items[0].id);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                // toast.error('Erreur '+ err);
            });
    }
    getTechnicienRow(param?) {
        const methode = 'user/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            isCostomer: false,
            data: {
                id: param.userId,
                userTypeCode: 'TECH',
                profilTechnicienCode: 'ENTRETIEN'
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeTechnicienRow: res.data.items[0].datasPositions ? res.data.items[0].datasPositions : [] });
                    this.setState(prevState => ({
                        listeTechnicienRow: [...prevState.listeTechnicienRow, this.state.marker]
                    }))
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    handlePageChange(ev) {
        const index = ev;
        this.getEntretien(ev);
        this.setState({
            page: {
                index: index,
                size: this.state.page.size
            }
        });
    }
    /*    handleShow() {
           this.setState({ show: true })
       } */
    /*  handleRapport() {
         this.setState({ rapport: true })
         this.setState({ periodicitesContrat: this.state.perioContrat })
     } */
    /*   closeRapport() {
          this.setState({ rapport: false })
      } */
    /*  handleClose() {
         this.setState({ show: false })
     } */

    searchEvent(param) {

        this.setState({ search: param });
        this.recherche(param);
    }

    async recherche(val) {
        await new Promise((r) => setTimeout(r, 500));
        this.getEntretien({ typeOffreLibelle: val })
    }

    getEntretienRow(param?, pageIndex?) {
        const methode = 'offre/getByCriteria';
        //setPerio(param.id);
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            size: this.state.page.size,
            data: {
                id: param.id,
                typeOffreId: '',
                libelle: param ? param.libelle : '',
                description: ''
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeEntretienRow: res.data.items ? res.data.items : [] });
                    this.setState({ total: res.data.count ? res.data.count : 0 });
                    //setFormData({typeDevisCode: entretien ? 'ENTRETIEN' : 'INTERVENTION'})
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err) => {
                //toast.error('aucun equipement associer ');
            });
    }

    getEntretien(param?, pageIndex?) {
        const methode = 'offre/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: {
                typeOffreId: '',
                libelle: param ? param.libelle : '',
                typeOffreLibelle: param ? param.typeOffreLibelle : '',
                description: '',

            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeEntretien: res.data.items ? res.data.items : [] });
                    this.setState({ total: res.data.count ? res.data.count : 0 });
                    //this.selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err) => {
                //toast.error('Erreur ' + err);
            });
    }

    /*   askBeforeCreate(val) {
          Swal.fire({
              title: 'Attention',
              text: 'Êtes-vous sûre de vouloir la affecter ce technicien à cet entretien ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non'
          }).then((result) => {
              if (result.value) {
                  this.affectationTechnicien(val);
              }
          })
      } */

    /*   getRapport(param?) {
          const methode = 'rapport/getByCriteria'
          const data = {
              user: this.userService.getUserData() && this.userService.getUserData().id,
              data: {
                  periodiciteId: param,
              }
          };
          this.restClientApi.executePostApi(methode, data)
              .then((res) => {
                  if (res && res.data && !res.data.hasError) {
                      this.getRapportEntrtien(res.data.items[0].id);
                  } else {
                      toast.error(res.data.status.message);
                  }
              })
              .catch((err) => {
                  // toast.error('Erreur '+ err);
              });
      } */
    /*   getRapportEntrtien(param?) {
          const methode = 'rapport/getRapportEntretien'
          const data = {
              user: this.userService.getUserData() && this.userService.getUserData().id,
              data: {
                  id: param,
              }
          };
          this.restClientApi.executePostApi(methode, data)
              .then((res) => {
                  if (res && res.data && !res.data.hasError) {
                      this.setState({ linkRapport: res.data.pathFile })
                  } else {
                      toast.error(res.data.status.message);
                  }
              })
              .catch((err) => {
                  // toast.error('Erreur '+ err);
              });
      } */
    /*  getUser(pageIndex?) {
         const methode = 'user/getByCriteria'
         const data = {
             user: this.userService.getUserData() && this.userService.getUserData().id,
             index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
             size: this.state.page.size,
             data: {
                 userTypeCode: 'TECH',
                 profilTechnicienCode: 'ENTRETIEN'
             }
         };
         this.restClientApi.executePostApi(methode, data)
             .then((res) => {
                 if (res && res.data && !res.data.hasError) {
                     this.setState({ listeUser: res.data.items });
                 } else {
                     toast.error(res.data.status.message);
                 }
             })
             .catch((err) => {
                 toast.error('Erreur ' + err);
             });
     } */
    /* getPeriode(param) {
        const methode = 'periodiciteContrat/getByCriteria'
        const valu = param
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                offreId: valu
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ perioContrat: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    } */

    /*  updatePayement(param?) {
         const methode = 'offre/validerStatutPaiement'
         const data = {
             user: this.userService.getUserData() && this.userService.getUserData().id,
             datas: [{
                 id: param,
             }]
         };
         this.restClientApi.executePostApi(methode, data)
             .then((res) => {
                 if (res && res.data && !res.data.hasError) {
                     toast.success("Operation effectuer avec succes");
                     this.setState({ show: false })
                     this.getEntretien();
                     this.setState({ detailObjet: null });
                     this.setState({ selectedLigne: -1 });
                 } else {
                     toast.error("Operation echouée: " + res.data.status.message);
                 }
             })
             .catch((err) => {
                 toast.error('Erreur ' + err);
             });
     } */
    /*  handleClos() {
         this.setState({ sho: false })
         this.setState({ activinterval: false })
         clearInterval(this.state.val)
     } */
    /*  affectationTechnicien(val?, resetForm?) {
         this.setState({ affi: true })
         const methode = 'offre/affectationTechnicien';
         const data = {
             user: this.userService.getUserData() && this.userService.getUserData().id,
             isInterview: false,
             data: {
                 dateIntervention: val.dateIntervention,
                 offreId: this.state.detailObjet.id,
                 periodiciteContratId: val.periodiciteContratId,
                 datasUserId: [{
                     userId: this.state.listeInterv.value,
                 }]
             }
 
         };
         this.restClientApi.executePostApi(methode, data)
             .then((res) => {
                 if (res && res.data && !res.data.hasError) {
                     toast.success(res.data.status.message);
                     this.setState({ affi: false });
                     this.getEntretien();
                     // resetForm();
                 } else {
                     toast.error(res.data.status.message);
                     this.setState({ affi: false });
                 }
             })
             .catch((err) => {
                 toast.error('Erreur ' + err);
             });
     } */
    /*   getMessage() {
          toast.error("Fichier du rapport non disponible");
      } */

    handleChange(event) {
        this.setState({ search: event.libelle });
        this.getEntretien({ libelle: event.libelle });
    }
    /*  handleChangeS(listeInterv) {
         this.setState({ listeInterv });
 
     } */

    /*   displayStatePayment() {
  
      } */


    render() {
        const iconMarkup = renderToStaticMarkup(<i className=" fa fa-map-marker fa-3x text-danger" />);
        const customMarkerIcon = divIcon({
            html: iconMarkup,
        });
        let paymentText;
        if (this.state.detailObjet?.statutPaiement === 'NON') {
            paymentText = 'Payement pas encore éffectué'
        } else if (this.state.detailObjet?.statutPaiement === 'OUI') {
            paymentText = 'Payement éffectué'
        } else {
            paymentText = 'En attente de Payement'
        }
        return (
          <div className="content intervention mb-5">
            <div className="container px-0">
              <h2 className="mt-3 text-left">
                Suivi et pilotage des entretiens
              </h2>
            </div>
            <div className="container mt-4 pt-5 pb-2">
              <div className="row justify-content-between">
                <h4 className="mt-3">Nouveaux entretiens</h4>
                <input
                  type="text"
                  placeholder="Rechercher par offre"
                  value={this.state.search}
                  onChange={(ev) => this.searchEvent(ev.target.value)}
                  className="form-control search-input w-25 rounded-0"
                />
              </div>
            </div>
            <div className="container bg-light">
              <div className="row bg-light">
                <div className="col-md-12 px-0 bg-light">
                  <div className="tbl">
                    <div className="row-t-head mt-3">
                      <div className="cell">ID </div>
                      <div className="cell">Nom </div>
                      <div className="cell">Prenom </div>
                      <div className="cell">Offre </div>
                      <div className="cell">Mode payement </div>
                      <div className="cell"> Date/Heure </div>
                      {/* <div className="cell"> État </div> */}
                      <div className="cell"> Action </div>
                    </div>
                    {this.state.listeEntretien.length > 0
                      ? this.state.listeEntretien.map((item, index) => (
                          <div
                            className="row-t mt-3"
                            key={index}
                            onClick={() => this.selectedRow(item, index)}
                            style={{
                              background:
                                this.state.selectedLigne === index
                                  ? "#e3fef3"
                                  : "#fff",
                            }}
                          >
                            <div className="cell">{index + 1}</div>
                            <div className="cell">{item.clientNom}</div>
                            <div className="cell">{item.clientPrenom}</div>
                            <div className="cell">{item.typeOffreLibelle}</div>
                            <div className="cell">{item.modePayement}</div>
                            <div className="cell">
                              {item.createdAt} {item.hours}
                            </div>
                            {/* <div className="cell">
                                            <button onClick={() => this.getEntretienRow(item, index)} className="btn btn-etat">{item.statutEntretien}</button>
                                        </div> */}
                            <div className="cell">
                              {item.statutPaiement === "OUI" && (
                                <button
                                  onClick={() => this.getContrat(item)}
                                  className="btn btn-etat rounded-0 px-1 py-0"
                                >
                                  {" "}
                                  <a
                                    href={
                                      this.state.link
                                        ? this.state.link
                                        : "https://smart-maintenance.mct.ci/#/entretiens"
                                    }
                                  >
                                    {" "}
                                    <i
                                      className="fa fa-handshake-o"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </button>
                              )}
                              <Link to={`/entretiens-prestation/${item.id}`}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </Link>
                            </div>
                          </div>
                        ))
                      : "Aucune donnée ..."}
                    {/*   <Modal show={this.state.rapport} onHide={() => this.closeRapport()} animation={false}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Liste des rapports</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {this.state.periodicitesContrat ? this.state.periodicitesContrat.map((item, id) =>
                                            <div className="row-t mt-3" key={id}>
                                                <div onClick={() => this.getRapport(item.id)} className="btn mr-3 btn-outline-dark"> Rapport du {item.mois}/{item.annee}</div>
                                                {this.state.linkRapport ? <button className="btn btn-etat mr-3 rounded-0 px-1 py-0"><a href={this.state.linkRapport}> <i className="fa fa-eye" aria-hidden="true"></i></a></button> : <button onClick={() => this.getMessage()} className="btn btn-etat mr-3 rounded-0 px-1 py-0"><i className="fa fa-eye" aria-hidden="true"></i></button>}
                                            </div>
                                        ) : 'Aucune donnée ...'}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => this.closeRapport(false)}>
                                            Fermer
                                    </Button>
                                    </Modal.Footer>
                                </Modal> */}

                    {/*  <MapingModal
                                    show={this.state.sho}
                                    onHide={() => this.handleClos()}
                                    activinterval={this.state.activinterval}
                                    positions={this.state.listeTechnicienRow}
                                    line={this.state.limeOptions}
                                    listeTechniciens={this.state.listeUser}
                                    customMarkerIcon={customMarkerIcon}
                                    marker={this.state.marker}
                                /> */}
                  </div>
                  <div className="w-100 mt-2">
                    {this.state.listeEntretien &&
                    this.state.listeEntretien.length > 0 ? (
                      <Pagination
                        innerClass="pagination pull-right"
                        activePage={this.state.page.index}
                        itemsCountPerPage={this.state.page.size}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={5}
                        onChange={(event) => this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {}
              </div>
            </div>
          </div>
        );
    }
}