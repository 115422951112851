import React
// , {useState, useEffect, useRef} 
from 'react';
import { Formik
    // , Form, Field, ErrorMessage, FastField
 } from 'formik';
// import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import '../styles/produits.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';

export class Achat extends React.Component{
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props){
        super(props);
        
        this.state = {
            listeClientsAchats: [],
            listeProduitAchat: [],
            listeProduitType: [],
            selectedLigne: -1,
            detailObjet: null,
            affi: false,
            page: {
                index: 1,
                size: 10,
            },
            total: 0,
            search: '',
        }
    }

       selectedRow(val, index){
            // indexSelected = index
            this.setState({selectedLigne:index});
            this.setState({detailObjet:val});
      }

      searchEvent(param){
            
        this.setState({search: param});
        this.recherche(param);
    }

    async recherche(val){
        await new Promise((r) => setTimeout(r, 500));
        this.getClientsAchat(val)
    }
    getProduitClientAchat(param?,pageIndex?){
        const methode = 'demandeAchat/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: param.id ,
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res)=>{
                if(res && res.data && !res.data.hasError){
                    //console.log('produit',res.data)
                    this.setState({listeProduitAchat: res.data.items[0].datasProduit ? res.data.items[0].datasProduit : []});
                    this.setState({total: res.data.count ? res.data.count : 0});
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err)=>{
                toast.error(err);
            });
    }
       getClientsAchat(param?, pageIndex?){
        const methode = 'demandeAchat/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: "",
                clientNom:param,
                clientPrenom: "",
              
            }
        };
        this.restClientApi.executePostApi(methode,data)
            .then((res)=>{                
                if(res && res.data && !res.data.hasError){
                    this.setState({listeClientsAchats: res.data.items ? res.data.items : []});
                    this.setState({total: res.data.count ? res.data.count : 0});
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err)=>{
                toast.error(err);
            });
        }

       /* createCategories(param, resetForm){
        this.setState({affi:true})
        const methode = param && param.id ? 'categorieProduit/update' :'categorieProduit/create';
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [{...param, datasDocuments: this.state.datasDocuments}]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res)=>{
                if(res && res.data && !res.data.hasError){
                    toast.success("Operation effectuer avec succes");
                    this.setState({affi:false});
                    this.getClientsAchat();
                    this.initForm();
                    resetForm(this.state.formState);
                } else {
                    toast.error("Operation echoué");
                    this.setState({affi:false});
                }
            })
            .catch((err)=>{
                toast.error('Erreur '+ err);
            });
        } */

         handlePageChange(ev){
            const index = ev;
            this.getClientsAchat(this.state.search, ev);
            this.setState({page: {
                index: index,
                size: this.state.page.size
            }});
        }

        componentDidMount(){
            this.getClientsAchat();
            //this.getTypeProduit();
        }

    render (){
    return(
        <div className="content intervention mb-5">
            <div className="container px-0">
                <h2 className="mt-3 text-left">Gestion des Achats</h2>
            </div>
            <div className="container mt-4 pt-5 pb-2">
                <div className="row justify-content-between">
                    <h4 className="mt-3">Nouveaux Achats</h4>
                    <input type="text" placeholder="Rechercher par nom"
                     value={this.state.search} onChange={(val)=>this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0"/>
                </div>
            </div>
            <div className="container bg-light">
               <div className="row bg-light">
                   <div className="col-md-8 px-0 bg-light">
                   <div className="tbl">
                        <div className="row-t-head mt-3">
                            <div className="cell">ID </div>
                            <div className="cell">Nom complet</div>
                            <div className="cell">Date achat</div>
                            <div className="cell">Montant</div>
                            <div className="cell">Moyen Payement</div>
                           {/*  <div className="cell">Statut livraison</div> */}
                            <div className="cell"> Option</div>
                        </div>
                        {this.state.listeClientsAchats && this.state.listeClientsAchats.length>0 ? this.state.listeClientsAchats.map((item, index)=>
                            <div className="row-t mt-3" key={index} 
                                onClick={()=>this.selectedRow(item, index)} 
                                style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                                <div className="cell">{index+1}</div>
                                <div className="cell">{item.clientNom} {item.clientPrenom}</div>
                                <div className="cell">{item.createdAt}</div>
                                <div className="cell">{item.montantAchat}</div>
                                <div className="cell">{item.modePaiementLibelle}</div>
                               {/*  <div className="cell">{item.statutLivraison}</div> */}
                                <div className="cell">
                                <button onClick={()=>this.getProduitClientAchat(item, index)}  className="btn btn-etat"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        ) : 'Aucune donnée'}
                    </div>
                    <div className="w-100 mt-2">
                       {this.state.listeClientsAchats && this.state.listeClientsAchats.length>0 ? 
                        <Pagination innerClass="pagination pull-right"
                        activePage={this.state.page.index}
                        itemsCountPerPage={this.state.page.size}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={5}
                        onChange={(event)=>this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                        /> : ''}
                    </div>
                   </div>
                   <div className="col-md-4">
                        <div className="card card-shadow p-3 mt-5">
                            <div className="top-card text-left text-green">
                                <h5 className="font-weight-bold">Détail des produits</h5>
                                 <div className="text-right">Id: {this.state.detailObjet?.id}</div> 
                                {this.state.detailObjet ? <div className="option-detail">
                                     <div className="my-2"> 
                                        <i className="fa fa-map-marker fa-14" aria-hidden="true"></i>
                                        <span className="ml-3">{this.state.detailObjet?.commune}</span>
                                    </div> 
                                    <div className="my-2"> 
                                        <i className="fa fa-clock-o fa-14" aria-hidden="true"></i>
                                        <span className="ml-3">{this.state.detailObjet?.createdAt}</span>
                                    </div>
                                </div> : ''}
                            </div>
                            <hr/>
                            <div className="form">                           
                                    <Card >
                                        <SimpleBar style={{ maxHeight: 400 }}>
                                            <div className="form-group">
                                                <div className="row-t-head mt-3">                                                     
                                                    <div style={{fontSize: 20}} className="cell">Listes des produits </div>                                                     
                                                </div>
                                                <div className="col-md-12 px-0 bg-light">
                                                    <div className="tbl">                                 
                                                    {this.state.listeProduitAchat && this.state.listeProduitAchat.map((item, index)=>
                                                        <div className="row-t mt-3 ml-3" key={index} 
                                                            style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                                                            <div className="cell">{item.produitLibelle }</div> 
                                                        </div>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </SimpleBar>
                                    </Card>                          
                            </div>
                        </div>
                   </div>
               </div>
            </div>
        </div>
    )}
}