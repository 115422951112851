import React from 'react';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import '../styles/condition.scss';
import 'font-awesome/css/font-awesome.min.css';

const restClientApi = new ResClientApi();
const userService = new UserService();
export default function Condition(props) {



  return (
    <div className=" h-100">
      <div className="bloc_div visible_to_all template_custom_id___basic_block" style={{ marginTop: '0px', marginBottom: '0px' }} data-bloc-id="9850" data-bloc-name="Confidentialité de vos données personnelles sur Orange et moi" template-custom-id="__basic_block" visible-to="all" refresh-interval="0" start-date="" end-date="">
        <div className="" style={{ padding: "5px 0px" }}>
          <div className="container" style={{ maxWidth: "940px", padding: "0px 10px" }}>
            <div className="" style={{ padding: "0" }}>
              <div className="etn-orange-portal--">

                <div className="text-center">
                  <img className="w-25" src={require('../assets/img/drawable-xxxhdpi/initial-logo.png')} />
                  <div className="text-light">By MCT</div>
                </div>

                <div id="container">
                  <div className="news-content o-container">
                    <div className="news-title" style={{ textAlign: "center" }} >
                      <h1>Confidentialité de vos données personnelles sur SMART MAINTENANCE BY MCT</h1>
                    </div>
                    <p className="teaser f14">&nbsp;</p>
                    <p><br></br> <span style={{ fontSize: "18px" }}>De par cette page web MCT vous vous informe sur la collecte de vos données personnelles (Noms, prénoms, emails, numéros et localisation) ainsi que de leur utilisation par son application SMART MAINTENANCE BY MCT.</span></p>
                    <p>&nbsp;</p>
                    <p><br></br><span style={{ fontSize: "18px" }}>MCT tient à vous rassurer d’emblée que vos données à caractère personnel sont protégées par la loi No 2013-450 du 19 juin 2013 relative à la protection des données à caractère personnel en République de Côte d’Ivoire. Son application SMART MAINTENANCE BY MCT traite vos données personnelles en conformité avec cette loi. Vos données personnelles sont utilisées à des fins de simplification d’usage visant à vous fournir une meilleure expérience d’utilisation de l'application SMART MAINTENANCE BY MCT</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{ fontSize: "18px" }}>Par ailleurs, MCT s’engage à ne divulguer, ni à ne partager vos données avec des tiers. Le traitement avec précaution de vos données personnelles est très important pour nous. C'est pourquoi nous avons pris toutes les mesures de sécurité techniques et organisationnelles nécessaires pour protéger vos données personnelles. Coordonnées de contact "Si vous avez des questions concernant cette déclaration de confidentialité, n'hésitez pas à nous contacter par <a href="mailto:contact@mct.ci"> e-mail : contact@mct.ci</a> ou +225 27 21 35 40 40 / +225 07 09 09 09 42</span></p>
                      <div className="row">
                        <div className="col-md-12" style={{marginLeft: "19px;"}}>
                          <a download="Smart-maintenance" href="/Smart-maintenance.apk" style={{float: "left;"}}><button type="button" className="btn btn-refresh mb-2">Télécharger l'APK</button><i style={{marginLeft: "-14px;"}} class="fa fa-download" aria-hidden="true"></i></a>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}