import React
, {
    useState,
    useEffect
} from 'react';
import {
    Formik, Form, Field,
    // ErrorMessage, FastField 
} from 'formik';
import * as Yup from 'yup';
import { Link, withRouter } from "react-router-dom"
import Card from 'react-bootstrap/Card'
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
import { toast } from 'react-toastify';
import ResClientApi from '../services/restClientApi'
import Pagination from "react-js-pagination";
import UserService from '../services/user-service';
import Loader from 'react-loader-spinner';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
//import { Scrollbars } from 'react-custom-scrollbars';

export function DemandesDevis(props) {
    const restClientApi = new ResClientApi();
    const userService = new UserService();
    const [formData, setFormData] = useState({
        nombreEquipement: '',
        //nombrePiece: '',
        //typeDevisCode: '',
        //typeEquipement: '',
        periodiciteContratId: '',
        montant: '',
    });

    const [listeAchat, setListeAchat] = useState([]);
    const [entretien, setEntretien] = useState('entretien');
    const [activbouton, setActivbouton] = useState(true);
    const [affi, setAffi] = useState(false);
    const [shoclien, setShoclien] = useState(false);
    const [listeIntervention, setListeIntervention] = useState([]);
    const [listeInterventionRow, setListeInterventionRow] = useState([]);
    const [listeEntretien, setListeEntretien] = useState([]);
    const [listeEntretienRow, setListeEntretienRow] = useState([]);
    const [listePassageEntretien, setListePassageEntretien] = useState([]);
    const [perioContrat, setPerioContrat] = useState([]);
    const [perio, setPerio] = useState();
    const [detailObjet, setDetailObjet] = useState(0);
    const [selectedLigne, setSelectedLigne] = useState(-1);
    const [total, seTtotal] = useState(0);
    const [totalintervantion, seTtotalintervantion] = useState(0);
    const [selectent, setSelectent] = useState({
        value1: '',
        value2: ''
    });
    const [recherche, setRecherche] = useState({
        startDate: null,
        endDate: null,
        focusedInput: ''
    });
    const [totalAchat, setTotalAchat] = useState(0)
    const [pagine, setPagine] = useState({
        index: 1,
        size: 10,
    })
    const [pagineintervantion, setPagineintervantion] = useState({
        index: 1,
        size: 10,
    })
    const [pageAchat, setPageAchat] = useState({
        index: 1,
        size: 10,
    })

    useEffect(() => {
        //getTypeEquipement();
        //getTypeDevi();
        getIntervention();
        getEntretien();
        //getAchat();
    }, []);
    //   let indexSelected;

    function getPeriode(param) {
        const methode = 'periodiciteContrat/getByCriteria'
        setPerio(param);
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                offreId: param
            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setPerioContrat(res.data.items ? res.data.items : []);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }
    //const [detailObjet, setDetailObjet] = useState(listeIntervention[0]);
    function selectedRow(val, index, type?) {
        //   indexSelected = index
        setSelectedLigne(index);
        setDetailObjet(val)
        //getPeriode(val.id)
    }

    function createDevisIntervention(param, reset) {
        setAffi(true);
        const methode = 'devis/create';
        let request;
        if (entretien === 'entretien') {
            request = [{ offreEntretienId: detailObjet.id, nombreEquipement: selectent.value1, periodiciteContratId: param.periodiciteContratId, typeDevisCode: 'ENTRETIEN', montant: selectent.value2 }];
        } else if (entretien === 'intervention') {
            request = [{ ...param, interventionId: detailObjet.id, nombreEquipement: selectent.value1, typeDevisCode: 'INTERVENTION' }]
        } else {
            request = [{ ...param, achatId: detailObjet.id, typeDevisCode: 'ACHACT' }]
        }
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isInterview: true,
            datas: request
        };
        // return;
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success("Operation effectuer avec succes");
                    setAffi(false);
                    entretien === 'entretien' ? getEntretien() : entretien === 'intervention' && getIntervention();
                    reset({
                        nombreEquipement: '',
                        //nombrePiece: '',
                        //typeDevisCode: '',
                        //typeEquipement: '',
                        montant: '',
                    })

                } else {
                    toast.error("Operation echoué");
                    setAffi(false);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    function handleSho(param) {
        setShoclien(true);
        getEntretienRow(param);
    }
    function handleClos() {
        setShoclien(false);
    }
    /*  function getTypeEquipement() {
      const methode = 'equipementClient/getByCriteria'
          const data = {
              user: userService.getUserData() && userService.getUserData().id,
              isSimpleLoading: false,
              data: {
     
              }
          };
          restClientApi.executePostApi(methode, data)
          .then((res) => {
              if (res && res.data && !res.data.hasError) {
                 setListeEquipement(res.data.items ? res.data.items : []);
              } else {
                 toast.error(res.data.status.message);
              }
          })
          .catch((err) => {
              toast.error('Erreur ' + err);
          });
      } */

    /* function getTypeDevi() {
       const methode = 'typeDevis/getByCriteria'
           const data = {
               user: userService.getUserData() && userService.getUserData().id,
               isSimpleLoading: false,
               data: {
                   id:""
               }
           };
           restClientApi.executePostApi(methode, data)
           .then((res) => {
               if (res && res.data && !res.data.hasError) {
                   setListeDevi(res.data.items ? res.data.items  :[]);
               } else {
                  toast.error(res.data.status.message);
               }
           })
           .catch((err) => {
               toast.error('Erreur ' + err);
           });
       } */

    function handlePageChange(ev) {

        const index = ev;
        if (entretien === 'entretien') {
            setPagine({ index: index, size: pagine.size })
            getEntretien(null, ev);
        } else if (entretien === 'intervention') {
            setPagineintervantion({ index: index, size: pagine.size });
            getIntervention(null, index);
        } else {
            setPageAchat({ index: index, size: pagine.size });
            //getAchat(null, ev);
        }

    }

    function getIntervention(param?, pageIndex?) {
        const methode = 'demandeIntervention/getByCriteria'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            index: pageIndex ? pageIndex - 1 : pagine.index - 1,
            size: pagineintervantion.size,
            data: {

            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setListeIntervention(res.data.items ? res.data.items : []);
                    seTtotalintervantion(res.data.count || 0);
                    selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    function getInterventionRow(param?) {
        const methode = 'demandeIntervention/getByCriteria'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isSimpleLoading: false,
            forMobile: false,
            data: {
                id: param.id,
            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setListeInterventionRow(res.data.items[0].datasEquipement ? res.data.items[0].datasEquipement : []);
                    setFormData({ nombreEquipement: res.data.items[0].nombreEquipement });
                    setSelectent({ value1: res.data.items[0].nombreEquipement })
                    setActivbouton(res.data.items[0].hasDevis ? res.data.items[0].hasDevis : false)
                    seTtotalintervantion(res.data.count || 0);
                    //selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err) => {
                //toast.error('Erreur '+ err);
            });
    }

    function getEntretienRow(param?) {
        const methode = 'offre/getByCriteria';
        //setPerio(param.id);
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            size: pagine.size,
            data: {
                id: param.id,
                typeOffreId: '',
                libelle: param ? param.libelle : '',
                description: ''
            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    console.log("offre avec id", res.data.items[0].datasPassagesEntretien);
                    setListeEntretienRow(res.data.items[0].datasEquipement ? res.data.items[0].datasEquipement : []);
                    setListePassageEntretien(res.data.items[0].datasPassagesEntretien ? res.data.items[0].datasPassagesEntretien : []);
                    setFormData({ nombreEquipement: res.data.items[0].nombreEquipement });
                    setSelectent({ value1: res.data.items[0].nombreEquipement, value2: (res.data.items[0].montantTypeOffre * res.data.items[0].nombreEquipement) })
                    setActivbouton(res.data.items[0].hasDevis ? res.data.items[0].hasDevis : false)
                    //setFormData({typeDevisCode: entretien ? 'ENTRETIEN' : 'INTERVENTION'})
                    setFormData({ montant: (res.data.items[0].montantTypeOffre * res.data.items[0].nombreEquipement) });
                    getPeriode(param.id)
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err) => {
                //toast.error('aucun equipement associer ');
            });
    }

    function onDatesChange({ startDate, endDate }) {
        setRecherche({
            startDate: startDate,
            endDate: endDate,
        });
    }

    function onFocusChange(focusedInput) {
        setRecherche({ focusedInput });
    }

    function getEntretien(param?, pageIndex?) {
        const methode = 'offre/getByCriteria'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            index: pageIndex ? pageIndex - 1 : pagine.index - 1,
            size: pagine.size,
            data: {
                typeOffreId: '',
                libelle: param ? param.libelle : '',
                description: ''
            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setListeEntretien(res.data.items ? res.data.items : []);
                    seTtotal(res.data.count || 0);
                    selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    /*  function getAchat(param?, pageIndex?) {
         const methode = 'demandeAchat/getByCriteria'
         const data = {
             user: userService.getUserData() && userService.getUserData().id,
             index: pageIndex ? pageIndex-1 : pageAchat.index-1 ,
             size: pagine.size,
             data: {
                 typeOffreId: '',
                 libelle: param ? param.libelle : '',
                 description: ''
             }
         };
         restClientApi.executePostApi(methode, data)
             .then((res)=>{
                 if(res && res.data && !res.data.hasError){
                     setListeAchat(res.data.items ? res.data.items : []);
                     setTotalAchat(res.data.count || 0);
                     selectedRow(res.data.items && res.data.items[0], 0)
                 } else {
                     toast.error(res.data.status.message);
                 }
             })
             .catch((err)=>{
                 toast.error('Erreur '+ err);
             });
         } */

    function showTableMontant() {
        if (entretien === 'entretien') {
            return <div className="form-group">
                <label className="text-left w-100" htmlFor="montant">Montant</label>
                <Field disabled type="number" value={formData.montant} name="montant" className="form-control rounded-0" id="montant" placeholder="Montant" />
            </div>
        } else if (entretien === 'intervention') {
            return <div className="form-group">
                <label className="text-left w-100" htmlFor="montant">Montant</label>
                <Field type="number" name="montant" className="form-control rounded-0" id="montant" placeholder="Montant" />
            </div>
        } else {
            return <div className="form-group">
                <label className="text-left w-100" htmlFor="montant">Montant</label>
                <Field type="number" name="montant" className="form-control rounded-0" id="montant" placeholder="Montant" />
            </div>
        }
    }

    function showTable() {
        if (entretien === 'entretien') {
            return <><div className="tbl">
                <div className="row-t-head mt-3">
                    <div className="cell">ID </div>
                    <div className="cell">Nom </div>
                    <div className="cell">Prenom </div>
                    <div className="cell">Offre </div>
                    {/*  <div className="cell">Statut paiement </div> */}
                    <div className="cell"> Date/Heure </div>
                    {/* <div className="cell"> État </div> */}
                    <div className="cell"> Action </div>
                </div>
                {listeEntretien && listeEntretien.map((item, index) =>
                    <div className="row-t mt-3" key={index}
                        onClick={() => selectedRow(item, index)}
                        style={{ background: selectedLigne === index ? '#e3fef3' : '#fff' }}>
                        <div className="cell">{index + 1}</div>
                        <div className="cell">{item.clientNom}</div>
                        <div className="cell">{item.clientPrenom}</div>
                        <div className="cell">{item.typeOffreLibelle}</div>
                        {/* <div className="cell">{item.statutPaiement}</div> */}
                        <div className="cell">{item.createdAt} {item.hours}</div>
                       {/*  <div className="cell">
                            <button onClick={() => getEntretienRow(item, index)} className="btn btn-etat rounded-pill">{item.statutDevisCode}</button>
                        </div> */}
                        <div className="cell">
                            <Link to={`/demandes-devis/${item.id}`}>
                                <i className="fa fa-eye" aria-hidden="true"></i>
                            </Link>
                            {/*  <button onClick={() => handleSho(item)} className="btn btn-etat  rounded-0 px-1 py-0"><i className="fa fa-eye icon" aria-hidden="true"></i></button> */}
                        </div>
                    </div>)
                }
            </div>
                <Modal size="lg" show={shoclien} onHide={() => handleClos()} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Historique entretient</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-8 px-0 bg-light">
                            <div className="tbl">
                                <div className="row-t-head mt-3">
                                    <div className="cell">ID </div>
                                    <div className="cell">Nom </div>
                                    <div className="cell">Prenom </div>
                                    <div className="cell">Offre </div>
                                    <div className="cell">Statut payement </div>
                                    <div className="cell"> Date/Heure </div>
                                    <div className="cell"> Périodes </div>
                                </div>
                                {listePassageEntretien && listePassageEntretien.length > 0 ? listePassageEntretien.map((item, index) =>
                                    <div className="row-t mt-3" key={index}
                                               /*  onClick={() => this.selectedRow(item, index)} */
                                                /* style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }} */ >
                                        <div className="cell">{index + 1}</div>
                                        <div className="cell">{item.clientNom}</div>
                                        <div className="cell">{item.clientPrenom}</div>
                                        <div className="cell">{item.typeOffreLibelle}</div>
                                        <div className="cell">{item.statutPaiement}</div>
                                        <div className="cell">{item.createdAt} {item.hours}</div>
                                        <div className="cell">{item.periode}</div>
                                    </div>
                                ) : 'Aucune donnée'}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClos(false)}>
                            Fermer
                                    </Button>
                    </Modal.Footer>
                </Modal>
                <div className="w-100 mt-2">
                    {listeEntretien ?
                        <Pagination innerClass="pagination pull-right"
                            activePage={pagine.index}
                            itemsCountPerPage={pagine.size}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={(event) => handlePageChange(event)}
                            itemClass="page-item"
                            linkClass="page-link"
                        /> : ''}
                </div>
            </>
        } else if (entretien === 'intervention') {
            return <> <div className="tbl">
                <div className="row-t-head mt-3">
                    <div className="cell">ID </div>
                    <div className="cell">Client</div>
                    <div className="cell">Statut Payement</div>
                    {/* <div className="cell">Statut</div> */}
                    <div className="cell"> Date/Heure</div>
                    <div className="cell"> État</div>
                </div>
                {listeIntervention && listeIntervention.map((item, index) =>
                    <div className="row-t mt-3" key={index}
                        onClick={() => selectedRow(item, index)}
                        style={{ background: selectedLigne === index ? '#e3fef3' : '#fff' }}>
                        <div className="cell">{index + 1}</div>
                        <div className="cell">{item.clientNom} {item.clientPrenom}</div>
                        <div className="cell">{item.statutPaiement}</div>
                        {/* <div className="cell">{item.libelleStatut}</div> */}
                        <div className="cell">{item.createdAt} {item.hours}</div>
                        <div className="cell">
                            <button onClick={() => getInterventionRow(item, index)} className="btn btn-etat rounded-pill">{item.statutDevisCode}</button>
                        </div>
                    </div>)}
            </div>
                <div className="w-100 mt-2">
                    {listeIntervention ?
                        <Pagination innerClass="pagination pull-right"
                            activePage={pagineintervantion.index}
                            itemsCountPerPage={pagineintervantion.size}
                            totalItemsCount={totalintervantion}
                            pageRangeDisplayed={5}
                            onChange={(event) => handlePageChange(event)}
                            itemClass="page-item"
                            linkClass="page-link"
                        /> : ''}
                </div>
            </>
        } else {
            return <> <div className="tbl">
                <div className="row-t-head mt-3">
                    <div className="cell">ID </div>
                    <div className="cell">Client</div>
                    <div className="cell">Statut payement</div>
                    {/* <div className="cell"> Date/Heure</div> */}
                    <div className="cell"> État</div>
                </div>
                {listeAchat && listeAchat.map((item, index) =>
                    <div className="row-t mt-3" key={index}
                        onClick={() => selectedRow(item, index)}
                        style={{ background: selectedLigne === index ? '#e3fef3' : '#fff' }}>
                        <div className="cell">{index + 1}</div>
                        <div className="cell">{item.clientNom} {item.clientPrenom}</div>
                        <div className="cell">{item.statutPaiement}</div>
                        {/* <div className="cell">{item.libelleStatus}</div> */}
                        <div className="cell">
                            <button className="btn btn-etat rounded-pill">{item.libelleStatut}</button>
                        </div>
                    </div>)}
            </div>
                <div className="w-100 mt-2">
                    {listeIntervention ?
                        <Pagination innerClass="pagination pull-right"
                            activePage={pageAchat.index}
                            itemsCountPerPage={pageAchat.size}
                            totalItemsCount={totalAchat}
                            pageRangeDisplayed={5}
                            onChange={(event) => handlePageChange(event)}
                            itemClass="page-item"
                            linkClass="page-link"
                        /> : ''}
                </div>
            </>
        }
    }
    function affichP() {
        if (entretien === 'entretien') {
            return <>
                <div className="form-group">
                    <label className="text-left w-100" htmlFor="periodeContratId">Choisir la periode du contrat</label>
                    {/* <Field id="firstName" name="firstName" placeholder="Jane" className="form-control"/> */}
                    <Field name="periodiciteContratId" as="select" className="form-control rounded-0 border border-dark" id="periodiciteContratId">
                        <option>Veuillez choisir la periode</option>
                        {perioContrat?.map((item, index) => (
                            <option key={index} value={item.id}>{item.mois}/{item.annee}</option>
                        ))}
                    </Field>

                </div></>
        }

    }

    return (
        <div className="content intervention mb-5">
            <div className="container px-0">
                <h2 className="mt-3 text-left">Demande et dévis</h2>
            </div>
            <div className="container mt-4 pt-5 pb-2">
                <div className="row justify-content-between">
                    <h4 className="mt-3">Liste des demandes</h4>
                    {/* 
                    <DateRangePicker
                        startDate={recherche.startDate} // momentPropTypes.momentObj or null,
                        startDateId="001" // PropTypes.string.isRequired,
                        endDate={recherche.endDate} // momentPropTypes.momentObj or null,
                        endDateId="002" // PropTypes.string.isRequired,
                        onDatesChange={onDatesChange} // PropTypes.func.isRequired,
                        focusedInput={recherche.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={onFocusChange} // PropTypes.func.isRequired,
                    /> */}

                </div>
            </div>
            <div className="container mt-4 pt-5 pb-2">
                <div className="row">
                    {/*  <button onClick={TouPage} type="button" className="btn btn-sm btn-outline-secondary rounded-pill mr-4">Tout</button> */}
                    <button onClick={() => setEntretien('entretien')} type="button" style={{ backgroundColor: entretien === 'entretien' ? '#e3fef3' : 'transparent' }}
                        className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Entretien</button>
                    <button onClick={() => setEntretien('intervention')} type="button" style={{ backgroundColor: entretien === 'intervention' ? '#e3fef3' : 'transparent' }}
                        className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Intervention</button>
                    {/* <button  onClick={() => setEntretien('achat')} type="button" style={{backgroundColor: entretien === 'achat' ? '#e3fef3' : 'transparent'}}
                     className="btn btn-sm btn-outline-secondary text-dark rounded-pill">Achat</button> */}
                </div>
            </div>

            <div className="container bg-light">
                <div className="row bg-light">
                    {entretien === 'entretien' ?
                        <div className="col-md-12 px-0 bg-light">
                            {showTable()}
                        </div> :
                        <div className="col-md-8 px-0 bg-light">
                            {showTable()}
                        </div>
                    }

                    {entretien === 'intervention' ?
                        <div className="col-md-4">
                            <div className="card card-shadow p-3 mt-5">
                                <div className="top-card text-left text-green">
                                    <h5 className="font-weight-bold">Dévis</h5>
                                </div>
                                <hr></hr>
                                <div className="form">
                                    <Formik
                                        initialValues={formData}

                                        onSubmit={async (values, { resetForm }) => {
                                            await new Promise((r) => setTimeout(r, 500));
                                            createDevisIntervention(values, resetForm)
                                            //alert(JSON.stringify(values, null, 2));
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            resetForm
                                        }) => (
                                            <Form>

                                                <div className="form-group">
                                                    <label className="text-left w-100" htmlFor="nombreEquipement">Nombre d'équipement</label>
                                                    <Field disabled type="number" value={entretien === 'entretien' ? formData.nombreEquipement : entretien === 'intervention' ? formData.nombreEquipement : ''} name="nombreEquipement" className="form-control rounded-0" id="nbreEquipement" placeholder="nombre d'equipement" />
                                                </div>
                                                <Card >
                                                    <SimpleBar style={{ maxHeight: 200 }}>
                                                        <div className="form-group">
                                                            <div className="row-t-head mt-3">
                                                                <div style={{ fontSize: 20 }} className="cell">Listes d'equippements </div>
                                                            </div>
                                                            <div className="col-md-8 px-0 bg-light">
                                                                <div className="tbl">
                                                                    {entretien === 'entretien' ? listeEntretienRow && listeEntretienRow.map((item, index) =>
                                                                        <div className="row-t mt-3 ml-3" key={index}
                                                                            style={{ background: selectedLigne === index ? '#e3fef3' : '#fff' }}>
                                                                            <div className="cell">{item.equipementMarqueLibelle}</div>
                                                                        </div>) : listeInterventionRow && listeInterventionRow.map((item, index) =>
                                                                            <div className="row-t mt-3 ml-3" key={index}
                                                                                style={{ background: selectedLigne === index ? '#e3fef3' : '#fff' }}>
                                                                                <div className="cell">{item.libelleEquipement}</div>
                                                                            </div>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SimpleBar>
                                                </Card>
                                                {affichP()}

                                                {showTableMontant()}
                                                <div className="d-flex justify-content-between">
                                                    <button type="submit" className="btn btn-outline-success btn-sm px-2 rounded-0"
                                                        disabled={activbouton}
                                                    >Envoi au client</button>
                                                    <Loader
                                                        type="Puff"
                                                        color="#00BFFF"
                                                        height={40}
                                                        width={40}
                                                        visible={affi}
                                                    />
                                                    <button type="button"
                                                        onClick={() => resetForm(formData)}
                                                        className="btn btn-danger btn-sm px-3 rounded-0">Annuler</button>
                                                </div>
                                            </Form>)}
                                    </Formik>
                                </div>
                            </div>
                        </div> : ""}
                </div>
            </div>


        </div>
    )
}
