import React
    // , {useState, useEffect, useRef} 
    from 'react';
import {
    Formik
    , Form, Field, ErrorMessage, FastField
} from 'formik';
import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
// const restClientApi = new ResClientApi();

export class Techniciens extends React.Component {
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props) {
        super(props);

        this.state = {
            listeTechnicien: [],
            listeUserType: [],
            listeProfilTechnicien: [],
            listeTechnicienClient: [],
            modalShow: false,
            assignationModalShow: false,
            selectedLigne: -1,
            detailObjet: null,
            affi: false,
            shoclien: false,
            page: {
                index: 1,
                size: 10,
            },
            formState: {
                id: '',
                lastName: '',
                email: '',
                login: '',
                firstName: '',
                telephone: '',
                userTypeCode: 'TECH',
                profilTechnicienCode: '',
                // userModeCode: 'OFFRE_ENTRETIEN'
            },
            total: 0,
            search: ''
        }
    }

    initForm() {
        this.setState({
            formState: {
                id: '',
                lastName: '',
                email: '',
                login: '',
                firstName: '',
                telephone: '',
                userTypeCode: 'TECH',
                profilTechnicienCode: '',
                // userModeCode: 'OFFRE_ENTRETIEN'
            }
        });
    }

    selectedRow(val, index) {
        //   indexSelected = index
        this.setState({ selectedLigne: index });
        this.setState({ detailObjet: val });
        this.getTechnicienClient(val);
        // this.setState({marker: [val.latitudeClient ? val.latitudeClient : '', val.longitudeClient ? val.longitudeClient : '']});
    }

    goForModif(param) {
        const form = {
            id: param.id,
            lastName: param.lastName,
            email: param.email,
            login: param.login,
            firstName: param.firstName,
            telephone: param.telephone,
        };
        this.setState({ formState: form });
    }

    getTechnicien(param?, pageIndex?) {
        const methode = 'user/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {

                login: param && param.login ? param.login : '',
                lastName: param && param.lastName ? param.lastName : '',
                firstName: param && param.firstName ? param.firstName : '',
                userTypeCode: 'TECH',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeTechnicien: res.data.items ? res.data.items : [] });
                    this.setState({ total: res.data.count ? res.data.count : 0 });
                    // this.selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    getUserType(param?, pageIndex?) {
        const methode = 'userType/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeUserType: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    getProfilTechnicien(param?, pageIndex?) {
        const methode = 'profilTechnicien/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: ''
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeProfilTechnicien: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    getTechnicienClient(param?, pageIndex?) {
        const methode = 'interventionTechnicien/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: "",
                userId: param.id
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeTechnicienClient: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    createTechnicien(param, resetForm) {
        this.setState({ affi: true })
        const methode = param && param.id ? 'user/update' : 'user/create'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [param]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.setState({ affi: false });
                    this.getTechnicien();
                    this.initForm();
                    resetForm(this.state.formState);
                    toast.success("Operation effectuer avec succes");
                } else {
                    toast.error(res.data.status.message);
                    this.setState({ affi: false });
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    handlePageChange(ev) {
        const index = ev;
        this.getTechnicien(ev);
        this.setState({
            page: {
                index: index,
                size: this.state.page.size
            }
        });
    }

    searchEvent(param) {

        this.setState({ search: param });
        this.recherche(param);
    }

    async recherche(val) {
        await new Promise((r) => setTimeout(r, 500));
        this.getTechnicien({ login: val })
    }

    componentDidMount() {
        this.getTechnicien();
        this.getUserType();
        this.getProfilTechnicien();
    }

    delete(val) {
        Swal.fire({
            title: 'Attention',
            text: 'Êtes-vous sûre de vouloir la supprimer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                this.deleteTechnicien(val);
            }
        })
    }
    handleSho(param) {
        this.setState({ shoclien: true })
    }
    handleClos() {
        this.setState({ shoclien: false })

    }
    deleteTechnicien(param) {
        const methode = 'user/delete'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [{
                id: param.id
            }]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.getTechnicien();
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }


    render() {
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        return (
            <div className="content intervention mb-5">
                <div className="container px-0">
                    <h2 className="mt-3 text-left">Gestion des techniciens</h2>
                </div>
                <div className="container mt-4 pt-5 pb-2">
                    <div className="row justify-content-between">
                        <h4 className="mt-3">Nouveaux techniciens</h4>
                        <input type="text" placeholder="Rechercher par login"
                            value={this.state.search} onChange={(val) => this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0" />
                    </div>
                </div>
                <div className="container bg-light">
                    <div className="row bg-light">
                        <div className="col-md-8 px-0 bg-light">
                            <div className="tbl">
                                <div className="row-t-head mt-3">
                                    <div className="cell">ID </div>
                                    <div className="cell">Nom </div>
                                    <div className="cell">Prenoms</div>
                                    <div className="cell">Login</div>
                                    <div className="cell">Email</div>
                                    <div className="cell">Telephone</div>
                                    <div className="cell">profil</div>
                                    <div className="cell"> Option</div>
                                </div>
                                {this.state.listeTechnicien && this.state.listeTechnicien.length > 0 ? this.state.listeTechnicien.map((item, index) =>
                                    <div className="row-t mt-3" key={index}
                                        onClick={() => this.selectedRow(item, index)}
                                        style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                                        <div className="cell">{index + 1}</div>
                                        <div className="cell">{item.lastName}</div>
                                        <div className="cell">{item.firstName}</div>
                                        <div className="cell">{item.login}</div>
                                        <div className="cell">{item.email}</div>
                                        <div className="cell">{item.telephone}</div>
                                        <div className="cell">{item.profilTechnicienCode}</div>
                                        <div className="cell">
                                            <button onClick={() => this.goForModif(item)} className="btn btn-etat mr-1"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                            <button onClick={() => this.delete(item)} className="btn btn-outline-danger btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                            <button onClick={() => this.handleSho(item)} className="btn btn-etat  rounded-0 px-1 py-0"><i className="fa fa-eye icon" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                ) : 'Aucune donnée'}
                            </div>
                            <Modal show={this.state.shoclien} onHide={() => this.handleClos()} animation={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Historique</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="tbl">
                                        <div className="row-t-head mt-3">
                                            <div className="cell">ID </div>
                                            <div className="cell">Client </div>
                                            <div className="cell">Date intervention</div>
                                        </div>
                                        {this.state.listeTechnicienClient && this.state.listeTechnicienClient.length > 0 ? this.state.listeTechnicienClient.map((item, index) =>
                                            <div className="row-t mt-3" key={index}
                                               /*  onClick={() => this.selectedRow(item, index)} */
                                                /* style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }} */ >
                                                <div className="cell">{index + 1}</div>
                                                <div className="cell">{item.nomClient} {item.prenomClient}</div>
                                                <div className="cell">{item.dateIntervention}</div>
                                            </div>
                                        ) : 'Aucune donnée'}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.handleClos(false)}>
                                        Fermer
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <div className="w-100 mt-2">
                                {this.state.listeTechnicien && this.state.listeTechnicien.length > 0 ?
                                    <Pagination innerClass="pagination pull-right"
                                        activePage={this.state.page.index}
                                        itemsCountPerPage={this.state.page.size}
                                        totalItemsCount={this.state.total}
                                        pageRangeDisplayed={5}
                                        onChange={(event) => this.handlePageChange(event)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    /> : ''}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-shadow p-3 mt-5">
                                <div className="top-card text-left text-green">
                                    <h5 className="font-weight-bold">Technicien</h5>
                                </div>
                                <hr />
                                <div className="form">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={this.state.formState}
                                        validationSchema={Yup.object().shape({
                                            lastName: Yup.string()
                                                .required('Champs requis'),
                                            firstName: Yup.string()
                                                .required('Champs requis'),
                                            email: Yup.string()
                                                .email('Veuillez saisir un mail correcte')
                                                .required('Champs requis'),
                                            login: Yup.string()
                                                .required('Champs requis'),
                                            telephone: Yup.string()
                                                .matches(phoneRegExp, 'le numero doit comporter au moins 8 chiffres')
                                                .required('Champs requis'),
                                        })}
                                        onReset={(value, { setSubmitting, resetForm }) => {
                                            //resetForm();
                                            //this.initForm();
                                        }}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            this.createTechnicien(values, resetForm);
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            resetForm
                                        }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            placeholder="Nom"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.firstName}
                                                        />
                                                        {errors.firstName && touched.firstName &&
                                                            <div className="text-danger">{errors.firstName}</div>}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Prenoms"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.lastName}
                                                        />
                                                        {errors.lastName && touched.lastName &&
                                                            <div className="text-danger">{errors.lastName}</div>}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="text"
                                                            name="login"
                                                            placeholder="Login"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.login}
                                                        />
                                                        {errors.login && touched.login &&
                                                            <div className="text-danger">{errors.login}</div>}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <label className="text-left w-100" htmlFor="profilTechnicienCode">Choisir le profil du technicien</label>
                                                        {/* <Field id="firstName" name="firstName" placeholder="Jane" className="form-control"/> */}
                                                        <Field name="profilTechnicienCode" as="select" className="form-control rounded-0 border border-dark" id="profilTechnicienCode">
                                                            <option>Veuillez choisir le profil du technicien</option>
                                                            {this.state.listeProfilTechnicien?.map((item, index) => (
                                                                <option key={index} value={item.code}>{item.code}</option>
                                                            ))}
                                                            {/* <option value="blue">Blue</option>*/}
                                                        </Field>
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder="Email: xxxxx@xxx.com"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                        />
                                                        {errors.email && touched.email &&
                                                            <div className="text-danger">{errors.email}</div>}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="text"
                                                            name="telephone"
                                                            placeholder="Tel: +225 XX XX XX XX"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.telephone}
                                                        />
                                                        {errors.telephone && touched.telephone &&
                                                            <div className="text-danger">{errors.telephone}</div>}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                                        >Annuler</button>
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={50}
                                                            width={50}
                                                            visible={this.state.affi}
                                                        />
                                                        <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                                        >Valider</button>
                                                    </div>
                                                </form>
                                            )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}