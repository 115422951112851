import React
    // , {useState, useEffect, useRef} 
    from 'react';
import {
    Formik
     , Form, Field, ErrorMessage, FastField
} from 'formik';
import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
// const restClientApi = new ResClientApi();

export class Devis extends React.Component {
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props) {
        super(props);

        this.state = {
            listeDevis: [],
            listeUserType: [],
            listeProfilTechnicien: [],
            modalShow: false,
            assignationModalShow: false,
            selectedLigne: -1,
            detailObjet: null,
            affi: false,
            page: {
                index: 1,
                size: 10,
            },
            total: 0,
            search: ''
        }
    }

   
    selectedRow(val, index) {
          this.indexSelected = index
         this.setState({selectedLigne:index});
         this.setState({detailObjet:val});
        // this.setState({marker: [val.latitudeClient ? val.latitudeClient : '', val.longitudeClient ? val.longitudeClient : '']});
    }

    // goForModif(param) {
    //     const form = {
    //         id: param.id,
    //         lastName: param.lastName,
    //         email: param.email,
    //         login: param.login,
    //         firstName: param.firstName,
    //         telephone: param.telephone,
    //     };
    //     this.setState({ formState: form });
    // }

    getDevis(param?, pageIndex?) {
        console.log('rec',param?.clientNom)
        const methode = 'devis/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: "",
                forReplace: true,
                clientNom: param && param?.clientNom ? param.clientNom : '',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeDevis: res.data.items ? res.data.items : [] });
                    this.setState({ total: res.data.count ? res.data.count : 0 });
                     this.selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    handlePageChange(ev) {
        const index = ev;
        this.getDevis(ev);
        this.setState({
            page: {
                index: index,
                size: this.state.page.size
            }
        });
    }

    searchEvent(param) {
        this.setState({ search: param });
        this.recherche(param);
    }

    async recherche(val) {
        await new Promise((r) => setTimeout(r, 500));
        this.getDevis({ clientNom: val })
    }

    componentDidMount() {
        this.getDevis();
        // this.getUserType();
        // this.getProfilTechnicien();
    }

    // delete(val) {
    //     Swal.fire({
    //         title: 'Attention',
    //         text: 'Êtes-vous sûre de vouloir la supprimer?',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Oui',
    //         cancelButtonText: 'Non'
    //     }).then((result) => {
    //         if (result.value) {
    //             this.deleteTechnicien(val);
    //         }
    //     })
    // }

    // deleteTechnicien(param) {
    //     const methode = 'user/delete'
    //     const data = {
    //         user: this.userService.getUserData() && this.userService.getUserData().id,
    //         isCostomer: false,
    //         datas: [{
    //             id: param.id
    //         }]
    //     };
    //     this.restClientApi.executePostApi(methode, data)
    //         .then((res) => {
    //             if (res && res.data && !res.data.hasError) {
    //                 toast.success(res.data.status.message);
    //                 this.getDevis();
    //             } else {
    //                 toast.error(res.data.status.message);
    //             }
    //         })
    //         .catch((err) => {
    //             toast.error('Erreur ' + err);
    //         });
    // }


    render() {
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        return (
            <div className="content intervention mb-5">
                <div className="container px-0">
                    <h2 className="mt-3 text-left">Gestion des Dévis</h2>
                </div>
                <div className="container mt-4 pt-5 pb-2">
                    <div className="row justify-content-between">
                        <h4 className="mt-3">Nouveaux Dévis</h4>
                        <input type="text" placeholder="Rechercher par Nom"
                            value={this.state.search} onChange={(val) => this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0" />
                    </div>
                </div>
                <div className="container bg-light">
                    <div className="row bg-light">
                        <div className="col-md-12 px-0 bg-light">
                            <div className="tbl">
                                <div className="row-t-head mt-3">
                                    <div className="cell">ID </div>
                                    <div className="cell">Nom </div>
                                    <div className="cell">Prenoms</div>
                                    <div className="cell">Montant Total</div>
                                    <div className="cell">Date</div>
                                     {/* <div className="cell"> Option</div> */}
                                </div>
                                {this.state.listeDevis && this.state.listeDevis.length > 0 ? this.state.listeDevis.map((item, index) =>
                                    <div className="row-t mt-3" key={index}
                                        onClick={() => this.selectedRow(item, index)}
                                        style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                                        <div className="cell">{index + 1}</div>
                                        <div className="cell">{item.clientNom}</div>
                                        <div className="cell">{item.clientPrenom}</div>
                                        <div className="cell">{item.montant}</div>
                                        <div className="cell">{item.createdAt}</div>
                                       {/*  <div className="cell">
                                            <button onClick={() => this.goForModif(item)} className="btn btn-etat mr-1"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                            <button onClick={() => this.delete(item)} className="btn btn-outline-danger btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                        </div> */}
                                    </div>
                                ) : 'Aucune donnée'}
                            </div>
                            <div className="w-100 mt-2">
                                {this.state.listeDevis && this.state.listeDevis.length > 0 ?
                                    <Pagination innerClass="pagination pull-right"
                                        activePage={this.state.page.index}
                                        itemsCountPerPage={this.state.page.size}
                                        totalItemsCount={this.state.total}
                                        pageRangeDisplayed={5}
                                        onChange={(event) => this.handlePageChange(event)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    /> : ''}
                            </div>
                        </div>
                      { /*  <div className="col-md-4"> 
                            <div className="card card-shadow p-3 mt-5">
                                <div className="top-card text-left text-green">
                                    <h5 className="font-weight-bold">Dévis</h5>
                                </div>
                                <hr />
                                <div className="form">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={this.state.formState}
                                        validationSchema={Yup.object().shape({
                                            libelle: Yup.string()
                                                .required('Champs requis'),
                                            quantite: Yup.number()
                                                .required('Champs requis'),
                                            montant: Yup.number()
                                                .required('Champs requis'),
                                        })}
                                        onReset={(value, { setSubmitting, resetForm }) => {
                                            //resetForm();
                                            //this.initForm();
                                        }}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            this.createDevis(values, resetForm);
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            resetForm
                                        }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="text"
                                                            name="libelle"
                                                            placeholder="libelle"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.libelle}
                                                        />
                                                        {errors.libelle && touched.libelle &&
                                                            <div className="text-danger">{errors.libelle}</div>}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="number"
                                                            name="quantite"
                                                            placeholder="quantite"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.quantite}
                                                        />
                                                        {errors.quantite && touched.quantite &&
                                                            <div className="text-danger">{errors.quantite}</div>}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="text"
                                                            name="montant"
                                                            placeholder="montant"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.montant}
                                                        />
                                                        {errors.montant && touched.montant &&
                                                            <div className="text-danger">{errors.montant}</div>}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                                        >Annuler</button>
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={50}
                                                            width={50}
                                                            visible={this.state.affi}
                                                        />
                                                        <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                                        >Valider</button>
                                                    </div>
                                                </form>
                                            )}
                                    </Formik>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        )
    }
}
