import React , { useState, useEffect }  from 'react'
import { Link, withRouter } from "react-router-dom"
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CProgress
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import '../styles/intervention.scss';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';

import {toast} from 'react-toastify';

const restClientApi = new ResClientApi();
const userService = new UserService();
const TheHeaderDropdownNotif = (props) => {
  const itemsCount = 5
  const [listeByNotification, setListeByNotification] = useState([]);
  const [nobreByNotification, setNobreByNotificationByNotification] = useState('');


  useEffect(() => {
    getByNotification();

    setInterval(() => {
      getByNotification();
    }, 30000);
       
  }, []);

  function getByNotification() {
    const methode = 'notification/getByNotification'
    const data = {
        user: userService.getUserData() && userService.getUserData().id,
        isSimpleLoading: false,
        data: {
          forDetails: false,
        }
    };
    restClientApi.executePostApi(methode, data)
        .then((res) => {
            if (res && res.data && !res.data.hasError) {
              setNobreByNotificationByNotification(res.data.items[0].nbrNotification);
                 console.log('nobreByNotification', nobreByNotification);
            } else {
                // console.log('liste items', listeTechnicien);
                toast.error(res.data.status.message);
            }
        })
        .catch((err)=>{
            toast.error('Erreur '+ err);
        });
}
function clickNotif() {
  const methode = 'notification/getByNotification'
  const data = {
      user: userService.getUserData() && userService.getUserData().id,
      isSimpleLoading: false,
      data: {
        forDetails: true,
      }
  };
  restClientApi.executePostApi(methode, data)
      .then((res) => {
          if (res && res.data && !res.data.hasError) {
            setListeByNotification(res.data.items[0]?.datasDetail);
            setNobreByNotificationByNotification(res.data.items[0]?.nbrNotification);
               console.log('listeByNotification', listeByNotification);
               console.log('nobreByNotification', nobreByNotification);

          } else {
              // console.log('liste items', listeTechnicien);
              toast.error(res.data.status.message);
          }
      })
      .catch((err)=>{
          toast.error('Erreur '+ err);
      });
}


  return (
    <CDropdown className="c-header-nav-item mx-2">    
      <CDropdownToggle className="c-header-nav-link" caret={false} onClick={()=>clickNotif()}>
        {/* <CIcon name="cil-bell"/> */}<i className="fa fa-bell" aria-hidden="true"></i>
        <CBadge shape="pill" className="badge" color="danger">{nobreByNotification}</CBadge>
      </CDropdownToggle>
      <CDropdownMenu  placement="bottom-end" className="pt-0">
        <CDropdownItem header tag="div" className="text-center" color="light">
          <strong>Vous avez {nobreByNotification} notifications</strong>
        </CDropdownItem>
        {listeByNotification && listeByNotification.map((item, index)=><div key={index}>
          {item.sens === 'ENTRETIEN'
          ? 
            <CDropdownItem >
              <Link to="/entretiens">
                  <CIcon name="cil-user-follow" className="mr-2 text-success" />
                  {item.sens} <strong >{item.nombre}  </strong> 
                  <CBadge shape="pill" className="badge" color="danger"></CBadge>{/* {item.nombre} */}
              </Link>
            </CDropdownItem>
           
          : item.sens === 'INTERVENTION'
          ? 
          <CDropdownItem >
            <Link to="/interventions">
              <CIcon name="cil-user-follow" className="mr-2 text-success" />
              {item.sens} <strong>{item.nombre}  </strong> 
              <CBadge shape="pill" className="badge" color="danger"></CBadge>{/* {item.nombre} */}
            </Link>
          </CDropdownItem>
          : item.sens === 'ACHAT'
          ? 
          <CDropdownItem>
            <Link to="/achat">
              <CIcon name="cil-user-follow" className="mr-2 text-success" />
              {item.sens} <strong> {item.nombre}  </strong>
              <CBadge shape="pill" className="badge" color="danger"></CBadge>{/* {item.nombre} */}
            </Link>
          </CDropdownItem> : 
           item.sens === 'INFO'
           ? 
           <CDropdownItem>
             <Link to="/achat">
               <CIcon name="cil-user-follow" className="mr-2 text-success" />
               {item.sens} <strong> {item.nombre}  </strong> 
               <CBadge shape="pill" className="badge" color="danger"></CBadge>{/* {item.nombre} */}
             </Link>
           </CDropdownItem> : ""  }</div> 
         )}
        
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdownNotif