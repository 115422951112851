import React
    // , {useState, useEffect, useRef} 
    from 'react';
import {
    Formik
    , Form, Field, ErrorMessage, FastField
} from 'formik';
import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import moment from 'moment';

export class CodePromo extends React.Component {
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props) {
        super(props);

        this.state = {
            listeCodePromo: [],
            modalShow: false,
            assignationModalShow: false,
            selectedLigne: -1,
            detailObjet: null,
            affi: false,
            page: {
                index: 1,
                size: 10,
            },
            formState: {
                id: '',
                nombre: '',
            },
            total: 0,
            search: ''
        }
    }
    initForm() {
        this.setState({
            formState: {
                id: '',
                nombre: '',
            }
        });
    }

    selectedRow(val, index) {
        //   indexSelected = index
        this.setState({ selectedLigne: index });
        this.setState({ detailObjet: val });
    }

    goForModif(param) {
        const form = {
            id: param.id,
            nombre: param.nombre,
        };
       // this.setState({ dateStart:   moment(param.dateDebut).format('YYYY-DD-MM'), dateEnd:moment(param.dateFin).format('YYYY-DD-MM')});
        this.setState({ formState: form });
    }

    getCodePromo(param?, pageIndex?) {
        const methode = 'codePromoParrainage/getByCriteria'
        const data = {
            index:  this.state.page.index - 1,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: "",
                isActive: ""
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeCodePromo: res.data.items ? res.data.items : [] });
                    this.setState({ total: res.data.count ? res.data.count : 0 });
                    // this.selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    createCodePromoParrainage(param, resetForm) {
        this.setState({ affi: true })
        const methode ='codePromoParrainage/create'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [param]
        };
        console.log('data create', data);
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                console.log('data create', res);
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.setState({ affi: false });
                    this.getCodePromo();
                    this.initForm();
                    resetForm(this.state.formState);
                    toast.success("Operation effectuer avec succes");
                } else {
                    toast.error(res.data.status.message);
                    this.setState({ affi: false });
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    handlePageChange(ev) {
        console.log(ev);
        this.setState({
            page: {
                index: ev,
                size: this.state.page.size
            }
        });
        this.getCodePromo();
       
    }

  

    searchEvent(param) {

        this.setState({ search: param });
        this.recherche(param);
    }

    async recherche(val) {
        await new Promise((r) => setTimeout(r, 500));
        this.getCodePromo({ nombre: val })
    }

    componentDidMount() {
        this.getCodePromo();
    }

    delete(val) {
        Swal.fire({
            title: 'Attention',
            text: 'Êtes-vous sûre de vouloir supprimer le code?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                this.deleteCodeProno(val);
            }
        })
    }

    activeDesactive(val) {
        Swal.fire({
            title: 'Attention',
            text: val.isActive ? 'Êtes-vous sûre de vouloir la désactiver le code ?' : 'Êtes-vous sûre de vouloir la Activer le code ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                this.ActiveCodeProno(val);
            }
        })
    }
    
     handleSho(param) {
        this.setState({ shoclien: true })
    }
    ActiveCodeProno(param) {
        const methode = 'codePromoParrainage/update'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [{
                id: param.id,
                isActive: param.isActive ? false: true,
            }]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.getCodePromo();
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }
 /*    handleClos() {
        this.setState({ shoclien: false })

    } */
    deleteCodeProno(param) {
        const methode = 'codePromoParrainage/delete'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [{
                id: param.id
            }]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.getCodePromo();
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }


    render() {
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        return (
            <div className="content intervention mb-5">
                <div className="container px-0">
                    <h2 className="mt-3 text-left">Gestion des Codes promo parrainage</h2>
                </div>
                <div className="container mt-4 pt-5 pb-2">
                    <div className="row justify-content-between">
                        <h4 className="mt-3">Liste des codes</h4>
                       {/*  <input type="text" placeholder="Rechercher par titre de l'offre"
                            value={this.state.search} onChange={(val) => this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0" /> */}
                    </div>
                </div>
                <div className="container bg-light">
                    <div className="row bg-light">
                        <div className="col-md-8 px-0 bg-light">
                            <div className="tbl">
                                <div className="row-t-head mt-3">
                                    <div className="cell">ID </div>
                                    <div className="cell">Code </div>
                                    <div className="cell">Date création</div>
                                    <div className="cell"> Option</div>
                                </div>
                                {this.state.listeCodePromo && this.state.listeCodePromo.length > 0 ? this.state.listeCodePromo.map((item, index) =>
                                    <div className="row-t mt-3" key={index}
                                        onClick={() => this.selectedRow(item, index)}
                                        style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                                        <div className="cell">{index + 1}</div>
                                        <div className="cell">{item.code}</div>
                                        <div className="cell">{item.createdAt}</div>
                                        <div className="cell">
                                            {/* <button onClick={() => this.goForModif(item)} className="btn btn-etat mr-1"><i className="fa fa-pencil" aria-hidden="true"></i></button> */}
                                            <button onClick={() => this.delete(item)} className="btn btn-outline-danger btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                            <button onClick={() => this.activeDesactive(item)} className="btn btn-etat  "> {item.isActive ? <i className="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-check" aria-hidden="true"></i>}</button> {/*  */}
                                        </div>
                                    </div>
                                ) : 'Aucune donnée'}
                            </div>
                            <div className="w-100 mt-2">
                                {this.state.listeCodePromo && this.state.listeCodePromo.length > 0 ?
                                    <Pagination innerClass="pagination pull-right"
                                        activePage={this.state.page.index}
                                        itemsCountPerPage={this.state.page.size}
                                        totalItemsCount={this.state.total}
                                        pageRangeDisplayed={5}
                                        onChange={(event) => this.handlePageChange(event)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    /> : ''}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-shadow p-3 mt-5">
                                <div className="top-card text-left text-green">
                                    <h5 className="font-weight-bold">Code promo</h5>
                                </div>
                                <hr />
                                <div className="form">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={this.state.formState}
                                        validationSchema={Yup.object().shape({
                                            nombre: Yup.string()
                                                .required('Champs requis'),
                                        
                                        })}
                                        onReset={(value, { setSubmitting, resetForm }) => {
                                            //resetForm();
                                            //this.initForm();
                                        }}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            this.createCodePromoParrainage(values, resetForm);
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            resetForm
                                        }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group mt-3">
                                                        <label className="text-left w-100" htmlFor="dateDebut">Nombre de code</label>
                                                        <input
                                                            type="number"
                                                            name="nombre"
                                                            placeholder="Nombre de code à generer"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.nombre}
                                                        />
                                                        {errors.nombre && touched.nombre &&
                                                            <div className="text-danger">{errors.nombre}</div>}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                                        >Annuler</button>
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={50}
                                                            width={50}
                                                            visible={this.state.affi}
                                                        />
                                                        <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                                        >Valider</button>
                                                    </div>
                                                </form>
                                            )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}