import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Select from 'react-select'
// import Pagination from "react-js-pagination";
import { Formik, Form, Field
    // , ErrorMessage, FastField, FormikProps
 } from 'formik';
// import * as Yup from 'yup';
import DatePicker, { registerLocale
    // , setDefaultLocale 
} from "react-datepicker";
// import  from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/intervention.scss';
import * as moment from 'moment';
import subDays from "date-fns/subDays";
import {toast} from 'react-toastify';
registerLocale('fr', fr);

const restClientApi = new ResClientApi();
const userService = new UserService();
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const AssignationModal = (props) => {

    // const [techniciens, settechniciens] = useState(props.techniciens);
    const [startDate, setStartDate] = useState(new Date());
    const [listeInterv, setListeInter] = useState();
    useEffect(() => {
        // settechniciens(props.techniciens)
        // getTechnicien();
    }, []);

    function assginationTechnicien(val?) {
        const methode = 'demandeIntervention/affectationTechnicien';
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isInterview: true,
            data: {
                    dateIntervention: val.dateIntervention,
                    interventionId: props.data.id,
                    datasUserId: [{
                        userId: listeInterv
                    }]
                }
            
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    props.onHide(false);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err)=>{
                toast.error('Erreur '+ err);
            });
    }
    function getTechnicien() {
        const methode = 'user/getByCriteria'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                userTypeCode: 'TECH'
            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    // settechniciens(res.data.items);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err)=>{
                toast.error('Erreur '+ err);
            });
    }

    function handleChange(i) { 
        console.log('i',i);
        setListeInter(i.value)
      };

    function handleSubmit(){
        //console.log('handle');
    }

    return (
        <Modal
            {...props}
            backdrop="static"
            size="md"
            animation={true}
            keyboard={false}>
            <Modal.Header closeButton className="border-0 pb-0">
                <Modal.Title className="text-green">Affectation de technicien</Modal.Title>
            </Modal.Header>
            <Modal.Body >
               <Formik
                    initialValues={{
                        userId: '',
                        dateIntervention: startDate,
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        await sleep(500);
                        values.dateIntervention = moment(startDate).format('DD/MM/YYYY');
                        //console.log('value',values)
                        assginationTechnicien(values);
                    }}
                    >
                    {formProps => (
                        
                        <Form>
                            <div className="form-group mt-3">
                                <label htmlFor="userId">Technicien</label>
                               {/*  <Field as="select" name="userId" className="form-control rounded-0"> 
                                    <option>Veuillez choisir un technicien </option>
                                        {props.techniciens && props.techniciens.map((item, index) => <option key={index} value={item.id}>
                                        {item.firstName} {item.lastName} &nbsp;&nbsp;&nbsp;&nbsp; {item.nbreEquipement}
                                    </option>                                    
                                    )}
                                </Field> */}
                               <Select
                                    isClearable
                                    name="userId"
                                    onChange={handleChange}
                                    options={props.techniciens.map((item,index) => {
                                        return {value: item.id , label:  <div>{item.firstName} {item.lastName} <Badge variant="warning">{item.nbreEquipement}</Badge></div> }
                                    })}
                                />
                            
                                                            </div>
                            <div className="form-group mt-3 myPicker">
                                <label htmlFor="nbreEquipement">Date d'intervention</label>
                                <DatePicker selected={startDate} className="form-control rounded-0 w-full" minDate={subDays(new Date(), 5)}
                                   dateFormat="dd/MM/yyyy" name="dateIntervention" onChange={date => setStartDate(date)} />
                            </div>
                            <div className="d-flex border-0 pt-0 justify-content-between mt-3">
                                <Button type="reset" className="btn btn-outline-default btn-sm btn-text rounded-0 px-4"
                                    onClick={() => props.onHide(false)}>Fermer</Button>
                                <button type="submit" className="btn btn-outline-success btn-sm btn-text rounded-0 px-4">
                                    Envoyer
                                </button>
                            </div>
                        </Form>
                    )}
                    </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default AssignationModal;