import React, {useState, UserEffect} from 'react';
import { Formik, Form, Field, ErrorMessage, FastField } from 'formik';
import * as Yup from 'yup';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import {toast } from 'react-toastify';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import Loader from 'react-loader-spinner';

export class Marques extends React.Component{
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props){
        super(props);
        this.state = {
            detailObjet: null,
            affi: false,
            selectedLigne: -1, 
            listeMarques: [],
            listeUser: [],
            formState: {
                id: '',
                libelle:'',
            },
            page: {
                index: 1,
                size: 10,
            },
            total: 0,
            search: '',
            startDate: moment().format('DD/MM/YYYY')
        }
    }

    componentDidMount(){
        this.getMarques();
        this.getUser();
    }
    
    initForm(){
        this.setState({
            formState: {
            id: '',
            libelle:'',
        }
      });
    }

      selectedRow(val, index){
            this.setState({selectedLigne: index});
            this.setState({detailObjet: val});
      }

      handlePageChange(ev){
        const index = ev;
        this.getMarques(this.state.search, ev);
        this.setState({page: {
            index: index,
            size: this.state.page.size
        }});
    }

      getMarques(param?, pageIndex?){
        const methode = 'equipementMarque/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
            size: this.state.page.size,
            data: {
                typeOffreId: '',
                libelle: param ? param.libelle : '',
                description: ''
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res)=>{
                if(res && res.data && !res.data.hasError){
                    this.setState({listeMarques: res.data.items ? res.data.items : []});
                    this.setState({total: res.data.count || 0});
                    this.selectedRow(res.data.items && res.data.items[0], 0);
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err)=>{
                toast.error('Erreur '+ err);
            });
        }

        askBeforeCreate(val, reset?){
            Swal.fire({
                title: 'Attention',
                text: 'Êtes-vous sûre de vouloir la affecter ce technicien à cet entretien ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33', 
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
             }).then((result) => {
                if(result.value){
                 this.createMarque(val, reset);
               }
             })
        }

        delete(val){
            Swal.fire({
                title: 'Attention',
                text: 'Êtes-vous sûre de vouloir la supprimer?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33', 
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
             }).then((result) => {
                if(result.value){
                 this.deleteMarque(val);
               }
             })
        }

        goForModif(param){
            const form = {
                id: param.id,
                libelle: param.libelle,
            };
            this.setState({formState: form});
          }

        deleteMarque(param){
            const methode = 'equipementMarque/delete'
            const data = {
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isCostomer: false,
                datas: [{
                    id: param.id
                }]
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        toast.success(res.status.message);
                        this.getMarques();
                    } else {
                        toast.error(res.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error('Erreur '+ err);
                });
            }

      getUser(pageIndex?){
        const methode = 'user/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
            size: this.state.page.size,
            data: {
                userTypeCode: 'TECH',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res)=>{
                if(res && res.data && !res.data.hasError){
                    this.setState({listeUser: res.data.items});
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err)=>{
                toast.error('Erreur '+ err);
            });
        }

        createMarque(val?, resetForm?) {
            this.setState({affi:true})
            const methode = val && val.id ? 'equipementMarque/update' : 'equipementMarque/create';
            const data = {
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isInterview: false,
                datas: [{
                        id: val.id,
                        libelle: val.libelle
                }]
                
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res) => { 
                    if (res && res.data && !res.data.hasError) {
                        toast.success("Operation effectuer avec succes");
                        this.setState({affi:false})
                        this.getMarques();
                        this.initForm();
                        resetForm(this.state.formState);
                    } else {
                        toast.error("Operation echoué");
                        this.setState({affi:false})
                    }
                })
                .catch((err)=>{
                    toast.error('Erreur '+ err);
                });
        }

        searchEvent(param){
            
            this.setState({search: param});
            this.recherche(param);
        }
    
        async recherche(val){
            await new Promise((r) => setTimeout(r, 500));
            this.getMarques({libelle: val})
        }

        displayStatePayment(){
            
        }

      render(){
        
            return(
                <div className="content intervention mb-5">
                    <div className="container px-0">
                        <h2 className="mt-3 text-left">Gestion des marques</h2>
                    </div>
                    <div className="container mt-4 pt-5 pb-2">
                        <div className="row justify-content-end">
                            {/* <h4 className="mt-3">Nouveaux entretiens</h4> */}
                            <input type="text" placeholder="Rechercher par libellé"
                             value={this.state.search} onChange={(ev)=> this.searchEvent(ev.target.value)} className="form-control search-input w-25 rounded-0"/>
                        </div>
                        
                    </div>
                    <div className="container bg-light">
                    <div className="row bg-light">
                        <div className="col-md-8 px-0 bg-light">
                            <div className="tbl">
                                <div className="row-t-head mt-3">
                                    <div className="cell">ID </div>
                                    <div className="cell">Libelle </div>
                                    <div className="cell"> Date/Heure </div>
                                    <div className="cell"> Option </div>
                                </div>
                                {this.state.listeMarques.length > 0 ? this.state.listeMarques.map((item, index)=>
                                <div className="row-t mt-3" key={index} 
                                    onClick={()=>this.selectedRow(item, index)} 
                                    style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                                    <div className="cell">{index+1}</div>
                                    <div className="cell">{item.libelle}</div>
                                    <div className="cell">{item.createdAt} {item.hours}</div>
                                    <div className="cell">
                                        <button onClick={()=>this.goForModif(item)} className="btn btn-etat mr-1"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                        <button onClick={()=>this.delete(item)} className="btn btn-outline-danger btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                ): 'Chargement en cours ...'}
                            </div>
                            <div className="w-100 mt-2">
                            {this.state.listeMarques && this.state.listeMarques.length>0 ? 
                                <Pagination innerClass="pagination pull-right"
                                activePage={this.state.page.index}
                                itemsCountPerPage={this.state.page.size}
                                totalItemsCount={this.state.total}
                                pageRangeDisplayed={5}
                                onChange={(event)=>this.handlePageChange(event)}
                                itemClass="page-item"
                                linkClass="page-link"
                                /> : ''}
                            </div>
                        </div>
                        <div className="col-md-4">
                                <div className="card card-shadow p-3 mt-5">
                                    <div className="top-card text-left text-green">
                                        <h5 className="font-weight-bold">Marque</h5>
                                        {/* <div className="font-weight-bold my-2">Entretien split systems</div>
                                        <div>Id: {this.state.detailObjet?.id}</div>
                                        <div className="option-detail">
                                            <div className="my-2"> 
                                                <i className="fa fa-clock-o fa-14" aria-hidden="true"></i><span className="ml-3">{this.state.detailObjet?.hours} GMT</span>
                                            </div>
                                            <div className="my-2"> 
                                                <i className="fa fa-map-marker fa-14" aria-hidden="true"></i><span className="ml-3">{this.state.detailObjet?.lieu}</span>
                                            </div>
                                            <div className="my-2"> 
                                                <i className="fa fa-user fa-14" aria-hidden="true"></i>
                                                <span>{this.state.detailObjet?.lastName} {this.state.detailObjet?.firstName}</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <hr></hr>
                                    <div className="form">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={this.state.formState}
                                            onSubmit={async (values, {resetForm}) => {
                                                this.askBeforeCreate(values, resetForm);
                                                // await new Promise((r) => setTimeout(r, 500));
                                                // alert(JSON.stringify(values, null, 2));
                                            }}
                                            >
                                            {    
                                            ({ resetForm }) =>(<Form>
                                                <div className="form-group">
                                                    <label className="text-left w-100" htmlFor="libelle">Libellé</label>
                                                    <Field id="libelle" name="libelle" placeholder="Libellé marque" className="form-control rounded-0"/>
                                                   
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                <button
                                                        type="button"
                                                        className="btn btn-danger rounded-0 px-5"
                                                        onClick={() => resetForm(this.state.formState)}
                                                    >
                                                        Annuler
                                                    </button>
                                                    <button type="submit" className="btn btn-outline-success rounded-0 px-5">Valider</button>
                                                    <Loader
                                                        type="Puff"
                                                        color="#00BFFF"
                                                        height={50}
                                                        width={50}                                        
                                                        visible={this.state.affi}
                                                    />
                                                </div>
                                            </Form>)}
                                        </Formik>
                                    </div>
                                </div>
                        </div>
                    </div>
                    </div>
                </div>
            )
    }
}