import React
    // , {useState, useEffect, useRef} 
    from 'react';
import {
    Formik
    // , Form, Field, ErrorMessage, FastField
} from 'formik';
import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import FileSaver from 'file-saver';
import Loader from 'react-loader-spinner';
// const restClientApi = new ResClientApi();

export class Users extends React.Component {
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props) {
        super(props);

        this.state = {
            position: {
                lat: 7.539989,
                lng: -5.547080,
                zoom: 13,
            },
            listeTechnicien: [],
            listeUtilSouscrit: [],
            listeUtilNonSouscrit: [],
            listeAdmin: [],
            listeTech: [],
            setEntretien: 'client',
            listeUserType: [],
            modalShow: false,
            affi: false,
            assignationModalShow: false,
            selectedLigne: -1,
            detailObjet: null,
            marker: [7.539989, -5.547080],
            page: {
                index: 1,
                size: 10,
            },
            pageSup: {
                index: 1,
                size: 10,
            },
            pageTech: {
                index: 1,
                size: 10,
            },
            pageUtilSouscr: {
                index: 1,
                size: 10,
            },
            pageUtilNonSouscr: {
                index: 1,
                size: 10,
            },
            formState: {
                id: null,
                lastName: '',
                email: '',
                login: '',
                firstName: '',
                telephone: '',
                userTypeCode: 'TECH',
                profilTechnicienCode: '',
                file: '',
            },
            total: 0,
            totalAdmin: 0,
            totalTech: 0,
            totalUtilSouscr:0,
            totalUtilNonSouscr:0,
            search: '',
            datasDocuments: [
                {
                    nomFichier: '',
                    extension: '',
                    fichierBase64: ""
                }
            ],
            fileName: 'Choisir la photo du technicien',

        }
    }

    initForm() {
        this.setState({
            formState: {
                id: '',
                lastName: '',
                email: '',
                login: '',
                firstName: '',
                telephone: '',
                userTypeCode: 'TECH',
                profilTechnicienCode: '',
                file: '',
                //userModeCode: 'OFFRE_ENTRETIEN',
            }
        });
    }

    selectedRow(val, index) {
        this.setState({ selectedLigne: index });
        this.setState({ detailObjet: val });
    }

    goForModif(param) {
        const form = {
            id: param.id,
            lastName: param.lastName,
            email: param.email,
            login: param.login,
            firstName: param.firstName,
            telephone: param.telephone,
            userTypeCode: 'TECH',
            userTypeCode: param.profilTechnicienCode,
            file: '',
        };
        this.setState({ formState: form });
    }

    searchEvent(param) {
        this.setState({ search: param });
        this.recherche(param);
    }

    async recherche(val) {
        await new Promise((r) => setTimeout(r, 500));
        if (this.state.setEntretien === 'client') {
            this.getTechnicien({ nom: val })
        } else if (this.state.setEntretien === 'sup-admin') {
            this.getAdmin({ lastName: val })
        } else if (this.state.setEntretien === 'technicien'){
            this.getTech({ lastName: val })
        } else if (this.state.setEntretien === 'utilisateur-souscrit'){
            this.setState({pageUtilSouscr:{
                index:1,
                size: this.state.pageUtilSouscr.size,
            }})
            this.getUtilSouscrit({ lastName: val })
        } else if (this.state.setEntretien === 'utilisateur-non-souscrit'){
            this.setState({pageUtilNonSouscr:{
                index:1,
                size: this.state.pageUtilNonSouscr.size,
            }})
            this.getUtilNonSouscrit({ lastName: val })
        }
    }
    getTechnicien(param?, pageIndex?) {
        const methode = 'client/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {
                userTypeId: 1,
                nom: param && param.nom ? param.nom : '',
                prenom: "",
                adresse: "",
                email: "",
                telephone: "",
                // userTypeCode: 'TECH',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeTechnicien: res.data.items ? res.data.items : [] });
                    this.setState({ total: res.data.count ? res.data.count : 0 });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    
    getUtilSouscrit(param?, pageIndex?) {
        const methode = '/user/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.pageUtilSouscr.index - 1,
            size: this.state.pageUtilSouscr.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: {
                clientNom: param && param.lastName ? param.lastName : '',
                isSouscribe: true,
                userTypeCode: "USER"
                // userTypeCode: 'TECH',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeUtilSouscrit: res.data.items ? res.data.items : [] });
                    this.setState({ totalUtilSouscr: res.data.count ? res.data.count : 0 });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    getUtilNonSouscrit(param?, pageIndex?) {
        const methode = 'user/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.pageUtilNonSouscr.index - 1,
            size: this.state.pageUtilNonSouscr.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: {
                clientNom: param && param.lastName ? param.lastName : '',
                isSouscribe: false,
                userTypeCode: "USER"
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeUtilNonSouscrit: res.data.items ? res.data.items : [] });
                    this.setState({ totalUtilNonSouscr: res.data.count ? res.data.count : 0 });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    exportclient(param?, pageIndex?) {
        const methode = 'client/extraction'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: {
                isSouscribe:true
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res  && !res.data.hasError) {
                    window.location.href = res && res.data.filePathDoc //: window.location.href;
                    console.log('ddddd',/* window.location.href */);
                    //window.open(res.filePathDoc)
                   // const file = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                   // FileSaver.saveAs(file, `client.xlsx`);//-${this.param.nom}
                } else {
                    //console.log('aaa',res.data.filePathDoc);

                    toast.error(res.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    exportclientNom(param?, pageIndex?) {
        const methode = 'client/extraction'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: {
                isSouscribe:false
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res  && !res.data.hasError) {
                    window.location.href = res && res.data.filePathDoc //: window.location.href;
                    console.log('exportclientNom',/* window.location.href */);
                    //window.open(res.filePathDoc)
                   // const file = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                   // FileSaver.saveAs(file, `client.xlsx`);//-${this.param.nom}
                } else {
                    //console.log('aaa',res.data.filePathDoc);

                    toast.error(res.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    getAdmin(param?, pageIndex?) {
        const methode = 'user/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            size: this.state.pageSup.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {
                userTypeId: 2,
                lastName: param && param.lastName ? param.lastName : '',
                userTypeLibelle: param && param.userTypeLibelle ? param.userTypeLibelle : '',
                firstName: param && param.firstName ? param.firstName : '',
                // userTypeCode: 'TECH',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeAdmin: res.data.items ? res.data.items : [] });
                    this.setState({ totalAdmin: res.data.count ? res.data.count : 0 });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    getTech(param?, pageIndex?) {
        const methode = 'user/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            size: this.state.pageTech.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {
                userTypeId: 3,
                lastName: param && param.lastName ? param.lastName : '',
                firstName: param && param.firstName ? param.firstName : '',
                // userTypeCode: 'TECH',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeTech: res.data.items ? res.data.items : [] });
                    this.setState({ totalTech: res.data.count ? res.data.count : 0 });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    getUserType(param?, pageIndex?) {
        const methode = 'userType/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeUserType: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    getUserProfil(param?, pageIndex?) {
        const methode = 'profilTechnicien/getByCriteria'
        const data = {
            user: "25",
            isSimpleLoading: false,
            data: {
                id: ''
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeUserType: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    createTechnicien(param, resetForm) {
        this.setState({ affi: true })
        const methode = param && param.id ? 'user/update' : 'user/create'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [{ ...param, datasDocuments: this.state.datasDocuments }]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success("Operation effectuer avec succes");
                    this.setState({ affi: false });
                    this.getTechnicien();
                    this.getAdmin();
                    this.getTech();
                    this.initForm();
                    resetForm(this.state.formState);
                } else {
                    toast.error("Operation echoué");
                    this.setState({ affi: false });
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    handlePageChange(ev) {
        const index = ev;
        if (this.state.setEntretien === 'client') {
            this.setState({
                page: {
                    index: index,
                    size: this.state.page.size
                }
            });
            this.getTechnicien(null, ev);
        } else if (this.state.setEntretien === 'sup-admin') {
            this.setState({
                pageSup: {
                    index: index,
                    size: this.state.page.size
                }
            });
            this.getAdmin(null, ev);
        } else if (this.state.setEntretien === 'technicien'){
            this.setState({
                pageTech: {
                    index: index,
                    size: this.state.page.size
                }
            });
            this.getTech(null, ev);
        } else if (this.state.setEntretien === 'utilisateur-souscrit'){
            this.setState({
                pageUtilSouscr: {
                    index: index,
                    size: this.state.page.size
                }
            });
            this.getUtilSouscrit(null, ev);
        } else if (this.state.setEntretien === 'utilisateur-non-souscrit'){
            this.setState({
                pageUtilNonSouscr: {
                    index: index,
                    size: this.state.page.size
                }
            });
            this.getUtilNonSouscrit(null, ev);
        }
    }

    componentDidMount() {
        this.getTechnicien();
        this.getAdmin();
        this.getTech();
        this.getUserProfil();
        this.getUtilNonSouscrit();
        this.getUtilSouscrit();

    }
    deleteCleint(val) {
        Swal.fire({
            title: 'Attention',
            text: 'Êtes-vous sûre de vouloir la supprimer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                this.deleteClt(val);
            }
        })
    }
    deleteClt(param) {
        const methode = 'client/delete'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [{
                id: param.id
            }]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.getTechnicien();
                    this.getAdmin();
                    this.getTech();
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    delete(val) {
        Swal.fire({
            title: 'Attention',
            text: 'Êtes-vous sûre de vouloir la supprimer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                this.deleteTechnicien(val);
            }
        })
    }

    deleteTechnicien(param) {
        const methode = 'user/delete'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [{
                id: param.id
            }]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.getTechnicien();
                    this.getAdmin();
                    this.getTech();
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    showTable() {
        if (this.state.setEntretien === 'client') {
            return <><div className="col-md-12 px-0 bg-light">
                <div className="tbl">
                    <div className="row-t-head mt-3">
                        <div className="cell">ID </div>
                        <div className="cell">Nom </div>
                        <div className="cell">Prenoms</div>
                        <div className="cell">Email</div>
                        <div className="cell">Telephone</div>
                        <div className="cell">Date création</div>
                        <div className="cell"> Option</div> 
                    </div>
                    {this.state.listeTechnicien && this.state.listeTechnicien.length > 0 ? this.state.listeTechnicien.map((item, index) =>
                        <div className="row-t mt-3" key={index}
                            onClick={() => this.selectedRow(item, index)}
                            style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                            <div className="cell">{index + 1}</div>
                            <div className="cell">{item.nom}</div>
                            <div className="cell">{item.prenom}</div>
                            <div className="cell">{item.email}</div>
                            <div className="cell">{item.telephone}</div>
                            <div className="cell">{item.createdAt}</div>
                            <div className="cell">
                                {/* <button onClick={() => this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>*/}
                                <button onClick={() => this.deleteCleint(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                            </div> 
                        </div>
                    ) : 'Aucune donnée'}
                </div>
                <div className="w-100 mt-2">
                    {this.state.listeTechnicien && this.state.listeTechnicien.length > 0 ?
                        <Pagination innerClass="pagination pull-right"
                            activePage={this.state.page.index}
                            itemsCountPerPage={this.state.page.size}
                            totalItemsCount={this.state.total}
                            pageRangeDisplayed={5}
                            onChange={(event) => this.handlePageChange(event)}
                            itemClass="page-item"
                            linkClass="page-link"
                        /> : ''}
                </div>
            </div>
            </>
        } else if (this.state.setEntretien === 'sup-admin') {
            return <> <div className="col-md-8 px-0 bg-light">
                <div className="tbl">
                    <div className="row-t-head mt-3">
                        <div className="cell">ID </div>
                        <div className="cell">Nom </div>
                        <div className="cell">Prenoms</div>
                        <div className="cell">Email</div>
                        <div className="cell">Telephone</div>
                        <div className="cell">Date création</div>
                        <div className="cell"> Option</div>
                    </div>
                    {this.state.listeAdmin && this.state.listeAdmin.length > 0 ? this.state.listeAdmin.map((item, index) =>
                        <div className="row-t mt-3" key={index}
                            onClick={() => this.selectedRow(item, index)}
                            style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                            <div className="cell">{index + 1}</div>
                            <div className="cell">{item.lastName}</div>
                            <div className="cell">{item.firstName}</div>
                            <div className="cell">{item.email}</div>
                            <div className="cell">{item.telephone}</div>
                            <div className="cell">{item.createdAt}</div>
                            <div className="cell">
                                <button onClick={() => this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                <button onClick={() => this.delete(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    ) : 'Aucune donnée'}
                </div>
                <div className="w-100 mt-2">
                    {this.state.listeAdmin && this.state.listeAdmin.length > 0 ?
                        <Pagination innerClass="pagination pull-right"
                            activePage={this.state.pageSup.index}
                            itemsCountPerPage={this.state.pageSup.size}
                            totalItemsCount={this.state.totalAdmin}
                            pageRangeDisplayed={5}
                            onChange={(event) => this.handlePageChange(event)}
                            itemClass="page-item"
                            linkClass="page-link"
                        /> : ''}
                </div>
            </div>
            </>
        } else if (this.state.setEntretien === 'technicien') {
            return <> <div className="col-md-8 px-0 bg-light">
                <div className="tbl">
                    <div className="row-t-head mt-3">
                        <div className="cell">ID </div>
                        <div className="cell">Nom </div>
                        <div className="cell">Prenoms</div>
                        <div className="cell">Email</div>
                        <div className="cell">Telephone</div>
                        <div className="cell">Date création</div>
                        <div className="cell"> Photo</div>
                        <div className="cell"> Option</div>
                    </div>
                    {this.state.listeTech && this.state.listeTech.length > 0 ? this.state.listeTech.map((item, index) =>
                        <div className="row-t mt-3" key={index}
                            onClick={() => this.selectedRow(item, index)}
                            style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                            <div className="cell">{index + 1}</div>
                            <div className="cell">{item.lastName}</div>
                            <div className="cell">{item.firstName}</div>
                            <div className="cell">{item.email}</div>
                            <div className="cell">{item.telephone}</div>
                            <div className="cell">{item.createdAt}</div>
                            <div className="cell">
                                <img src={item.datasDocuments && item.datasDocuments[0].libelle} width={60} />
                            </div>
                            <div className="cell">
                                <button onClick={() => this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                <button onClick={() => this.delete(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                            </div>
                        </div>

                    ) : 'Aucune donnée'}
                </div>
                <div className="w-100 mt-2">
                    {this.state.listeTech && this.state.listeTech.length > 0 ?
                        <Pagination innerClass="pagination pull-right"
                            activePage={this.state.pageTech.index}
                            itemsCountPerPage={this.state.pageTech.size}
                            totalItemsCount={this.state.totalTech}
                            pageRangeDisplayed={5}
                            onChange={(event) => this.handlePageChange(event)}
                            itemClass="page-item"
                            linkClass="page-link"
                        /> : ''}
                </div>
            </div>
            </>
        } else if (this.state.setEntretien === 'utilisateur-souscrit') {
            return <><div className="col-md-12 px-0 bg-light">
            <div className="tbl">
                <div className="row-t-head mt-3">
                    <div className="cell">ID </div>
                    <div className="cell">Nom </div>
                    <div className="cell">Prenoms</div>
                    <div className="cell">Email</div>
                    <div className="cell">Telephone</div>
                    <div className="cell">Staut</div>
                    <div className="cell">Date création</div>
                    <button onClick={() => this.exportclient()}
                            className="btn btn-sm btn-outline-secondary text-dark rounded-pill ">Export client</button>
                    {/*  <div className="cell"> Option</div> */}
                </div>
                {this.state.listeUtilSouscrit && this.state.listeUtilSouscrit.length > 0 ? this.state.listeUtilSouscrit.map((item, index) =>
                    <div className="row-t mt-3" key={index}
                        onClick={() => this.selectedRow(item, index)}
                        style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                        <div className="cell">{index + 1}</div>
                        <div className="cell">{item.clientNom}</div>
                        <div className="cell">{item.clientPrenom}</div>
                        <div className="cell">{item.email}</div>
                        <div className="cell">{item.telephone}</div>
                        <div className="cell">{item.telephone}</div>
                        <div className="cell">{item.createdAt}</div>
                        <div className="cell"></div>
                        {/* <div className="cell">
                            <button onClick={() => this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                            <button onClick={() => this.delete(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                        </div> */}
                    </div>
                ) : 'Aucune donnée'}
            </div>
            <div className="w-100 mt-2">
                {this.state.listeUtilSouscrit && this.state.listeUtilSouscrit.length > 0 ?
                    <Pagination innerClass="pagination pull-right"
                        activePage={this.state.pageUtilSouscr.index}
                        itemsCountPerPage={this.state.pageUtilSouscr.size}
                        totalItemsCount={this.state.totalUtilSouscr}
                        pageRangeDisplayed={5}
                        onChange={(event) => this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                    /> : ''}
            </div>
        </div>
        </>
        }else if (this.state.setEntretien === 'utilisateur-non-souscrit') {
            return <><div className="col-md-12 px-0 bg-light">
            <div className="tbl">
                <div className="row-t-head mt-3">
                    <div className="cell">ID </div>
                    <div className="cell">Nom </div>
                    <div className="cell">Prenoms</div>
                    <div className="cell">Email</div>
                    <div className="cell">Telephone</div>
                    <div className="cell">Staut</div>
                    <div className="cell">Date création</div>
                    <button onClick={() => this.exportclientNom()}
                            className="btn btn-sm btn-outline-secondary text-dark rounded-pill ">Export client</button>
                    {/*  <div className="cell"> Option</div> */}
                </div>
                {this.state.listeUtilNonSouscrit && this.state.listeUtilNonSouscrit.length > 0 ? this.state.listeUtilNonSouscrit.map((item, index) =>
                    <div className="row-t mt-3" key={index}
                        onClick={() => this.selectedRow(item, index)}
                        style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                        <div className="cell">{index + 1}</div>
                        <div className="cell">{item.clientNom}</div>
                        <div className="cell">{item.clientPrenom}</div>
                        <div className="cell">{item.email}</div>
                        <div className="cell">{item.telephone}</div>
                        <div className="cell">{item.telephone}</div>
                        <div className="cell">{item.createdAt}</div>
                        <div className="cell"></div>
                        {/* <div className="cell">
                            <button onClick={() => this.goForModif(item)} className=" btn-style"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                            <button onClick={() => this.delete(item)} className="btn btn-outline-danger btn-style btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                        </div> */}
                    </div>
                ) : 'Aucune donnée'}
            </div>
            <div className="w-100 mt-2">
                {this.state.listeUtilNonSouscrit && this.state.listeUtilNonSouscrit.length > 0 ?
                    <Pagination innerClass="pagination pull-right"
                        activePage={this.state.pageUtilNonSouscr.index}
                        itemsCountPerPage={this.state.pageUtilNonSouscr.size}
                        totalItemsCount={this.state.totalUtilNonSouscr}
                        pageRangeDisplayed={5}
                        onChange={(event) => this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                    /> : ''}
            </div>
        </div>
        </>
        }
    }

    showFrom() {
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
       /*  if (this.state.setEntretien === 'client') {
            return <div className="col-md-4">
                <div className="card card-shadow p-3 mt-5">
                    <div className="top-card text-left text-green">
                        <h5 className="font-weight-bold">Utilisateurs</h5>
                    </div>
                    <div className="my-2">
                        <img src={this.state.detailObjet?.datasDocuments && this.state.detailObjet?.datasDocuments[0].libelle} className="w-100" />
                    </div>
                    <hr />
                    <div className="form">
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.state.formState}
                            validationSchema={Yup.object().shape({
                                lastName: Yup.string()
                                    .required('Champs requis'),
                                firstName: Yup.string()
                                    .required('Champs requis'),
                                email: Yup.string()
                                    .email('Veuillez saisir un mail correcte')
                                    .required('Champs requis'),
                                telephone: Yup.string()
                                    .matches(phoneRegExp, 'le numero doit comporter au moins 10 chiffres')
                                    .required('Champs requis'),
                            })}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                this.createTechnicien(values, resetForm);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mt-3">
                                        <label htmlFor="nom">Nom</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="nom"
                                            placeholder="Nom"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                        />
                                        {errors.firstName && touched.firstName && errors.firstName}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="last-name">Prenoms</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            id="last-name"
                                            placeholder="Prenoms"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                        />
                                        {errors.lastName && touched.lastName && errors.lastName}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="e-mail">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="e-mail"
                                            placeholder="Email: xxxxx@xxx.com"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && errors.email}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="telephone">Téléphone</label>
                                        <input
                                            type="text"
                                            name="telephone"
                                            id="telephone"
                                            placeholder="Tel: +225 XX XX XX XX"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telephone}
                                        />
                                        {errors.telephone && touched.telephone && errors.telephone}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                        >Annuler</button>
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}
                                            visible={this.state.affi}
                                        />
                                        <button disabled={this.state.formState.id ? false : true} type="submit" className="btn btn-outline-success btn-text rounded-0"
                                        >Modifier</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>

        } else */ if (this.state.setEntretien === 'sup-admin') {
            return <div className="col-md-4">
                <div className="card card-shadow p-3 mt-5">
                    <div className="top-card text-left text-green">
                        <h5 className="font-weight-bold">Utilisateurs</h5>
                    </div>
                    <div className="my-2">
                        <img src={this.state.detailObjet?.datasDocuments && this.state.detailObjet?.datasDocuments[0].libelle} className="w-100" />
                    </div>
                    <hr />
                    <div className="form">
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.state.formState}
                            validationSchema={Yup.object().shape({
                                lastName: Yup.string()
                                    .required('Champs requis'),
                                firstName: Yup.string()
                                    .required('Champs requis'),
                                email: Yup.string()
                                    .email('Veuillez saisir un mail correcte')
                                    .required('Champs requis'),
                                login: Yup.string()
                                    .required('Champs requis'),
                                telephone: Yup.string()
                                    .matches(phoneRegExp, 'le numero doit comporter au moins 10 chiffres')
                                    .required('Champs requis'),
                            })}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                this.createTechnicien(values, resetForm);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mt-3">
                                        <label htmlFor="nom">Nom</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="nom"
                                            placeholder="Nom"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                        />
                                        {errors.firstName && touched.firstName && errors.firstName}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="last-name">Prenoms</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            id="last-name"
                                            placeholder="Prenoms"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                        />
                                        {errors.lastName && touched.lastName && errors.lastName}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="login">Login</label>
                                        <input
                                            type="text"
                                            name="login"
                                            id="login"
                                            placeholder="Login"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.login}
                                        />
                                        {errors.login && touched.login && errors.login}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="e-mail">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="e-mail"
                                            placeholder="Email: xxxxx@xxx.com"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && errors.email}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="telephone">Téléphone</label>
                                        <input
                                            type="text"
                                            name="telephone"
                                            id="telephone"
                                            placeholder="Tel: +225 XX XX XX XX"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telephone}
                                        />
                                        {errors.telephone && touched.telephone && errors.telephone}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                        >Annuler</button>
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}
                                            visible={this.state.affi}
                                        />
                                        <button disabled={this.state.formState.id ? false : true} type="submit" className="btn btn-outline-success btn-text rounded-0"
                                        >Modifier</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        } else if(this.state.setEntretien === 'technicien') {
            return <div className="col-md-4">
                <div className="card card-shadow p-3 mt-5">
                    <div className="top-card text-left text-green">
                        <h5 className="font-weight-bold">Utilisateurs</h5>
                    </div>
                    <div className="my-2">
                        <img src={this.state.detailObjet?.datasDocuments && this.state.detailObjet?.datasDocuments[0].libelle} className="w-100" />
                    </div>
                    <hr />
                    <div className="form">
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.state.formState}
                            validationSchema={Yup.object().shape({
                                lastName: Yup.string()
                                    .required('Champs requis'),
                                firstName: Yup.string()
                                    .required('Champs requis'),
                                email: Yup.string()
                                    .email('Veuillez saisir un mail correcte')
                                    .required('Champs requis'),
                                login: Yup.string()
                                    .required('Champs requis'),
                                telephone: Yup.string()
                                    .matches(phoneRegExp, 'le numero doit comporter au moins 10 chiffres')
                                    .required('Champs requis'),
                            })}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                this.createTechnicien(values, resetForm);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mt-3">
                                        <label htmlFor="nom">Nom</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="nom"
                                            placeholder="Nom"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                        />
                                        {errors.firstName && touched.firstName && errors.firstName}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="last-name">Prenoms</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            id="last-name"
                                            placeholder="Prenoms"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                        />
                                        {errors.lastName && touched.lastName && errors.lastName}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="login">Login</label>
                                        <input
                                            type="text"
                                            name="login"
                                            id="login"
                                            placeholder="Login"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.login}
                                        />
                                        {errors.login && touched.login && errors.login}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="profilTechnicienCode">type de profile</label>
                                        <select name="profilTechnicienCode" id="profilTechnicienCode"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.profilTechnicienCode}
                                            className="form-control rounded-0">
                                            <option>Selectionnez le type de profile</option>
                                            {this.state.listeUserType && this.state.listeUserType.map((item, index) =>
                                                <option key={index} value={item.code}>{item.libelle}</option>
                                            )}
                                        </select>
                                        {errors.typeProduitId && touched.typeProduitId && errors.typeProduitId}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="e-mail">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="e-mail"
                                            placeholder="Email: xxxxx@xxx.com"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && errors.email}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="telephone">Téléphone</label>
                                        <input
                                            type="text"
                                            name="telephone"
                                            id="telephone"
                                            placeholder="Tel: +225 XX XX XX XX"
                                            className="form-control rounded-0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telephone}
                                        />
                                        {errors.telephone && touched.telephone && errors.telephone}
                                    </div>
                                    <div className="form-group mt-3">
                                        <input
                                            id="fichierImg"
                                            type="file"
                                            name="file"
                                            placeholder="Login"
                                            className="form-control rounded-0 inputfile"
                                            onChange={(event) => this.processFile(event)}
                                            onBlur={handleBlur}
                                            value={values.file}
                                        />
                                        <label htmlFor="fichierImg">
                                            <i className="fa fa-upload fa-14 mr-2" aria-hidden="true"></i>
                                            {this.state.fileName}
                                        </label>
                                        {errors.file && touched.file && errors.file}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                        >Annuler</button>
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}
                                            visible={this.state.affi}
                                        />
                                        <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                        >Valider</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        }
    }

    processFile(event, doc?) {
        const reader = new FileReader();
        const file = event.target.files[0];
        let fileData;
        reader.onload = (readerEvent) => {
            const data = (readerEvent.target).result;
            //console.log('data file', file.name.split('.')[file.name.split('.').length-1]);

            //this.fileName3 = [file.name,this.state.datasDocuments[0].nomFichier]; 
            this.setState({
                fileName: file.name,
                /*  fileName:this.fileName3[1],
                 fileName2: this.fileName3[0], */
            })
            fileData = {
                nomFichier: file.name.split('.')[0],
                extension: file.name.split('.')[file.name.split('.').length - 1],
                fichierBase64: data.split(',')[1]
            };
            /*  if (this.datasDocuments.length < 2) {
                 this.datasDocuments.push(fileData)
             } */
            this.setState({ datasDocuments: [fileData] });
            //   this.setFileData(fileData);
            //   event.stopPropagation();
        };
        reader.readAsDataURL(event.target.files[0]);
        event.preventDefault();
    }
    render() {
        return (
            <div className="content intervention mb-5">
                <div className="container px-0">
                    <h2 className="mt-3 text-left">Gestion des utilisateurs</h2>
                </div>
                <div className="container mt-4 pt-5 pb-2">
                    <div className="row justify-content-between">
                        <h4 className="mt-3">Utilisateurs</h4>
                        <input type="text" placeholder="Rechercher par nom  "
                            value={this.state.search} onChange={(val) => this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0" />
                    </div>
                </div>

                <div className="container mt-4 pt-5 pb-2">
                    <div className="row">
                        {/*  <button onClick={TouPage} type="button" className="btn btn-sm btn-outline-secondary rounded-pill mr-4">Tout</button> */}
                        <button onClick={() => this.setState({ setEntretien: 'client' })} type="button" style={{ backgroundColor: this.state.setEntretien === 'client' ? '#e3fef3' : 'transparent' }}
                            className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Client</button>
                        <button onClick={() => this.setState({ setEntretien: 'sup-admin' })} type="button" style={{ backgroundColor: this.state.setEntretien === 'sup-admin' ? '#e3fef3' : 'transparent' }}
                            className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Sup admin</button>
                        <button onClick={() => this.setState({ setEntretien: 'technicien' })} type="button" style={{ backgroundColor: this.state.setEntretien === 'technicien' ? '#e3fef3' : 'transparent' }}
                            className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Technicien</button>
                        <button onClick={() => this.setState({ setEntretien: 'utilisateur-non-souscrit' })} type="button" style={{ backgroundColor: this.state.setEntretien === 'utilisateur-non-souscrit' ? '#e3fef3' : 'transparent' }}
                            className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Uilisateur non souscrit</button>
                        <button onClick={() => this.setState({ setEntretien: 'utilisateur-souscrit' })} type="button" style={{ backgroundColor: this.state.setEntretien === 'utilisateur-souscrit' ? '#e3fef3' : 'transparent' }}
                            className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Uilisateur ayant souscrit</button>
                        

                    </div>
                </div>

                <div className="container bg-light">
                    <div className="row bg-light">
                        {this.showTable()}
                        {this.showFrom()}
                    </div>
                </div>
            </div>
        )
    }
}