import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../styles/intervention.scss';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Select from 'react-select'
// import Pagination from "react-js-pagination";
import Loader from 'react-loader-spinner';
import {
    Formik, Form, Field
    // , ErrorMessage, FastField, FormikProps
} from 'formik';
// import * as Yup from 'yup';
import DatePicker, {
    registerLocale
    // , setDefaultLocale 
} from "react-datepicker";
// import  from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/intervention.scss';
import * as moment from 'moment';
import subDays from "date-fns/subDays";
import { toast } from 'react-toastify';
registerLocale('fr', fr);

const restClientApi = new ResClientApi();
const userService = new UserService();
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const DevisModa = (props) => {

    // const [techniciens, settechniciens] = useState(props.techniciens);
    const [startDate, setStartDate] = useState(new Date());
    const [affi, setaffi] = useState(false);
    const [datasDetailDevis, setDatasDetailDevis] = useState([]);
    const [fileName, setFileName] = useState('Choisir un fichier')
    const [montant, setMontant] = useState('')
    const [entretien, setEntretien] = useState('creation_devis');
    const [datasDocuments, setDatasDocuments] = useState([{
        nomFichier: '',
        fichierBase64: '',
        extension: ''
    }])
    const [detailDevi, setDetailDevi] = useState([{
        libelle: "",
        quantite: "",
        prixunitaire: "",
    }])
    const initialData = () => {
        setDatasDocuments([{
            nomFichier: '',
            fichierBase64: '',
            extension: ''
        }])
        setFileName('Choisir un fichier')
    }
    const initialValues = () => {
        setDetailDevi([{
            libelle: "",
            quantite: "",
            prixunitaire: "",
        }])
    }
    useEffect(() => {
        // settechniciens(props.techniciens)
        // getTechnicien();
    }, []);

    function createDevis(param, resetForm) {
        setaffi(true)
        console.log("mantant", montant)
        const methode = 'devis/create';
        let request;
        if (fileName === 'Choisir un fichier') {
            request = [{ ...param, datasDetailDevis: detailDevi, interventionId: props.data.id, montant: montant , forReplace: true, typeDevisCode: 'INTERVENTION' }]
        } else {
            request = [{ ...param, datasDocuments: datasDocuments, interventionId: props.data.id, montant: montant, forReplace: true, typeDevisCode: 'INTERVENTION' }]
        }
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            datas: request
        };
        restClientApi.executePostApi(methode, data)
            .then(res => {
                if (res && res.data && !res.data.hasError) {
                    toast.success("Operation effectuer avec succes");
                    setaffi(false)
                    initialData();
                    initialValues();
                    setDatasDocuments([{
                        nomFichier: '',
                        fichierBase64: '',
                        extension: ''
                    }]);
                    props.onHide(false);
                } else {
                    toast.error(res.data.status.message);
                    setaffi(false)
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    function processFile(event, doc?) {
        const reader = new FileReader();
        const file = event.target.files[0];
        let fileData;
        reader.onload = (readerEvent) => {
            const data = (readerEvent.target).result;
            //console.log('data file', file.name.split('.')[file.name.split('.').length-1]);

            //this.fileName3 = [file.name,this.state.datasDocuments[0].nomFichier]; 
            setFileName(file.name
                /*  fileName:this.fileName3[1],
                 fileName2: this.fileName3[0], */
            )
            fileData = {
                nomFichier: file.name.split('.')[0],
                extension: file.name.split('.')[file.name.split('.').length - 1],
                fichierBase64: data.split(',')[1]
            };
            /*  if (this.datasDocuments.length < 2) {
                 this.datasDocuments.push(fileData)
             } */
            setDatasDocuments([fileData]);
            //   this.setFileData(fileData);
            //   event.stopPropagation();
        };
        reader.readAsDataURL(event.target.files[0]);
        event.preventDefault();
    }
    function fermer() {
        props.onHide(false);
        initialValues();
        initialData();

    }
    const handlleChange = (e) => {
        setMontant(e.target.value)
    }

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...detailDevi];
        list[index][name] = value;
        setDetailDevi(list);
    };
    const handleAddInput = () => {
        setDetailDevi([...detailDevi, { libelle: "", quantite: '', prixunitaire: '' }])
    }
    const handleRemoveInput = index => {
        const list = [...detailDevi];
        list.splice(index, 1);
        setDetailDevi(list);
    }
    const montantTotal = () => {
        let montanTotal = 0;
        let total = 0;
        for (const index in detailDevi) {
            total = detailDevi[index].quantite * detailDevi[index].prixunitaire
            montanTotal += total
        }
        return montanTotal;
    }

    function createDevi() {
        if (entretien === 'creation_devis') {
            return <>
                {detailDevi.map((item, i) => {
                    return (
                        <div key={i} className="row border-bottom border-5 bg-light" >
                            <div className="col-md-4 form-group">
                                <input
                                    disabled={fileName === 'Choisir un fichier' ? false : true}
                                    type="text"
                                    name="libelle"
                                    placeholder="libelle"
                                    className="form-control w-28 rounded-0"
                                    onChange={e => handleChange(e, i)}
                                    value={item.libelle}
                                />
                            </div>
                            <div className="col-md-2 w-30 form-group">
                                <input
                                    disabled={fileName === 'Choisir un fichier' ? false : true}
                                    type="number"
                                    name="quantite"
                                    placeholder="quantite"
                                    className="form-control rounded-0"
                                    value={item.quantite >= 1 ? item.quantite : ""}
                                    onChange={e => handleChange(e, i)}
                                />
                            </div>
                            <div className=" col-md-4 form-group ">
                                <input
                                    disabled={fileName === 'Choisir un fichier' ? false : true}
                                    type="number"
                                    name="prixunitaire"
                                    placeholder="prix unitaire"
                                    className="form-control rounded-0"
                                    value={item.prixunitaire >= 1 ? item.prixunitaire : ""}
                                    onChange={e => handleChange(e, i)}
                                />
                            </div>
                            <div className=" col-md-2 form-group ">
                                {detailDevi.length !== 1 && <button onClick={() => handleRemoveInput(i)} className="btn btn-etat  rounded-0 px-1 py-0"><i className="fa fa-trash-o icon" aria-hidden="true"></i></button>}
                                {detailDevi.length - 1 === i && <button onClick={handleAddInput} className="btn btn-etat  rounded-0 px-1 py-0"><i className="fa fa-plus icon" aria-hidden="true"></i></button>}
                            </div>
                        </div>
                    )
                })}
                <div className="form-group mt-3">
                    <input
                        disabled={true}
                        type="number"
                        name="montantTotal"
                        placeholder="montant total"
                        className="form-control rounded-0"
                        value={montantTotal()}
                        onChange={handleChange}
                    />
                </div></>
        }

    }
    function uplodeDevis() {
        if (entretien === 'uplod_devis') {
            return <>
                <div className="form-group mt-3">
                    <input
                        disabled={detailDevi[0].libelle || detailDevi[0].quantite || detailDevi[0].prixunitaire ? true : false}
                        id="fichierImg"
                        type="file"
                        name="file"
                        placeholder="Login"
                        className="form-control rounded-0 inputfile"
                        onChange={event => processFile(event)}
                       
                    />
                    <label htmlFor="fichierImg">
                        <i className="fa fa-upload fa-14 mr-2" aria-hidden="true"></i>
                        {fileName}
                    </label>
                </div> 
                <div className="form-group mt-3">
                <input
                    type="number"
                    name="montant"
                    placeholder="montant "
                    className="form-control rounded-0"
                    value={montant}
                    onChange={e => handlleChange(e)}
                />
            </div></>
        }

    }

    return (
        <Modal
            {...props}
            backdrop="static"
            size="lg"
            animation={true}
            keyboard={false}>
            <Modal.Header closeButton className="border-0 pb-0">

                <button onClick={() => setEntretien('creation_devis')} type="button" style={{ backgroundColor: entretien === 'creation_devis' ? '#e3fef3' : 'transparent' }}
                    className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Creation devis</button>
                <button onClick={() => setEntretien('uplod_devis')} type="button" style={{ backgroundColor: entretien === 'uplod_devis' ? '#e3fef3' : 'transparent' }}
                    className="btn btn-sm btn-outline-secondary text-dark rounded-pill mr-4">Uplode devis pdf</button>

                {/* <Modal.Title className="text-green">Création de dévis</Modal.Title> */}
            </Modal.Header>
            <Modal.Body >
                <Formik
                    initialValues={{
                        userId: '',
                        dateIntervention: startDate,
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        await sleep(500);
                        values.dateIntervention = moment(startDate).format('DD/MM/YYYY');
                        createDevis(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        formProps
                    }) => (

                            <Form>
                                {createDevi()}

                                {uplodeDevis()}
                                <div className="d-flex border-0 pt-0 justify-content-between mt-3">
                                    <Button type="reset" className="btn btn-outline-default btn-sm btn-text rounded-0 px-4"
                                        onClick={() => fermer()}>Fermer</Button>
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={50}
                                        width={50}
                                        visible={affi}
                                    />
                                    <button type="submit" className="btn btn-outline-success btn-sm btn-text rounded-0 px-4">
                                        Envoyer
                                </button>
                                </div>
                            </Form>
                        )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default DevisModa;