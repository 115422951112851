import React, { useState, useEffect } from 'react'
import { Link, withRouter } from "react-router-dom"
// import { Formik } from 'formik';
import Moment from 'react-moment';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { toast } from 'react-toastify';
// import { Swiper, SwiperSlide } from 'swiper/react';
import {
    LineChart, Line,
    // XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import ProgressBar from 'react-bootstrap/ProgressBar';

import '../styles/dashboard.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import moment from 'moment';
const restClientApi = new ResClientApi();
const userService = new UserService();
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export function Dashboard() {

    const [listeInfoStat, setListeInfoStat] = useState({});
    const [listeTopTechnicien, setListeTopTechnicien] = useState([]);
    const [listeTopAchatCategorie, setListeTopAchatCategorie] = useState([]);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));
    const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));

    const data = [
        {
            name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
        },
        {
            name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
        },
        {
            name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
        },
        {
            name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
        },
        {
            name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
        },
        {
            name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
        },
        {
            name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
        },
    ];
    let jour = new Date()
    let jourdate = moment(jour).format('DD/MM/YYYY')
   /*  setDateStart((moment(jour).format('YYYY-MM-DD')))
    setDateEnd( (moment(jour).format('YYYY-MM-DD')) ) */


    /* let start
    let end */

    useEffect(() => {
        getInfoStat(jourdate);
        getTopTechnicien();
        getTopAchatCategorie();
    }, []);

    function startDate(val) {
       
        setStart((moment(val.target.value).format('DD/MM/YYYY')))
         setDateStart((moment(val.target.value).format('YYYY-MM-DD')))
   
        /* value={moment(datedash).format('YYYY-MM-DD')} */
    }
    function endDate(val) {
       
        setEnd( (moment(val.target.value).format('DD/MM/YYYY')))
         setDateEnd( (moment(val.target.value).format('YYYY-MM-DD')) ) 
           console.log('end', end);
       
       /* value={moment(datedash).format('YYYY-MM-DD')} */
   }
    function getInfoStat() {
        const methode = 'notification/getInfoStat'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                createdAt: jourdate,
                createdAtParam: {
           operator: "[]",
           start: start ? start : jourdate,
           end: end ? end : jourdate
          }
            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setListeInfoStat(res.data.item);
                    console.log('listeInfoStat', listeInfoStat);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }
    function getTopTechnicien() {
        const methode = 'user/getTopTechnicien'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isSimpleLoading: false,
            data: {

            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setListeTopTechnicien(res.data.items);
                    console.log('listeTopTechnicien', listeTopTechnicien);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    function getTopAchatCategorie() {
        const methode = 'achatProduit/getTopAchatCategorie'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            isSimpleLoading: false,
            data: {
            }
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    setListeTopAchatCategorie(res.data.items);
                    console.log('listeTopAchatCategorie', listeTopAchatCategorie);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    return (
        <div className="content intervention mb-5 ml-sm-3">
            <div className="container px-0">
                <h1 className="mt-3 text-left font-weight-light">Tableau de bord</h1>
                <span className="text-grey">Bienvenue, {listeInfoStat.userName} {listeInfoStat.userLastName}</span>
            </div>
            <div className="container mt-4 pt-5 pb-2">
                <div className="row justify-content-between">
                    <h4 className="mt-3 fs-18">Nombre total</h4>
                    {/* <Moment interval={30000} format="YYYY/MM/DD"></Moment> */}
                    <label style={{marginLeft: '225px'}} for="start">Debut</label>
                     <input type="date"  className="form-control  w-20  rounded-0" id="start" value={dateStart} onChange={(val) => startDate(val)}/> 
                    <label for="fin">Fin</label>
                     <input type="date"  className="form-control w-20  rounded-0"  id="fin" value={dateEnd} onChange={(val) => endDate(val)}/> 
                     <i style={{fontSize: '25px', cursor: 'pointer'}} onClick={()=>getInfoStat()} className="fa fa-search"></i>
                     
                </div>
                <div className="row mt-3">
                    <div className="section border-right-grey col-md-3 pl-0">
                        <span className="text-uppercase text-grey">Entretiens</span>
                        <div className="py-2 pos-relative-top">
                            <div className="bloc-value">{listeInfoStat.nbrEntretien}</div>
                            <span className="text-grey">Montant total: {listeInfoStat.montantTotalEntretien}</span>
                        </div>
                    </div>
                    <div className="section border-right-grey col-md-3 px-5">
                        <span className="text-uppercase text-grey">Interventions</span>
                        <div className="py-2 pos-relative-top">
                            <div className="bloc-value-left">{listeInfoStat.nbrIntervention}</div>
                            <span className="text-grey">Montant total: {listeInfoStat.montantTotalIntervention}</span>
                        </div>
                    </div>
                     {/*  <div className="bloc-value-right up-text-color mt-2">
                                <i className="fa fa-caret-up" aria-hidden="true"></i>  +56%
                            </div> */}
                    <div className="section border-right-grey col-md-3 px-5">
                        <span className="text-uppercase text-grey">Ventes</span>
                        <div className="py-2 pos-relative-top">
                            <div className="bloc-value-left">{listeInfoStat.nbrAchat}</div>
                            <span className="text-grey">Montant total: {listeInfoStat.montantTotalAchat}</span>
                            {/*  <div className="bloc-value-right down-text-color mt-2">
                                <i className="fa fa-caret-down" aria-hidden="true"></i>  +56%
                            </div> */}
                        </div>
                    </div>
                    <div className="section  col-md-3 px-5">
                        <span className="text-uppercase text-grey">Clients</span>
                        <div className="py-2 pos-relative-top">
                            <div className="bloc-value-left">{listeInfoStat.nombreTotalClient}</div>
                            <span className="text-grey">CA: {listeInfoStat.ca}</span>
                            {/*  <div className="bloc-value-right down-text-color mt-2">
                                <i className="fa fa-caret-down" aria-hidden="true"></i>  +56%
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container mt-4 pt-3 pb-2 px-5 ombre-grey">
                <div className="row justify-content-between">
                    <select name="type" className="form-control w-25 rounded-0">
                        <option>Entretiens</option>
                    </select>
                </div>
                <div className="row mt-3">
                    <div className="row">
                       
                        <div className="px-3">
                            <div className="text-grey text-center">Janvier</div>
                            <div className="bloc-value-right fs-18 down-text-color mt-2 text-center">
                                <i className="fa fa-caret-down" aria-hidden="true"></i>  +56%
                                    </div>
                            <LineChart width={200} height={100} data={data}>
                                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                            </LineChart>
                        </div>
                        <div className="px-3">
                            <div className="text-grey text-center">Février</div>
                            <div className="bloc-value-right fs-18 up-text-color mt-2 text-center">
                                <i className="fa fa-caret-up" aria-hidden="true"></i>  +56%
                                    </div>
                            <LineChart width={200} height={100} data={data}>
                                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                            </LineChart>
                        </div>
                        <div className="px-3">
                            <div className="text-grey text-center">Mars</div>
                            <div className="bloc-value-right fs-18 down-text-color mt-2 text-center">
                                <i className="fa fa-caret-down" aria-hidden="true"></i>  +56%
                                    </div>
                            <LineChart width={200} height={100} data={data}>
                                <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                            </LineChart>
                        </div>
                     
                    </div>
                </div>
            </div> */}
            <div className="container mt-4 pt-3 pb-2 px-0 ">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xl-6 px-0">
                        <div className="row justify-content-between px-3 my-2">
                            <h4>Top Techniciens</h4>
                            <Link to="/techniciens">Voir tout</Link>
                        </div>
                        <div className="">
                            {listeTopTechnicien && listeTopTechnicien.map((item, index) =>
                                <div className="ombre-grey d-flex my-2" key={index}>
                                    <img src={item.photo && item.photo} width={50}  />
                                    <div className="people-name ml-3">{item.lastName} {item.firstName}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xl-6">
                        <div className="row justify-content-between px-3 my-2">
                            <h4>Top Ventes</h4>
                            <Link to="/achat">Voir tout</Link>
                        </div>
                        <div className="ombre-grey px-3 px-2">
                            {listeTopAchatCategorie && listeTopAchatCategorie.map((item,index)=>
                                <div className="py-3" key={index}>
                                <div className="d-flex align-items-center">
                                    {/* <img src={require('../assets/img/drawable-xxxhdpi/Groupe 31.png')} width={50} height={20} /> */}
                                    <img src={item.categorieImage} width={50} height={20}/>
                                    <div className="people-name ml-3">{item.categorieLibelle}</div>
                                </div>
                                <div className="">
                                    <ProgressBar variant="success" now={item.rendement} />
                                   
                                </div>
                                </div>
                            )} 
                           {/*  <div className="py-3">
                                <div className="d-flex align-items-center">
                                    <img src={require('../assets/img/drawable-xxxhdpi/Groupe 31.png')} width={50} height={20} />
                                    <div className="people-name ml-3">Split mural</div>
                                </div>
                                <div className="">
                                    <ProgressBar variant="success" now={95} />
                                     <div className="people-name">167</div>
                                </div>
                            </div>
                            <div className="py-3">
                                <div className="d-flex align-items-center">
                                    <img src={require('../assets/img/drawable-xxxhdpi/Groupe 32.png')} width={50} height={20} />
                                    <div className="people-name ml-3">Casette</div>
                                </div>
                                <div className="">
                                    <ProgressBar variant="success" now={65} />
                                    {/* <div className="people-name">167</div> 
                                </div>
                            </div>
                            <div className="py-3">
                                <div className="d-flex align-items-center">
                                    <img src={require('../assets/img/drawable-xxxhdpi/Groupe 20.png')} width={50} height={20} />
                                    <div className="people-name ml-3">Gainable</div>
                                </div>
                                <div className="">
                                    <ProgressBar variant="success" now={35} />
                                    {/* <div className="people-name">167</div> *
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}