import React
    // , {useState, useEffect, useRef} 
    from 'react';
import {
    Formik
    , Form, Field, ErrorMessage, FastField
} from 'formik';
import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import moment from 'moment';

// const restClientApi = new ResClientApi();

export class OffresPromotionnel extends React.Component {
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props) {
        super(props);

        this.state = {
            listeOffresPromotionnel: [],
            listeUserType: [],
            listeTypesOffres: [
                {
                    isSouscribe: true,
                    code:1,
                    libelle:'Client souscrit',
                },
                {
                    isSouscribe: false,
                    code:2,
                    libelle:'Client non souscrit',
                }
            ],
            listeTechnicienClient: [],
            modalShow: false,
            assignationModalShow: false,
            selectedLigne: -1,
            detailObjet: null,
            affi: false,
           // shoclien: false,
            page: {
                index: 1,
                size: 10,
            },
            dateStart: moment().format('YYYY-MM-DD'),
            dateEnd: moment().format('YYYY-MM-DD'),
            start: moment().format('DD/MM/YYYY'),
            end: moment().format('DD/MM/YYYY'),
            jour : new Date(),
            jourdate : moment().format('DD/MM/YYYY'),
            formState: {
                id: '',
                libelle: '',
                pourcentagePromo: '',
                dateDebut: moment().format('YYYY-MM-DD'),
                dateFin: moment().format('YYYY-MM-DD'),
                //typeOffre: '',
                // userModeCode: 'OFFRE_ENTRETIEN'
            },
            total: 0,
            search: ''
        }
    }
    debut = '';
    fin = '';
   
    initForm() {
        this.setState({
            formState: {
                id: '',
                libelle: '',
                pourcentagePromo: '',
                dateDebut: moment().format('YYYY-MM-DD'),
                dateFin: moment().format('YYYY-MM-DD'),
                //typeOffre: '',
                // userModeCode: 'OFFRE_ENTRETIEN'
            }
        });
    }

    selectedRow(val, index) {
        //   indexSelected = index
        this.setState({ selectedLigne: index });
        this.setState({ detailObjet: val });
        this.getTechnicienClient(val);
        // this.setState({marker: [val.latitudeClient ? val.latitudeClient : '', val.longitudeClient ? val.longitudeClient : '']});
    }

    goForModif(param) {
        const form = {
            id: param.id,
            libelle: param.libelle,
            pourcentagePromo: param.pourcentagePromo,
            dateDebut: param.dateDebut,
            dateFin: param.dateFin,
        };
       // this.setState({ dateStart:   moment(param.dateDebut).format('YYYY-DD-MM'), dateEnd:moment(param.dateFin).format('YYYY-DD-MM')});
        this.setState({ formState: form });
    }

    getOffresPromotionnel(param?, pageIndex?) {
        const methode = 'offrePromo/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex - 1 : this.state.page.index - 1,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {
                id: "",
                libelle: "",
                pourcentagePromo: ""
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeOffresPromotionnel: res.data.items ? res.data.items : [] });
                    this.setState({ total: res.data.count ? res.data.count : 0 });
                    // this.selectedRow(res.data.items && res.data.items[0], 0)
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    getUserType(param?, pageIndex?) {
        const methode = 'userType/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeUserType: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

   /*  getTypeOffre(param?, pageIndex?) {
        const methode = 'profilTechnicien/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: ''
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeTypesOffres: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    } */
    getTechnicienClient(param?, pageIndex?) {
        const methode = 'interventionTechnicien/getByCriteria'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isSimpleLoading: false,
            data: {
                id: "",
                userId: param.id
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    this.setState({ listeTechnicienClient: res.data.items });
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    createTechnicien(param, resetForm) {
        this.setState({ affi: true })
        const methode = param && param.id ? 'offrePromo/update' : 'offrePromo/create'
        const data = {
          user:
            this.userService.getUserData() && this.userService.getUserData().id,
          isCostomer: false,
          datas: [
            {
              ...param,
              dateDebut: this.debut ? this.debut : this.state.jourdate,
              dateFin: this.fin ? this.fin : this.state.jourdate,
            },
          ],
        };
        console.log('data create', data);
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                console.log('data create', res);
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.setState({ affi: false });
                    this.getOffresPromotionnel();
                    this.initForm();
                    resetForm(this.state.formState);
                    toast.success("Operation effectuer avec succes");
                } else {
                    toast.error(res.data.status.message);
                    this.setState({ affi: false });
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    handlePageChange(ev) {
        const index = ev;
        this.getOffresPromotionnel(ev);
        this.setState({
            page: {
                index: index,
                size: this.state.page.size
            }
        });
    }

    funStartDate(val) {
       
        this.setState({dateStart:moment(val.target.value).format('YYYY-MM-DD')})
        /* this.setState({start:moment(val.target.value).format('DD/MM/YYYY')}) */ 
        this.debut = moment(val.target.value).format('DD/MM/YYYY');
        console.log('start',this.debut);

           /*  getInfoStat(); */
        
        /* value={moment(datedash).format('YYYY-MM-DD')} */
    }
    funEndDate(val) {
        this.setState({dateEnd:moment(val.target.value).format('YYYY-MM-DD')})
        /* this.setState({end:moment(val.target.value).format('DD/MM/YYYY')}) */
        this.fin= moment(val.target.value).format('DD/MM/YYYY');

        console.log('end', this.fin); 

       
        /* setEnd( (moment(val.target.value).format('DD/MM/YYYY')))
         setDateEnd( (moment(val.target.value).format('YYYY-MM-DD')) ) */

         /*   getInfoStat(); */
       
       /* value={moment(datedash).format('YYYY-MM-DD')} */
   }

    searchEvent(param) {

        this.setState({ search: param });
        this.recherche(param);
    }

    async recherche(val) {
        await new Promise((r) => setTimeout(r, 500));
        this.getOffresPromotionnel({ login: val })
    }

    componentDidMount() {
        this.getOffresPromotionnel();
        this.getUserType();
        //this.getTypeOffre();
    }

    delete(val) {
        Swal.fire({
            title: 'Attention',
            text: 'Êtes-vous sûre de vouloir la supprimer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                this.deleteTechnicien(val);
            }
        })
    }

    activeDesactive(val) {
        Swal.fire({
            title: 'Attention',
            text: 'Êtes-vous sûre de vouloir la Activer le code promotionnel?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.value) {
                this.deleteTechnicien(val);
            }
        })
    }
    
     handleSho(param) {
        this.setState({ shoclien: true })
    } 
 /*    handleClos() {
        this.setState({ shoclien: false })

    } */
    deleteTechnicien(param) {
        const methode = 'offrePromo/delete'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: [{
                id: param.id
            }]
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.getOffresPromotionnel();
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }


    render() {
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        return (
            <div className="content intervention mb-5">
                <div className="container px-0">
                    <h2 className="mt-3 text-left">Gestion des offres promotionnelles</h2>
                </div>
                <div className="container mt-4 pt-5 pb-2">
                    <div className="row justify-content-between">
                        <h4 className="mt-3">Liste des offres</h4>
                        <input type="text" placeholder="Rechercher par titre de l'offre"
                            value={this.state.search} onChange={(val) => this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0" />
                    </div>
                </div>
                <div className="container bg-light">
                    <div className="row bg-light">
                        <div className="col-md-8 px-0 bg-light">
                            <div className="tbl">
                                <div className="row-t-head mt-3">
                                    <div className="cell">ID </div>
                                    <div className="cell">libellé </div>
                                    <div className="cell">Poucentage de remis</div>
                                    <div className="cell">Date debut</div>
                                    <div className="cell">Date fin</div>
                                    {/* <div className="cell">Type d'offre</div> */}
                                    <div className="cell"> Option</div>
                                </div>
                                {this.state.listeOffresPromotionnel && this.state.listeOffresPromotionnel.length > 0 ? this.state.listeOffresPromotionnel.map((item, index) =>
                                    <div className="row-t mt-3" key={index}
                                        onClick={() => this.selectedRow(item, index)}
                                        style={{ background: this.state.selectedLigne === index ? '#e3fef3' : '#fff' }}>
                                        <div className="cell">{index + 1}</div>
                                        <div className="cell">{item.libelle}</div>
                                        <div className="cell">{item.pourcentagePromo}</div>
                                        <div className="cell">{item.dateDebut}</div>
                                        <div className="cell">{item.dateFin}</div>
                                        <div className="cell">
                                            {/* <button onClick={() => this.goForModif(item)} className="btn btn-etat mr-1"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                            <button onClick={() => this.activeDesactive(item)} className="btn btn-etat  rounded-0 px-1 py-0"><i className="fa fa-check" aria-hidden="true"></i> <i className="fa fa-times" aria-hidden="true"></i></button> */}
                                            <button onClick={() => this.delete(item)} className="btn btn-outline-danger btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                ) : 'Aucune donnée'}
                            </div>
                           {/*  <Modal show={this.state.shoclien} onHide={() => this.handleClos()} animation={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Historique</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="tbl">
                                        <div className="row-t-head mt-3">
                                            <div className="cell">ID </div>
                                            <div className="cell">Client </div>
                                            <div className="cell">Date intervention</div>
                                        </div>
                                        {this.state.listeTechnicienClient && this.state.listeTechnicienClient.length > 0 ? this.state.listeTechnicienClient.map((item, index) =>
                                            <div className="row-t mt-3" key={index} >
                                                <div className="cell">{index + 1}</div>
                                                <div className="cell">{item.nomClient} {item.prenomClient}</div>
                                                <div className="cell">{item.dateIntervention}</div>
                                            </div>
                                        ) : 'Aucune donnée'}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.handleClos(false)}>
                                        Fermer
                                    </Button>
                                </Modal.Footer>
                            </Modal> */}
                            <div className="w-100 mt-2">
                                {this.state.listeOffresPromotionnel && this.state.listeOffresPromotionnel.length > 0 ?
                                    <Pagination innerClass="pagination pull-right"
                                        activePage={this.state.page.index}
                                        itemsCountPerPage={this.state.page.size}
                                        totalItemsCount={this.state.total}
                                        pageRangeDisplayed={5}
                                        onChange={(event) => this.handlePageChange(event)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    /> : ''}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-shadow p-3 mt-5">
                                <div className="top-card text-left text-green">
                                    <h5 className="font-weight-bold">Offre promotionnelle</h5>
                                </div>
                                <hr />
                                <div className="form">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={this.state.formState}
                                        validationSchema={Yup.object().shape({
                                            pourcentagePromo: Yup.string()
                                                .required('Champs requis'),
                                            libelle: Yup.string()
                                                .required('Champs requis'),
                                            isSouscribe: Yup.string()
                                                .required('Champs requis'),
                                            dateDebut: Yup.date().default(function () {
                                                return new Date();
                                              }),
                                              dateFin: Yup.date().default(function () {
                                                return new Date();
                                              }),
                                           
                                             /*   code: Yup.string()
                                                .required('Champs requis'),
                                         telephone: Yup.string()
                                                .matches(phoneRegExp, 'le numero doit comporter au moins 8 chiffres')
                                                .required('Champs requis'), */
                                        })}
                                        onReset={(value, { setSubmitting, resetForm }) => {
                                            //resetForm();
                                            //this.initForm();
                                        }}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            this.createTechnicien(values, resetForm);
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            resetForm
                                        }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="text"
                                                            name="libelle"
                                                            placeholder="Libelle"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.libelle}
                                                        />
                                                        {errors.libelle && touched.libelle &&
                                                            <div className="text-danger">{errors.libelle}</div>}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <input
                                                            type="number"
                                                            name="pourcentagePromo"
                                                            placeholder="Pourcentage promo"
                                                            className="form-control rounded-0"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.pourcentagePromo}
                                                        />
                                                        {errors.pourcentagePromo && touched.pourcentagePromo &&
                                                            <div className="text-danger">{errors.pourcentagePromo}</div>}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        <label className="text-left w-100" htmlFor="isSouscribe">Choisir le type d'offre</label>
                                                        {/* <Field id="firstName" name="firstName" placeholder="Jane" className="form-control"/> */}
                                                        <Field name="isSouscribe" as="select" className="form-control rounded-0 border border-dark" id="isSouscribe">
                                                            <option>Veuillez choisir le type d'offre</option>
                                                            {this.state.listeTypesOffres?.map((item, index) => (
                                                                <option key={index} value={item.isSouscribe}>{item.libelle}</option>
                                                            ))}
                                                            {/* <option value="blue">Blue</option>*/}
                                                        </Field>
                                                    </div>
                                                   <div className="form-group mt-3">
                                                   <label className="text-left w-100" htmlFor="dateDebut">Date Debut</label>
                                                        <input
                                                            type="date"
                                                            name="dateDebut"
                                                            placeholder="date debut"
                                                            className="form-control rounded-0"
                                                            onChange={(val) => this.funStartDate(val)}
                                                            onBlur={handleBlur}
                                                            value={this.state.dateStart}
                                                        />
                                                        {/* {errors.dateDebut && touched.dateDebut &&
                                                            <div className="text-danger">{errors.dateDebut}</div>} */}
                                                    </div>
                                                     <div className="form-group mt-3">
                                                   <label className="text-left w-100" htmlFor="dateFin">Date fin</label>
                                                        <input
                                                            type="date"
                                                            name="dateFin"
                                                            placeholder="date fin"
                                                            className="form-control rounded-0"
                                                            onChange={(val) => this.funEndDate(val)}//handleChange
                                                            onBlur={handleBlur}
                                                            value={this.state.dateEnd}
                                                        />
                                                      {/*   {errors.dateFin && touched.dateFin &&
                                                            <div className="text-danger">{errors.dateFin}</div>}*/}
                                                    </div> 
                                                    <div className="d-flex justify-content-between">
                                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                                        >Annuler</button>
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={50}
                                                            width={50}
                                                            visible={this.state.affi}
                                                        />
                                                        <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                                        >Valider</button>
                                                    </div>
                                                </form>
                                            )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}