import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import '../styles/intervention.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

export class ClientNotif extends React.Component {
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props) {
        super(props);
        this.state = {
            listeTypesOffres: [
                {
                    isSouscribe: true,
                    code: 1,
                    libelle: 'Client souscrit',
                },
                {
                    isSouscribe: false,
                    code: 2,
                    libelle: 'Client non souscrit',
                }
            ],
            affi: false,
            formState: {
                id: '',
                message: '',
            },
            search: ''
        }
    }

    initForm() {
        this.setState({
            formState: {
                id: '',
                message: '',
            }
        });
    }

    createNotif(param, resetForm) {
        this.setState({ affi: true })
        const methode = 'client/notificationClient'
        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            data: { ...param },
        };
        console.log('data notificationClient', data);
        this.restClientApi.executePostApi(methode, data)
            .then((res) => {
                console.log('data create', res);
                if (res && res.data && !res.data.hasError) {
                    toast.success(res.data.status.message);
                    this.setState({ affi: false });
                    this.initForm();
                    resetForm(this.state.formState);
                } else {
                    toast.error(res.data.status.message);
                    this.setState({ affi: false });
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    render() {
        return (
            <div className="content intervention mb-5">
                <div className="container px-0">
                    <h2 className="mt-3 text-left">Message de notification</h2>
                </div>
               
                <div className="container bg-light">
                    <div className="row bg-light">
                        <div className="col-md-6">
                            <div className="card card-shadow p-3 mt-5">
                                <div className="top-card text-left text-green">
                                    <h5 className="font-weight-bold">Notification client</h5>
                                </div>
                                <hr />
                                <div className="form">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={this.state.formState}
                                        validationSchema={Yup.object().shape({
                                            message: Yup.string()
                                                .required('Champs requis'),
                                            isSouscribe: Yup.string()
                                                .required('Champs requis'),
                                        })}
                                        onSubmit={(values, { resetForm }) => {
                                            this.createNotif(values, resetForm);
                                        }}
                                    >
                                        {({
                                            values,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            resetForm,
                                        }) => (
                                            <form onSubmit={handleSubmit}>

                                                <div className="form-group mt-3">
                                                    <label className="text-left w-100" htmlFor="isSouscribe">Choisir le type d'offre</label>
                                                    <Field name="isSouscribe" as="select" className="form-control rounded-0 border border-dark" id="isSouscribe">
                                                        <option>Veuillez choisir le type d'offre</option>
                                                        {this.state.listeTypesOffres?.map((item, index) => (
                                                            <option key={index} value={item.isSouscribe}>{item.libelle}</option>
                                                        ))}
                                                    </Field>
                                                </div>
                                                <div className="form-group">
                                                    <label className="text-left w-100" htmlFor="lastName">Message</label>
                                                  <textarea id="message" value={values.message} onChange={handleChange}
                                                         name="message" placeholder="message" className="form-control rounded-0 border border-dark"
                                                        rows="5"></textarea>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                                    >Annuler</button>
                                                    <Loader
                                                        type="Puff"
                                                        color="#00BFFF"
                                                        height={50}
                                                        width={50}
                                                        visible={this.state.affi}
                                                    />
                                                    <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                                    >Valider</button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}