import React
// , {useState, useEffect, useRef} 
from 'react';
import { Formik
    // , Form, Field, ErrorMessage, FastField
 } from 'formik';
// import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import '../styles/produits.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import {toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
// const restClientApi = new ResClientApi();

export class TypeProduits extends React.Component{
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props){
        super(props);
        
        this.state = {
            listeProduitType: [],
            // modalShow: false,
            // assignationModalShow: false,
            selectedLigne: -1,
            affi: false,
            detailObjet: null,
            page: {
                index: 1,
                size: 10,
            },
            total: 0,
            search: '',
            formState: {
                id: '',
                libelle: '',
                code: '',
            }
        }
    }

    initForm(){
        this.setState({formState: {
            id: '',
            code: '',
            libelle: '',
        }});
    }

    goForModif(param){
        const form = {
            id: param.id,
            libelle: param.libelle,
            code: param.code,
        };
        this.setState({formState: form});
      }

       selectedRow(val, index){
            // indexSelected = index
            this.setState({selectedLigne:index});
            this.setState({detailObjet:val});
      }

        searchEvent(param){
            
            this.setState({search: param});
            this.recherche(param);
        }

        async recherche(val){
            await new Promise((r) => setTimeout(r, 500));
            this.getTypeProduit({libelle: val})
        }

       getTypeProduit(param?, pageIndex?){
            const methode = 'typeProduit/getByCriteria'
            const data = {
                index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
                size: this.state.page.size,
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isCostomer: false,
                data: {
                    // login: param && param.login ? param.login : '',
                    libelle: param && param.libelle ? param.libelle : '',
                }
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        this.setState({listeProduitType: res.data.items ? res.data.items : []});
                        // this.setState({listeProduitType: res.data.items});
                        this.setState({total: res.data.count ? res.data.count : 0});
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error(err);
                });
        }

       createProduits(param, resetForm){
        this.setState({affi:true});
            const methode = param && param.id ? 'typeProduit/update' :'typeProduit/create';
            const data = {
                user: this.userService.getUserData() && this.userService.getUserData().id,
                datas: [param]
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        toast.success("Operation effectuer avec succes");
                        this.setState({affi:false})
                        this.getTypeProduit();
                        this.initForm();
                        resetForm(this.state.formState);
                    } else {
                        toast.error("Operation echoué");
                        this.setState({affi:false})
                    }
                })
                .catch((err)=>{
                    toast.error('Erreur '+ err);
                });
        }

         handlePageChange(ev){
            const index = ev;
            this.getTypeProduit(ev);
            this.setState({page: {
                index: index,
                size: this.state.page.size
            }});
        }

        componentDidMount(){
            this.getTypeProduit();
            this.getTypeProduit();
        }

        delete(val){
            Swal.fire({
                title: 'Attention',
                text: 'Êtes-vous sûre de vouloir la supprimer?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33', 
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
             }).then((result) => {
                if(result.value){
                 this.deleteProduits(val);
               }
             })
        }

        deleteProduits(param){
            const methode = 'typeProduit/delete'
            const data = {
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isCostomer: false,
                datas: [{
                    id: param.id
                }]
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        toast.success(res.data.status.message);
                        this.getTypeProduit();
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error('Erreur '+ err);
                });
            }

 
    render (){
    return(
        <div className="content intervention mb-5">
            <div className="container px-0">
                <h2 className="mt-3 text-left">Gestion des Types de Produits</h2>
            </div>
            <div className="container mt-4 pt-5 pb-2">
                <div className="row justify-content-between">
                    <h4 className="mt-3">Nouveau type produits</h4>
                    <input type="text" placeholder="Recherche un type de produit"
                     value={this.state.search} onChange={(val)=>this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0"/>
                </div>
            </div>
            <div className="container bg-light">
               <div className="row bg-light">
                   <div className="col-md-8 px-0 bg-light">
                   <div className="tbl">
                        <div className="row-t-head mt-3">
                            <div className="cell">ID </div>
                            <div className="cell">Code </div>
                            <div className="cell">Type produit </div>
                            <div className="cell"> Option</div>
                        </div>
                        {this.state.listeProduitType && this.state.listeProduitType.length>0 ? this.state.listeProduitType.map((item, index)=>
                            <div className="row-t mt-3" key={index} 
                                onClick={()=>this.selectedRow(item, index)} 
                                style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                                <div className="cell">{index+1}</div>
                                <div className="cell">{item.code}</div>
                                <div className="cell">{item.libelle}</div>
                                <div className="cell">
                                    <button onClick={()=>this.goForModif(item)} className=" mr-1"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                    <button onClick={()=>this.delete(item)} className="btn btn-outline-danger btn-sm"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        ) : 'Aucune donnée'}
                    </div>
                    <div className="w-100 mt-2">
                       {this.state.listeProduitType && this.state.listeProduitType.length>0 ? 
                        <Pagination innerClass="pagination pull-right"
                        activePage={this.state.page.index}
                        itemsCountPerPage={this.state.page.size}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={5}
                        onChange={(event)=>this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                        /> : ''}
                    </div>
                   </div>
                   <div className="col-md-4">
                        <div className="card card-shadow p-3 mt-5">
                            <div className="top-card text-left text-green">
                                <h5 className="font-weight-bold">Produits</h5>
                                <div className="font-weight-bold my-2">{this.state.detailObjet?.designation}</div>
                                {/* <div className="text-right">Id: {this.state.detailObjet?.id}</div> */}
                                {this.state.detailObjet ? <div className="option-detail">
                                    <div className="my-2"> 
                                        <i className="fa fa-clock-o fa-14" aria-hidden="true"></i>
                                        <span className="ml-3">{this.state.detailObjet?.libelle}</span>
                                    </div>
                                </div> : ''}
                            </div>
                            <hr/>
                            <div className="form">
                            <Formik
                                enableReinitialize={true}
                                initialValues={this.state.formState}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    this.createProduits(values, resetForm);
                                }}
                                >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    resetForm
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mt-3">
                                            <label htmlFor="code">Code</label>
                                            <input
                                                type="text"
                                                name="code"
                                                id="code"
                                                placeholder="Code"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.code}
                                            />
                                            {errors.code && touched.code && errors.code}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="libelle">Libellé</label>
                                            <input
                                                type="text"
                                                name="libelle"
                                                id="libelle"
                                                placeholder="Libellé"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.libelle}
                                            />
                                            {errors.libelle && touched.libelle && errors.libelle}
                                        </div>
                                    <div className="d-flex justify-content-between">
                                        <button onClick={() => resetForm(this.state.formState)} type="reset" className="btn btn-danger btn-text rounded-0"
                                        >Annuler</button>
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}                                        
                                            visible={this.state.affi}
                                        />
                                        <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                        >Valider</button>
                                    </div>
                                    </form>
                                )}
                                </Formik>
                            </div>
                        </div>
                   </div>
               </div>
            </div>
        </div>
    )}
}