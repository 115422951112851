import React, {useState, useEffect, useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Map, Polyline, TileLayer, Marker, Popup } from 'react-leaflet';
import Badge from 'react-bootstrap/Badge'
import '../styles/intervention.scss';
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Select from 'react-select'
// import Pagination from "react-js-pagination";
import { Formik, Form, Field
    // , ErrorMessage, FastField, FormikProps
 } from 'formik';
// import * as Yup from 'yup';
import DatePicker, { registerLocale
    // , setDefaultLocale 
} from "react-datepicker";
// import  from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/intervention.scss';
import * as moment from 'moment';
import subDays from "date-fns/subDays";
import {toast} from 'react-toastify';
registerLocale('fr', fr);

const restClientApi = new ResClientApi();
const userService = new UserService();
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const MapingModal = (props) => {

    // const [techniciens, settechniciens] = useState(props.techniciens);
    const mapRefModa = useRef(null);
    let timer = null;
    const [listeInterv, setListeInter] = useState();
    const [position, setPosition] = useState({
        zoom: 13,
    });
   
    
    useEffect(() => {
        //handleCl()
        // settechniciens(props.techniciens)
        // getTechnicien();
    }, []);
        
       if (props.activinterval) {
         timer = setTimeout(function() {
            mapRefModa.current.leafletElement.invalidateSize()
            }, 2000)   
       }else{
           clearTimeout(timer)
       }
       let posit = props.positions
            


    function handleChange(i) { 
        setListeInter(i.value)
      };

    function handleSubmit(){
    }

    return (
        <Modal
            {...props}
            backdrop="static"
            size="lg"
            animation={true}
            keyboard={false}
            className="custom-modal-style">
            <Modal.Header closeButton className="border-0 pb-0">
                <Modal.Title className="text-green">Position du technicien</Modal.Title>
            </Modal.Header>
            <Modal.Body >
            <div className="form">
            <Map
            ref={mapRefModa}
            center={props.marker} bounceAtZoomLimits={true}
             zoom={position.zoom} animate={true}>
                <TileLayer
                noWrap={true}
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                 <Polyline pathOptions={props.line} positions={props.positions} /> 
                {
                props.listeTechniciens && props.listeTechniciens.map((item, index)=>
                    <Marker position={[item?.latitude || 0, item?.longitude ||0]} key={index} icon={props.customMarkerIcon}>
                        <Popup className="rounded-0">
                            <div className="">
                                <div className="border technicien-info p-1">
                                   <div className="text-normal">Client: <span className="font-weight-bold">
                                       {item.firstName}
                                       {item?.lastName} 
                                       </span>
                                    </div>
                                   <div className="text-normal">Téléphone: <span className="font-weight-bold">{item.telephone}</span></div>
                                   <div className="text-normal">Temps: <span className="font-weight-bold">{item.temps}</span></div>
                                   <div className="text-normal">equipements en charge: <span className="font-weight-bold">{item.nbreEquipement}</span></div>
                                </div>
                                
                            </div>   
                        </Popup>
                    </Marker> 
                )}
                {
                props.marker && props.marker.length>0 ? 
                    <Marker position={props.marker}>
                    </Marker> : ''
                }
            </Map>
            </div>        
            </Modal.Body>
            <Modal.Footer>
                <Button type="reset" className="btn btn-outline-default btn-sm btn-text rounded-0 px-4" onClick={() =>props.onHide(false)}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MapingModal;