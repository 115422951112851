import React from 'react'
import axios from 'axios';

// export default class ResClientApi extends React.Component{
export default class ResClientApi{
    
    // baseUrl = ' https://maintenance-api.clients.smile.ci/maintenance-api/';
    baseUrl = window.location.origin + '/api/mct-api/';
    constructor(){
        if(process.env.NODE_ENV === 'development'){
            this.baseUrl = window.location.origin + '/api/mct-api/' //Prod
            // this.baseUrl = ' https://smart-maintenance.mct.ci/api/maintenance-api/'

        } else {
            this.baseUrl = window.location.origin + '/api/mct-api/' //Prod
            // this.baseUrl = ' https://maintenance-api.clients.smile.ci/maintenance-api/'
        }
    }
    //maintenance-api.clients.smile.ci/maintenance-api
    // https://smart-maintenance.mct.ci/api/maintenance-api

    executePostApi(methode, data){
       return axios.post(this.baseUrl+methode, data)}

    executeGetApi(methode){
        return axios.get(this.baseUrl + methode);
    }

}
