import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
} from "react-router-dom";
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif';
import '../styles/sidebar.scss';
import 'font-awesome/css/font-awesome.min.css';
import UserService from '../services/user-service';

const userService = new UserService();
export default function Sidebard() {
    let history = useHistory();
    function deconnexion() {
        userService.removeUserData();
        history.push('/auth');
    }
    const [drop, setDrop] = useState(false)


    useEffect(() => {
        // abortUser();
    })

    return (

        <div className="column">
            <div className="my-4 text-center">
                <img
                    // src={require('../assets/img/jack_c.png')} 
                    src={require('../assets/img/@2x/Groupe 8@2x.png')}
                    className="d-inline-block align-middle w-20" />
                <div className="mt-1">
                    {userService.getUserData() ? <span className="">{ userService.getUserData().firstName } { userService.getUserData().lastName}</span> : history.push('/auth')}
                </div>
                <div>
                    <button onClick={() => deconnexion()} className="btn btn-outline-danger rounded-pill btn-sm">Déconnexion</button>

                </div>
                <div>
                    <TheHeaderDropdownNotif />
                </div>  

            </div>

            <div className="side-menu">
                <ul className="liste-items pl-3">
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/dashboard_active@2x.png')} className="icon-size-bar" />
                        <Link to="/">
                            Dashboard
                        </Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/entretien_active@2x.png')} className="icon-size-bar" />
                        <Link to="/entretiens">Entretiens</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/technicien_active@2x.png')} className="icon-size-bar" />
                        <Link to="/techniciens">
                            Techniciens
                        </Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/interventin_active@2x.png')} className="icon-size-bar" />
                        <Link to="/interventions">Dépannages</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/demande_devis_active@2x.png')} className="icon-size-bar" />
                        <Link to="/demande-devis">Demandes et devis</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/icons8-deux-sacs-de-course-60.png')} className="icon-size-bar" />
                        <Link to="/achat">Achat climatiseur</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/baseline_cached_black_18dp.png')} className="icon-size-bar" />
                        <Link to="/reclamation">Réclamtions</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/icons8-deux-sacs-de-course-60.png')} className="icon-size-bar" />
                        <Link to="/bd-commande">BD Commande</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/icons8-deux-sacs-de-course-60.png')} className="icon-size-bar" />
                        <Link to="/offres-promotionnel">Offre Promotionnel</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/icons8-deux-sacs-de-course-60.png')} className="icon-size-bar" />
                        <Link to="/client-notif">Notification</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/icons8-deux-sacs-de-course-60.png')} className="icon-size-bar" />
                        <Link to="/code-promo">Gestion Codes</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/baseline_cached_black_18dp.png')} className="icon-size-bar" />
                        <Link to="/gestion-techn">Gestion Techn</Link>
                    </li>
                    <li className="item-menu py-2">
                        <img src={require('../assets/img/@2x/parametres_active@2x.png')} className="icon-size-bar" />
                      
                        <button onClick={() => setDrop(!drop)} className="dropdown-btn px-0">Paramètre
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div style={{ display: drop ? 'block' : 'none' }} className="dropdown-container">
                            <Link to="/devis" className="dropdown">Dévis</Link>
                            <Link to="/marques" className="dropdown">Marque</Link>
                            <Link to="/puissance" className="dropdown">Puissance</Link>
                            <Link to="/model" className="dropdown">Model</Link>
                            <Link to="/categorie" className="dropdown">Catégories</Link>
                            {/* <Link to="/produits" className="dropdown">Produits</Link> */}
                            <Link to="/prix-produits" className="dropdown">Produits</Link>
                            {/* <Link to="/type-produits" className="dropdown">Type produits</Link> */}
                            <Link to="/users" className="dropdown">Utilisateur</Link>
                        </div>
                    </li>
                </ul>
            </div>
           
        </div>
    )
}