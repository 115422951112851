import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Select from 'react-select'
// import Pagination from "react-js-pagination";
import {
    Formik, Form, Field
    // , ErrorMessage, FastField, FormikProps
} from 'formik';
// import * as Yup from 'yup';
import DatePicker, {
    registerLocale
    // , setDefaultLocale 
} from "react-datepicker";
// import  from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/intervention.scss';
import * as moment from 'moment';
import subDays from "date-fns/subDays";
import { toast } from 'react-toastify';
registerLocale('fr', fr);

const restClientApi = new ResClientApi();
const userService = new UserService();
const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const ModifReclamModal = (props) => {

    // const [techniciens, settechniciens] = useState(props.techniciens);
    const [startDate, setStartDate] = useState(new Date());
    /* const [listeInterv, setListeInter] = useState([{
        libelle: '',
        userId: '',
        statutId: '',
        description: '',
    }]);
    const initialValues = () => {
        setListeInter([{
            libelle: '',
            userId: '',
            statutId: '',
            description: '',
        }])
    } */
    useEffect(() => {
        // settechniciens(props.techniciens)
        // getTechnicien();
    }, []);


    function updateReclamation(param?) {
        const methode = props.data && props.data.id && 'reclamation/update'
        const data = {
            user: userService.getUserData() && userService.getUserData().id,
            datas: [{ ...param, id: props.data.id }]
        };
        restClientApi.executePostApi(methode, data)
            .then((res) => {
                if (res && res.data && !res.data.hasError) {
                    toast.success("Operation effectuer avec succes");
                    //initialValues();
                    props.onHide(false);
                } else {
                    toast.error(res.data.status.message);
                }
            })
            .catch((err) => {
                toast.error('Erreur ' + err);
            });
    }

    return (
        <Modal
            {...props}
            backdrop="static"
            size="md"
            animation={true}
            keyboard={false}>
            <Modal.Header closeButton className="border-0 pb-0">
                <Modal.Title className="text-green">Modification de reclamation</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Formik
                    initialValues={{
                        //libelle: props.data? props.data.libelle : "",
                        statutId:  props.data? props.data.statutId :"",
                        //description:  props.data? props.data.description :"",
                        commantaire: props.data? props.data.commantaire :"",
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        await sleep(500);
                        //values.dateIntervention = moment(startDate).format('DD/MM/YYYY');
                        //console.log('value',values)
                        updateReclamation(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        resetForm
                    }) => (

                            <Form onSubmit={handleSubmit}>
                               {/*  <div className="form-group mt-3"> */}
                                   {/*  <label htmlFor="userId">Status</label> */}
                                    {/*  <Field as="select" name="statutId" className="form-control rounded-0"> 
                                    <option>Veuillez choisir le status </option>
                                        {props.techniciens && props.techniciens.map((item, index) => <option key={index} value={item.id}>
                                        {item.libelleStatut}
                                    </option>                                    
                                    )}
                                </Field> */}
                                    { /*   <Select
                                    isClearable
                                    name="userId"
                                    onChange={handleChange}
                                    options={props.techniciens.map((item,index) => {
                                        return {value: item.id , label:  <div>{item.firstName} {item.lastName} <Badge variant="warning">{item.nbreEquipement}</Badge></div> }
                                    })}
                                /> */}

                                {/* </div> */}
                                <div className="form-group mt-3">
                                    <label htmlFor="statutId">Statut</label>
                                    <select name="statutId" id="statutId"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.statutId}
                                        className="form-control rounded-0">
                                        <option>Selectionnez le status</option>
                                        {props.statutReclamation && props.statutReclamation.map((item, index) =>
                                            <option key={index} value={item.id}>{item.libelle}</option>
                                        )}
                                    </select> 
                                </div>
                                <div className="form-group">
                                    <label htmlFor="commantaire">Commentaire</label>
                                    <textarea
                                        type="text"
                                        name="commantaire"
                                        id="commantaire"
                                        placeholder="commantaire"
                                        className="form-control rounded-0"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.commantaire}
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label className="text-left w-100" htmlFor="description">Commentaire</label>
                                    <Field component={() => <textarea id="description" name="description" placeholder="Saisir le commentaire svp" className="form-control rounded-0 border border-dark"
                                        rows="5"> </textarea>}></Field>
                                        </div> */}

                                {/* <div className="form-group mt-3 myPicker">
                                <label htmlFor="nbreEquipement">Date d'intervention</label>
                                <DatePicker selected={startDate} className="form-control rounded-0 w-full" minDate={subDays(new Date(), 5)}
                                    dateFormat="dd/MM/yyyy" name="dateIntervention" onChange={date => setStartDate(date)} />
                            </div> */}

                                <div className="d-flex border-0 pt-0 justify-content-between mt-3">
                                    <Button type="reset" className="btn btn-outline-default btn-sm btn-text rounded-0 px-4"
                                        onClick={() => props.onHide(false)}>Fermer</Button>
                                    <button type="submit" className="btn btn-outline-success btn-sm btn-text rounded-0 px-4">
                                        Enregistrer
                                </button>
                                </div>
                            </Form>
                        )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default ModifReclamModal;