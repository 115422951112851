import React
// , {useState, useEffect, useRef} 
from 'react';
import { Formik
    // , Form, Field, ErrorMessage, FastField
 } from 'formik';
import * as Yup from 'yup';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import '../styles/intervention.scss';
import '../styles/produits.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'leaflet/dist/leaflet.css';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
import Pagination from "react-js-pagination";
import {toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
// const restClientApi = new ResClientApi();

export class Produits extends React.Component{
    restClientApi = new ResClientApi();
    userService = new UserService();
    constructor(props){
        super(props);
        
        this.state = {
            listeProduits: [],
            listeProduitType: [],
            listeCategorieType: [],
            selectedLigne: -1,
            detailObjet: null,
            affi: false,
            page: {
                index: 1,
                size: 10,
            },
            total: 0,
            search: '',
            formState: {
                id: null,
                libelle: '',
                typeProduitId: '',
                categorieId: '',
                prixUnitaire: 0,
                prixUnitaire: '',
                file: '',
            },
            fileName: 'Choisir une image du produit',
            datasDocuments: [
                {
                    nomFichier: '',
                    fichierBase64: '',
                    extension: ''
                }
            ],
            
        }
    }

    initForm(){
         this.setState({formState: {
             id: '',
             libelle: '',
             typeProduitId: '',
             categorieId: '',
             prixUnitaire: 0,
             file: '',
         }});
    }

    goForModif(param){
        const form = {
            id: param.id,
            libelle: param.libelle,
            typeProduitId: param.typeProduitId,
            prixUnitaire: param.prixUnitaire,
            file: '',
        };
        this.setState({formState: form});
      }

       selectedRow(val, index){
            // indexSelected = index
            this.setState({selectedLigne:index});
            this.setState({detailObjet:val});
      }

      searchEvent(param){
            
        this.setState({search: param});
        this.recherche(param);
    }

    async recherche(val){
        await new Promise((r) => setTimeout(r, 500));
        this.getProduits({libelle: val})
    }

       getProduits(param?, pageIndex?){
        const methode = 'produit/getByCriteria'
        const data = {
            index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
            size: this.state.page.size,
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            data: {
                libelle: param && param.libelle ? param.libelle : '',
                typeProduitId: param && param.typeProduitId ? param.typeProduitId : '',
                prixUnitaire: param && param.prixUnitaire ? param.prixUnitaire : '',
            }
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res)=>{
                if(res && res.data && !res.data.hasError){
                    this.setState({listeProduits: res.data.items ? res.data.items : []});
                    this.setState({total: res.data.count ? res.data.count : 0});
                } else {
                    toast.error(res.status.message);
                }
            })
            .catch((err)=>{
                toast.error(err);
            });
        }

       getTypeProduit(param, pageIndex){
            const methode = 'typeProduit/getByCriteria'
            const data = {
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isCostomer: false,
                data: {
                }
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        this.setState({listeProduitType: res.data.items});
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error(err);
                });
        }

        getCategories(param, pageIndex){
            const methode = 'categorieProduit/getByCriteria'
            const data = {
                //index: pageIndex ? pageIndex-1 : this.state.page.index-1 ,
                //size: this.state.page.size,
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isSimpleLoading: false,
                data: {
                    Id: '',
                    libelle: '',
                }
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        this.setState({listeCategorieType: res.data.items ? res.data.items : []});
                        //this.setState({total: res.data.count ? res.data.count : 0});
                    } else {
                        toast.error(res.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error(err);
                });
            }

       createProduits(param, resetForm){
        this.setState({affi:true})
        const methode = param && param.id ? 'produit/update' :'produit/create';
        let request;
        if(param.id && this.state.datasDocuments && this.state.datasDocuments[0] && this.state.datasDocuments[0].nomFichier !== ''){
            request = [{...param, datasDocuments: this.state.datasDocuments}];
        } else {
            request = [param]
        } 

        const data = {
            user: this.userService.getUserData() && this.userService.getUserData().id,
            isCostomer: false,
            datas: request
        };
        this.restClientApi.executePostApi(methode, data)
            .then((res)=>{
                if(res && res.data && !res.data.hasError){
                    toast.success("Operation effectuer avec succes");
                    this.setState({affi:false});
                    this.getProduits();
                    resetForm(this.state.formState); 
                    this.setState({
                        fileName: 'Choisir une image du produit'
                    });
                } else {
                    toast.error("Operation echouée: " + res.data.status.message);
                    this.setState({affi:false});
                }
            })
            .catch((err)=>{
                toast.error('Erreur '+ err);
            });
        }

         handlePageChange(ev){
            const index = ev;
            this.getProduits(this.state.search, ev);
            this.setState({page: {
                index: index,
                size: this.state.page.size
            }});
        }

        componentDidMount(){
            this.getProduits();
            this.getTypeProduit();
            this.getCategories();
        }

        delete(val){
            Swal.fire({
                title: 'Attention',
                text: 'Êtes-vous sûre de vouloir la supprimer?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33', 
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
             }).then((result) => {
                if(result.value){
                 this.deleteProduits(val);
               }
             })
        }

        deleteProduits(param){
            const methode = 'produit/delete'
            const data = {
                user: this.userService.getUserData() && this.userService.getUserData().id,
                isCostomer: false,
                datas: [{
                    id: param.id
                }]
            };
            this.restClientApi.executePostApi(methode, data)
                .then((res)=>{
                    if(res && res.data && !res.data.hasError){
                        toast.success(res.data.status.message);
                        this.getProduits();
                    } else {
                        toast.error(res.data.status.message);
                    }
                })
                .catch((err)=>{
                    toast.error('Erreur '+ err);
                });
            }

            processFile(event, doc?) {
                const reader = new FileReader();
                const file = event.target.files[0];
                let fileData;
                reader.onload = (readerEvent) => {
                  const data = (readerEvent.target).result;
                //   this.fileName = file.name;
                  this.setState({
                      fileName: file.name
                  })
                  fileData = {
                        nomFichier: file.name.split('.')[0],
                        extension: file.name.split('.')[file.name.split('.').length-1],
                        fichierBase64: data.split(',')[1]
                    };
                    this.setState({datasDocuments: [fileData]});
                //   this.setFileData(fileData);
                //   event.stopPropagation();
                };
                reader.readAsDataURL(event.target.files[0]);
                event.preventDefault();
              }

 
    render (){
    return(
        <div className="content intervention mb-5">
            <div className="container px-0">
                <h2 className="mt-3 text-left">Gestion des Produits</h2>
            </div>
            <div className="container mt-4 pt-5 pb-2">
                <div className="row justify-content-between">
                    <h4 className="mt-3">Nouveau Produits</h4>
                    <input type="text" placeholder="Rechercher par libellé"
                     value={this.state.search} onChange={(val)=>this.searchEvent(val.target.value)} className="form-control search-input w-25 rounded-0"/>
                </div>
            </div>
            <div className="container bg-light">
               <div className="row bg-light">
                   <div className="col-md-8 px-0 bg-light">
                   <div className="tbl">
                        <div className="row-t-head mt-3">
                            <div className="cell">ID </div>
                            <div className="cell">Produit </div>
                            <div className="cell">Type Produit</div>
                            <div className="cell">Catégorie produit</div>
                            <div className="cell">prix Unitaire</div>
                            <div className="cell"> Option</div>
                        </div>
                        {this.state.listeProduits && this.state.listeProduits.length>0 ? this.state.listeProduits.map((item, index)=>
                            <div className="row-t mt-3" key={index} 
                                onClick={()=>this.selectedRow(item, index)} 
                                style={{background: this.state.selectedLigne === index ? '#e3fef3': '#fff'}}>
                                <div className="cell">{index+1}</div>
                                <div className="cell">{item.libelle}</div>
                                <div className="cell">{item.typeProduitLibelle}</div>
                                <div className="cell">{item.categorieProduitLibelle}</div>
                                <div className="cell">{item.prixUnitaire}</div>
                                <div className="cell">
                                    <button onClick={()=>this.goForModif(item)} className="btn btn-etat mr-1">Modifier</button>
                                    <button onClick={()=>this.delete(item)} className="btn btn-outline-danger btn-sm">Supprimer</button>
                                </div>
                            </div>
                        ) : 'Aucune donnée'}
                    </div>
                    <div className="w-100 mt-2">
                       {this.state.listeProduits && this.state.listeProduits.length>0 ? 
                        <Pagination innerClass="pagination pull-right"
                        activePage={this.state.page.index}
                        itemsCountPerPage={this.state.page.size}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={5}
                        onChange={(event)=>this.handlePageChange(event)}
                        itemClass="page-item"
                        linkClass="page-link"
                        /> : ''}
                    </div>
                   </div>
                   <div className="col-md-4">
                        <div className="card card-shadow p-3 mt-5">
                            <div className="top-card text-left text-green">
                                <h5 className="font-weight-bold">Produits</h5>
                                <div className="font-weight-bold my-2">{this.state.detailObjet?.designation}</div>
                                {/* <div className="text-right">Id: {this.state.detailObjet?.id}</div> */}
                                {this.state.detailObjet ? 
                                <><div className="option-detail">
                                    <div className="my-2"> 
                                        <i className="fa fa-clock-o fa-14" aria-hidden="true"></i>
                                        <span className="ml-3">{this.state.detailObjet?.libelle}</span>
                                    </div>
                                    <div className="my-2"> 
                                        <i className="fa fa-map-marker fa-14" aria-hidden="true"></i>
                                        <span className="ml-3">{this.state.detailObjet?.typeProduitLibelle}</span>
                                    </div>
                                    <div className="my-2"> 
                                        <i className="fa fa-money fa-14" aria-hidden="true"></i>
                                        <span className="ml-3">{this.state.detailObjet?.prixUnitaire}</span>
                                    </div>
                                   
                                </div>
                                <div className="my-2"> 
                                    <img src={this.state.detailObjet?.datasDocuments && this.state.detailObjet?.datasDocuments[0].libelle}  className="w-100"/> 
                                </div></>
                              : ''}
                            </div>
                            <hr/>
                            <div className="form">
                            <Formik
                                enableReinitialize={true}
                                initialValues={this.state.formState}
                                onSubmit={(values, { resetForm }) => {
                                    this.createProduits(values, resetForm);
                                }}
                                validationSchema={Yup.object().shape({
                                    prixUnitaire: Yup.number()
                                      .min(1, 'valeur minimum est 1')
                                      .required('Champs requis'),
                                    libelle: Yup.string()
                                      .required('Champs requis'),
                                    typeProduitId: Yup.string()
                                      .required('Champs requis'),
                                    // file: !this.state.formState.id && Yup.mixed().required("Veillez choisir une image")
                                    // .test(
                                    //   "fileSize",
                                    //   "File too large",
                                    //   value => value && value.size <= FILE_SIZE
                                    // )
                                    // .test(
                                    //   "fileFormat",
                                    //   "Unsupported Format",
                                    //   value => value && SUPPORTED_FORMATS.includes(value.type)
                                    // )
                                    // email: Yup.string().email('Invalid email').required('Required'),
                                  })}
                                >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    resetForm
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mt-3">
                                            <label htmlFor="libelle">Libellé</label>
                                            <input
                                                type="text"
                                                name="libelle"
                                                id="libelle"
                                                placeholder="Libellé"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.libelle}
                                            />
                                            {errors.libelle && touched.libelle &&
                                             <div className="text-danger">{errors.libelle}</div>}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="typeProduitId">Type d'équipement</label>
                                            <select name="typeProduitId" id="typeProduitId" 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.typeProduitId}
                                                    className="form-control rounded-0">
                                                <option>Selectionnez un type d'équipement</option>
                                                {this.state.listeProduitType && this.state.listeProduitType.map((item, index) =>
                                                    <option key={index} value={item.id}>{item.libelle}</option>
                                                )}
                                            </select>
                                            {errors.typeProduitId && touched.typeProduitId && 
                                            <div className="text-danger">{errors.typeProduitId}</div>}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="categorieId">Type de catégorie</label>
                                            <select name="categorieId" id="categorieId" 
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.categorieId}
                                                    className="form-control rounded-0">
                                                <option>Selectionnez un type de catégorie</option>
                                                {this.state.listeCategorieType && this.state.listeCategorieType.map((item, index) =>
                                                    <option key={index} value={item.id}>{item.libelle}</option>
                                                )}
                                            </select>
                                            {errors.categorieId && touched.categorieId && errors.categorieId}
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="prix-unitaire">Prix unitaire</label>
                                            <input
                                                type="text"
                                                id="prix-unitaire"
                                                name="prixUnitaire"
                                                placeholder="Prix unitaire"
                                                className="form-control rounded-0"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.prixUnitaire}
                                            />
                                            {errors.prixUnitaire && touched.prixUnitaire && 
                                            <div className="text-danger">{errors.prixUnitaire}</div>}
                                        </div>
                                        <div className="form-group mt-3">
                                            <input
                                                id="fichierImg"
                                                type="file"
                                                name="file"
                                                className="form-control rounded-0 inputfile"
                                                onChange={(event)=>this.processFile(event)}
                                                onBlur={handleBlur}
                                                value={values.file}
                                            />
                                            <label htmlFor="fichierImg">
                                                <i className="fa fa-upload fa-14 mr-2" aria-hidden="true"></i>
                                                {this.state.fileName}
                                            </label>
                                            {errors.file && touched.file && 
                                            <div className="text-danger">{errors.file}</div>}
                                        </div>
                                    <div className="d-flex justify-content-between">
                                        <button type="reset"
                                        onClick={() => resetForm(this.state.formState)}
                                        className="btn btn-danger btn-text rounded-0"
                                        >Annuler</button>
                                        <Loader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}                                        
                                            visible={this.state.affi}
                                        />
                                        <button type="submit" className="btn btn-outline-success btn-text rounded-0"
                                        >Valider</button>
                                    </div>
                                    </form>
                                )}
                                </Formik>
                            </div>
                        </div>
                   </div>
               </div>
            </div>
        </div>
    )}
}